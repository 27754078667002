import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  CondensedSmall,
  Flashy,
  Gutter,
  Icon,
  Layout,
  LayoutItem,
  Paragraph,
  ParagraphHeader,
  SectionHeader,
} from '@axiom/ui';
import { Field } from 'react-final-form';
import { CandidatesConst } from '@axiom/const';

import FormStateTreatment from '../FormStateTreatment/FormStateTreatment';
import { AppStore } from '../../stores/app-store';
import { isLPUser } from '../../utils/roles-util';
import { CurrentUserStore } from '../../stores/protected/current-user-store';
import { FFSlider } from '../FFSlider/FFSlider';
import { DateUtil } from '../../utils/date-util';
import { CandidatePreferencesUtil } from '../../utils/candidate-preferences-util';
import { FormAvailabilityPreferencesStore } from '../../stores/protected/form-availability-preferences-store';
import { AvailabilityPreferencesUserStatus } from '../AvailabilityPreferencesUserStatus/AvailabilityPreferencesUserStatus';

import { PreferredWeeklyHoursWarningModal } from './PreferredWeeklyHoursWarningModal';

const {
  availabilityPreferencesUpdatedByTypes: { Talent },
} = CandidatesConst;

const PREFERRED_WEEKLY_HOURS_WARNING_THRESHOLD = 60;

const PreferredWeeklyHoursComponent = ({ currentUser }) => {
  const [upToDate, setUpToDate] = useState(false);
  const [formDirty, setFormDirty] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [preferredHours, setPreferredHours] = useState();

  useEffect(() => {
    const lpUser = isLPUser(currentUser);

    const handleBeforeUnload = event => {
      if (formDirty && lpUser) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    /**
     * TODO: update react-router to support "history.block()" or
     * equivalent. There was a useBlocker() but it was flakey and
     * removed from the code. Current v6.4.3
     *
     * Above is fill-in-good-enough-for-now work around
     */
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [formDirty]);

  return (
    <FormStateTreatment
      allowOverflow
      name="AVAILABILITY_PREFERENCES_FORM_TREATMENT"
      formStore={FormAvailabilityPreferencesStore}
      renderLoadedView={({ formData, fireSubmit, form }) => {
        const {
          values: {
            availabilityPreferencesUpdatedAt: lastUpdate,
            availabilityPreferencesUpdatedByType: lastUpdateUserType,
            calculatedDisplayName,
          },
          dirty,
        } = form.getState();

        if (dirty) {
          setFormDirty(dirty);
          setUpToDate(false);
        }

        return (
          <>
            {formData.maxHoursFromWorkday === 0 ? (
              <>
                <ParagraphHeader>
                  We show you are currently on leave.
                </ParagraphHeader>
                <Gutter bottom="16px" />
                <Paragraph>
                  If that's not correct or you want to let Axiom know when you
                  will be available for work, email your talent partner below.
                </Paragraph>
              </>
            ) : (
              <>
                <SectionHeader>
                  Preferred weekly hours:{' '}
                  <Flashy color="brandAxiomOrange">
                    {formData.preferredWeeklyMaxHours}
                  </Flashy>
                </SectionHeader>
                <Gutter bottom="16px" />

                <Field
                  name="preferredWeeklyMaxHours"
                  component={FFSlider}
                  minRange={formData.minMaxWeeklyHours.min}
                  maxRange={formData.minMaxWeeklyHours.max}
                  initialValue={formData.preferredWeeklyMaxHours}
                  onValueChange={setPreferredHours}
                />
              </>
            )}
            <Gutter bottom="16px" />
            <AvailabilityPreferencesUserStatus candidate={formData} />

            <Gutter bottom="16px" />

            <Layout position="right">
              <LayoutItem>
                {upToDate ? (
                  <Paragraph>
                    Up to date <Icon name="checkmark" />
                  </Paragraph>
                ) : (
                  <Button
                    name="PENDO-MY-WORK-SAVE-BUTTON"
                    variation="outline"
                    onClick={async () => {
                      if (
                        preferredHours >
                        PREFERRED_WEEKLY_HOURS_WARNING_THRESHOLD
                      ) {
                        setShowWarningModal(true);
                      } else {
                        await fireSubmit();
                      }
                      setUpToDate(true);
                    }}
                    className="gtm-save-user-preferences"
                  >
                    {CandidatePreferencesUtil.canConfirmPreferences(lastUpdate)
                      ? 'Confirm'
                      : 'Save'}
                  </Button>
                )}
              </LayoutItem>
            </Layout>

            {!!lastUpdate && (
              <Gutter top="16px">
                <CondensedSmall>
                  Last Updated: {DateUtil.displayRelativeTime(lastUpdate)} by{' '}
                  {lastUpdateUserType === Talent
                    ? calculatedDisplayName
                    : 'Axiom'}
                </CondensedSmall>
              </Gutter>
            )}
            {!!showWarningModal && (
              <PreferredWeeklyHoursWarningModal
                onConfirm={() => {
                  fireSubmit();
                  setShowWarningModal(false);
                }}
                onCancel={() => setShowWarningModal(false)}
              />
            )}
          </>
        );
      }}
    />
  );
};

PreferredWeeklyHoursComponent.defaultProps = {};

PreferredWeeklyHoursComponent.propTypes = {
  currentUser: CurrentUserStore.getStateShape().isRequired,
};

const mapDispatchToProps = {
  showConfirmDialog: AppStore.actionShowConfirmDialog,
};

export const PreferredWeeklyHours = connect(
  state => ({
    currentUser: CurrentUserStore.selectUser(state),
  }),
  mapDispatchToProps
)(PreferredWeeklyHoursComponent);
