import { Api } from '@axiom/ui';
import { User } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';
import ApiHelper from '../lib/api-helper';

import { USER_API_NAME } from './constants';
import { requiresPassword } from './auth-api';

const api = new ApiHelper(USER_API_NAME);

export const getLoggedUser = async () => {
  const data = await api.GET('/user/me', {});
  const user = data ? data.data : null;
  if (user?.email) {
    user.isSSO = !(await requiresPassword(user.email));
  }
  return user;
};

class UserApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
      cookieDomain: EnvUtil.cookieDomain,
    });
  }

  readUser() {
    return super.read<{ data: User }>({
      endpoint: `/user/me`,
      method: 'GET',
    });
  }
}

export const UserApi = new UserApiClass();
