import React from 'react';
import { Candidate } from '@axiom/validation';
import {
  Badge,
  Card,
  CardSection,
  Gutter,
  Paragraph,
  Layout,
  Well,
  useApi,
  Brief,
} from '@axiom/ui';

import { ProfileCompletionUtil } from '../../utils/profile-completion-util';
import { CandidateProfileIssuesApi } from '../../api/candidate-profile-issues-api';
import { CandidateExperienceApi } from '../../api/candidate-experience-api';

import { ProfileCompletionChecklistItem } from './ProfileCompletionChecklistItem';

export const ProfileCompletionChecklist = ({
  candidateId,
}: {
  candidateId: Candidate['id'];
}) => {
  const [{ data: profileIssues }, { data: experiences }] = useApi(
    CandidateProfileIssuesApi.readCandidateProfileIssues(candidateId),
    CandidateExperienceApi.readExperiences(candidateId)
  );

  const actionNeededItems = ProfileCompletionUtil.getActionNeededItems(
    profileIssues.issues,
    experiences
  );

  return actionNeededItems.length > 0 ? (
    <Card>
      <CardSection background="blue">
        <Badge background="red">Action Needed</Badge>
        <Gutter bottom="16px" />
        <Paragraph>
          Your profile is how clients decide whether they want to engage you,
          and it’s how Axiom finds you to recommend you to clients. Here’s what
          you need to do to get your profile completed, and get the kind of work
          you want faster.
        </Paragraph>
        <Gutter bottom="24px" />
        <Well background="light">
          <Brief
            name="REQUIRED_COMPLETION_STEPS_BRIEF"
            briefMaxHeight="320px"
            position="center"
          >
            <Layout
              name="required-completion-steps"
              direction="vertical"
              verticalGutter="16px"
            >
              {actionNeededItems.map(item => (
                <ProfileCompletionChecklistItem
                  key={item.name}
                  actionItem={item}
                />
              ))}
            </Layout>
          </Brief>
        </Well>
      </CardSection>
    </Card>
  ) : null;
};
