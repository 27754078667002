/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment,global-require,unicorn/prefer-module  */
import React, { Component } from 'react';
import { bool, func, string, number, node } from 'prop-types';
import size from 'lodash/fp/size';
import { BulletsIcon } from '@axiom/ui';

import InputCharRemaining from '../InputCharRemaining/InputCharRemaining';
import { formatDataTestId } from '../../utils/data-test-util';

import {
  DivButtonsWrapper,
  DivEditorWrapper,
  DivRichTextEditorWrapper,
  ButtonUlToggleWrapper,
} from './RichTextEditorStyles';

let draftConvert;
let draftJs;
let convertFromHTML;
let convertToRaw;
let Editor;
let EditorState;
let RichUtils;

class RichTextEditor extends Component {
  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
    this.plainText = '';
    this.state = {
      editorState: null,
    };
  }

  componentDidMount() {
    draftConvert = require('draft-convert');
    draftJs = require('draft-js');
    convertFromHTML = draftConvert.convertFromHTML;
    convertToRaw = draftJs.convertToRaw;
    Editor = draftJs.Editor;
    EditorState = draftJs.EditorState;
    RichUtils = draftJs.RichUtils;

    this.setState(
      {
        editorState: this.newEditorState(this.props.initialValue),
      },
      () => {
        this.buildPlainText(this.state.editorState);
        this.forceUpdate();
        window.addEventListener('resize', this.onResize);
      }
    );
  }

  componentDidUpdate() {
    const { readOnly, readOnlyMaxLines, setContentsWouldCrop } = this.props;

    const canSetContentsWouldCrop =
      readOnly === true &&
      typeof readOnlyMaxLines === 'number' &&
      typeof setContentsWouldCrop === 'function';

    if (canSetContentsWouldCrop) {
      this.props.setContentsWouldCrop({
        contentsWouldCrop: this.readOnlyMaxLinesWouldCrop(),
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    this.forceUpdate();
  };

  onChange = editorState => {
    this.buildPlainText(editorState);
    this.setState({ editorState }, () => {
      this.props.onChange(this.plainText);
    });
  };

  onKeyCommand = (command, editorState) => {
    const allowedCommands = new Set([
      'split-block',
      'backspace',
      'backspace-to-start-of-line',
      'backspace-word',
      'redo',
    ]);

    if (allowedCommands.has(command)) {
      const updatedState = RichUtils.handleKeyCommand(editorState, command);

      if (updatedState) {
        this.setState({ editorState: updatedState });
        return 'handled';
      }
    }

    return 'not-handled';
  };

  onUlToggleButtonClick = () => {
    const updatedState = RichUtils.toggleBlockType(
      this.state.editorState,
      'unordered-list-item'
    );

    if (updatedState) this.onChange(updatedState);
  };

  handleBeforeInput = () => {
    const {
      props: { maxLength },
      plainText,
    } = this;

    return maxLength && size(plainText) > maxLength - 1 ? 'handled' : null;
  };

  handlePastedText = pastedText => {
    const {
      props: { maxLength },
      plainText,
    } = this;

    return maxLength && size(plainText) + size(pastedText) > maxLength
      ? 'handled'
      : null;
  };

  newEditorState = plainText => {
    const htmlText = (plainText || '')
      .split('\n')
      .map(plainTextLine => {
        let data = `<p>${plainTextLine}</p>`;

        if (plainTextLine.indexOf('- ') === 0) {
          data = `<ul><li>${plainTextLine.slice(2)}</li></ul>`;
        } else if (/^(\d+\.\s)/.test(plainTextLine)) {
          data = `<ol><li>${plainTextLine.slice(
            plainTextLine.indexOf('. ') + 2
          )}</li></ol>`;
        }

        return data;
      })
      .join('');

    const contentState = convertFromHTML(htmlText);
    const newEditorState = EditorState.createWithContent(contentState);

    return newEditorState;
  };

  buildPlainText = editorState => {
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    let listItemNumber = 1; // draft.js doesn't expose this
    this.plainText = rawContent.blocks
      .map(block => {
        if (block.type === 'ordered-list-item') {
          return `${listItemNumber++}. ${block.text}`;
        }
        listItemNumber = 1;
        if (block.type === 'unordered-list-item') {
          return `- ${block.text}`;
        }

        return block.text;
      })
      .join('\n');
  };

  readOnlyMaxLinesWouldCrop() {
    const {
      props: { readOnlyMaxLines },
      editorRef: { current },
    } = this;

    if (current) {
      const { editor } = current;
      if (typeof readOnlyMaxLines === 'number') {
        const computedStyle = window.getComputedStyle(editor);
        const lineHeightPx = Number.parseFloat(computedStyle.lineHeight);
        const maxHeight = readOnlyMaxLines * lineHeightPx;
        const blocks = editor.querySelectorAll('[data-block="true"]');
        const lastBlock = [...blocks].at(-1);
        return (
          lastBlock && lastBlock.offsetTop + lastBlock.offsetHeight > maxHeight
        );
      }
    }
    return false;
  }

  readOnlyHeight() {
    const {
      props: { readOnlyMaxLines },
      editorRef: { current },
    } = this;
    const vertMargin = 32;
    if (current) {
      const { editor } = current;
      const htmlBlocks = editor.querySelectorAll('[data-block="true"]');
      if (typeof readOnlyMaxLines === 'number') {
        const computedStyle = window.getComputedStyle(editor);
        const lineHeightPx = Number.parseFloat(computedStyle.lineHeight);
        const maxHeight = readOnlyMaxLines * lineHeightPx;
        return [...htmlBlocks].reduce((height, block) => {
          const remainingHeight = maxHeight - block.offsetTop;
          if (block.offsetHeight < remainingHeight) {
            return block.offsetTop + block.offsetHeight;
          }
          const additionalLines = Math.floor(remainingHeight / lineHeightPx);
          if (additionalLines > 0) {
            return block.offsetTop + additionalLines * lineHeightPx;
          }
          return height;
        }, 0);
      }
      const lastChild = [...htmlBlocks].at(-1);
      return lastChild
        ? lastChild.offsetTop + lastChild.offsetHeight + vertMargin
        : 0;
    }
    return null;
  }

  render() {
    const {
      dataTest,
      invalid,
      readOnly,
      readOnlyMaxLines,
      showButtons,
      showCharsRemainingLabel,
      maxLength,
      errorMessageNode,
      ...props
    } = this.props;

    return (
      <DivRichTextEditorWrapper
        data-test={formatDataTestId(
          `rich_text_editor ${readOnly ? '_disabled' : ''}`
        )}
        {...props}
      >
        {!!this.state.editorState && (
          <DivEditorWrapper
            readOnlyHeight={this.readOnlyHeight()}
            invalid={invalid}
            readOnly={readOnly}
          >
            <Editor
              ref={this.editorRef}
              editorState={this.state.editorState}
              onChange={this.onChange}
              handleKeyCommand={this.onKeyCommand}
              readOnly={readOnly}
              stripPastedStyles
              handleBeforeInput={this.handleBeforeInput}
              handlePastedText={this.handlePastedText}
            />
          </DivEditorWrapper>
        )}
        {!readOnly && !!showButtons && (
          <DivButtonsWrapper>
            <ButtonUlToggleWrapper
              type="button"
              onClick={this.onUlToggleButtonClick}
            >
              <BulletsIcon />
            </ButtonUlToggleWrapper>
          </DivButtonsWrapper>
        )}
        {!readOnly && (
          <InputCharRemaining
            showCharsRemainingLabel={showCharsRemainingLabel}
            maxLength={maxLength}
            errorMessageNode={errorMessageNode}
            value={this.plainText}
          />
        )}
      </DivRichTextEditorWrapper>
    );
  }
}

RichTextEditor.defaultProps = {
  readOnly: false,
  readOnlyMaxLines: null,
  setContentsWouldCrop: () => {},
  dataTest: null,
  showButtons: true,
  initialValue: '',
  invalid: false,
  onChange: () => {},
  showCharsRemainingLabel: false,
  maxLength: null,
  errorMessageNode: null,
};

RichTextEditor.propTypes = {
  dataTest: string,
  errorMessageNode: node,
  initialValue: string,
  invalid: bool,
  maxLength: number,
  readOnly: bool,
  readOnlyMaxLines: number,
  setContentsWouldCrop: func,
  showButtons: bool,
  showCharsRemainingLabel: bool,
  onChange: func,
};

export default RichTextEditor;
