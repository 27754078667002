import React from 'react';

import { TalentDataProvider } from '../providers/TalentDataProvider';
import { PageLayout } from '../layouts/PageLayout/PageLayout';
import { PreferencesPrograms } from '../components/Preferences/PreferencesPrograms';

export const ProgramsPreferencesPage = () => (
  <TalentDataProvider>
    <PageLayout showTalentLinks name="PROGRAMS-PREFERENCES-PAGE">
      <PreferencesPrograms />
    </PageLayout>
  </TalentDataProvider>
);
