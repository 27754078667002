import { Api } from '@axiom/ui';
import { Candidate, CandidateProfileIssues } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class CandidateProfileIssuesApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiPortalUrl,
    });
  }

  readCandidateProfileIssues(candidateId: Candidate['id']) {
    return super.read<{ data: CandidateProfileIssues }>({
      endpoint: `/candidates/${candidateId}/profileIssues`,
      method: 'GET',
    });
  }

  refreshCandidateProfileIssues(candidateId: string) {
    return super.invalidate(`/candidates/${candidateId}/profileIssues`);
  }
}

export const CandidateProfileIssuesApi = new CandidateProfileIssuesApiClass();
