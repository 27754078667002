import React from 'react';
import { bool } from 'prop-types';
import { Spinner } from 'reactstrap';

import {
  LoadingCurtainCoverWrapper,
  LoadingCurtainSpinnerWrapper,
} from './LoadingCurtainStyles';

export const LoadingCurtain = ({ showSpinner, isFullscreen }) => (
  <LoadingCurtainCoverWrapper isFullscreen={isFullscreen}>
    <LoadingCurtainSpinnerWrapper>
      {!!showSpinner && <Spinner color="primary" />}
    </LoadingCurtainSpinnerWrapper>
  </LoadingCurtainCoverWrapper>
);

LoadingCurtain.propTypes = {
  isFullscreen: bool,
  showSpinner: bool,
};

LoadingCurtain.defaultProps = {
  showSpinner: true,
  isFullscreen: false,
};
