import React from 'react';

import { DigitalSubmissionDataProvider } from '../providers/DigitalSubmissionDataProvider';
import { PrintStylesWrapper } from '../themes/components';
import { DigitalTalentSubmissionView } from '../components/DigitalTalentSubmissionView/DigitalTalentSubmissionView';
import { PageLayout } from '../layouts/PageLayout/PageLayout';

export const TalentSubmissionPage = () => (
  <DigitalSubmissionDataProvider>
    <PrintStylesWrapper>
      <PageLayout name="DIGITALSUBMISSIONPAGE">
        <DigitalTalentSubmissionView />
      </PageLayout>
    </PrintStylesWrapper>
  </DigitalSubmissionDataProvider>
);

TalentSubmissionPage.propTypes = {};
