import ApiHelper from '../lib/api-helper';

import { NOTIFICATION_API_NAME } from './constants';

const api = new ApiHelper(NOTIFICATION_API_NAME);

export const getNotifications = async userId => {
  const { data } = await api.GET(`/users/${userId}/notifications`);
  /**
   * Make sure we're only looking at notifications
   */
  const notifications = data
    ? data.filter(notification => notification.type === 'notification')
    : [];
  /**
   * Make sure they're sorted newest to oldest
   */
  return notifications.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
};

export const putNotificationRead = async notificationId => {
  const { data } = await api.PATCH(`/notifications/${notificationId}`, {
    isRead: true,
  });
  return { data };
};

export const putAllNotificationsRead = async userId => {
  const { data } = await api.PUT(
    `/users/${userId}/notifications/markAllAsRead`
  );
  return { data };
};
