import React from 'react';
import { ProfileCompletionConst } from '@axiom/const';
import {
  CondensedSmall,
  Flashy,
  Gutter,
  Layout,
  LayoutItem,
  Paragraph,
  SectionHeader,
} from '@axiom/ui';

export const ExperiencesMissingIndustryPrompt = () => (
  <>
    <Layout horizontalGutter="8px" position="middle" wrap>
      <LayoutItem>
        <SectionHeader>
          {
            ProfileCompletionConst.ProfileCompletionExperienceIndustryBanner
              .missingTitle
          }
        </SectionHeader>
      </LayoutItem>
      <LayoutItem>
        <CondensedSmall>
          <Flashy color="textSubtle">|</Flashy>
        </CondensedSmall>
      </LayoutItem>
      <LayoutItem>
        <CondensedSmall>
          <Flashy color="textSubtle">1 min</Flashy>
        </CondensedSmall>
      </LayoutItem>
    </Layout>
    <Gutter bottom="8px" />
    <Paragraph>
      {
        ProfileCompletionConst.ProfileCompletionExperienceIndustryBanner
          .missingContent
      }
    </Paragraph>
  </>
);
