import { useParams } from 'react-router-dom';
import { useApi } from '@axiom/ui';

import { CandidateLegacyApi } from '../api/candidate-legacy-api';
import { UserApi } from '../api/user-api';
import { CandidateUtil } from '../utils/candidate-util';
import { isHQUser, isSalesUser } from '../utils/roles-util';

export const useDocumentTitle = (appendTitle: string) => {
  const [{ data: user }] = useApi(UserApi.readUser());
  const { candidateId: urlCandidateId } = useParams();
  const candidateId = CandidateUtil.reconcileUserCandidateId(
    user,
    urlCandidateId
  );
  const [{ data: candidate }] = useApi(
    CandidateLegacyApi.readCandidate(candidateId)
  );

  if (isHQUser(user) || isSalesUser(user)) {
    return `${candidate.calculatedDisplayName} - ${appendTitle}`;
  }

  return `AxiomLaw - ${appendTitle}`;
};
