import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import StoreStateTreatment from '../components/StoreStateTreatment/StoreStateTreatment';
import { AvailabilityPreferencesView } from '../components/AvailabilityPreferencesView/AvailabilityPreferencesView';
import { ConfirmationsStore } from '../stores/confirmations-store';
import { WindowUtil } from '../utils/window-util';
import { PageLayout } from '../layouts/PageLayout/PageLayout';

const AvailabilityPreferencesConfirmPageComponent = ({
  confirmationsState,
}) => {
  const { token } = useParams();

  // Use URL query as this route is not in auth and doesn't have access to user object
  const { candidateId } = WindowUtil.getUrlQuery();

  useEffect(() => {
    ConfirmationsStore.submit(token, candidateId);
  }, []);

  return (
    <PageLayout>
      <StoreStateTreatment
        storeState={confirmationsState}
        renderLoadedView={() => <AvailabilityPreferencesView />}
      />
    </PageLayout>
  );
};

AvailabilityPreferencesConfirmPageComponent.propTypes = {
  confirmationsState: ConfirmationsStore.getStateShape().isRequired,
};

export const AvailabilityPreferencesConfirmPage = connect(state => ({
  confirmationsState: ConfirmationsStore.select(state),
}))(AvailabilityPreferencesConfirmPageComponent);
