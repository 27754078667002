import React, { useState } from 'react';
import { IconButton, Layout, LayoutItem, Paragraph, Divider } from '@axiom/ui';
import { Candidate, CandidateLanguage } from '@axiom/validation';

import { CandidateLanguageUtil } from '../../utils/candidate-language-util';

import { LanguagesItemDelete } from './LanguagesItemDelete';
import { LanguagesItemEdit } from './LanguagesItemEdit';

export const LanguagesItemDisplay = ({
  candidateId,
  candidateLanguages,
  actionedLanguages,
}: {
  candidateId: Candidate['id'];
  candidateLanguages: CandidateLanguage[];
  actionedLanguages: CandidateLanguage[];
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  return (
    <div data-test="LANGUAGES_ITEM_DISPLAY">
      <Layout position="middle">
        <LayoutItem fluid>
          <Paragraph name="LANGUAGE_DETAILS">
            <Divider>
              {CandidateLanguageUtil.buildLanguageDisplayBars(
                actionedLanguages
              )}
            </Divider>
          </Paragraph>
        </LayoutItem>
        <LayoutItem>
          <Layout horizontalGutter="8px" position="middle">
            <IconButton
              icon="trash"
              pattern="secondary"
              variation="minimal"
              name="DELETE_LANGUAGES_BUTTON"
              onClick={() => setIsDeleteModalOpen(true)}
            />
            <IconButton
              icon="pencil"
              pattern="secondary"
              variation="minimal"
              name="EDIT_LANGUAGES_BUTTON"
              onClick={() => setIsEditModalOpen(true)}
            />
          </Layout>
        </LayoutItem>
      </Layout>
      {!!isDeleteModalOpen && (
        <LanguagesItemDelete
          candidateId={candidateId}
          candidateLanguages={candidateLanguages}
          languagesToDelete={actionedLanguages}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      )}
      {!!isEditModalOpen && (
        <LanguagesItemEdit
          candidateId={candidateId}
          candidateLanguages={candidateLanguages}
          actionedLanguages={actionedLanguages}
          onClose={() => setIsEditModalOpen(false)}
        />
      )}
    </div>
  );
};
