/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';

import { FormStateHelperShape } from '../../lib/form-state-helper';
import { ComponentShape } from '../../utils/prop-type-util';
import { LoadingCurtain } from '../LoadingCurtain/LoadingCurtain';
import { PositionRelative } from '../../themes/components';
import Toast from '../Toast/Toast';
import { formatDataTestId } from '../../utils/data-test-util';

/**
 * Use this for handling when a FormState hits one of its various states.
 *
 * Assuming you connect selectors look like this:
 * export default connect(state => ({
    donutFormState: DonutsStore.selectDonutFormState(state),
    donutForm: DonutsStore.selectDonutFormData(state)
  }))(ListOfDonutsPage);

 *
 * <FormStateHelperTreatment
 *  formState={donutFormState}
 *  formComponent={<DonutForm />}
 *  displayComponent={<DonutDisplay />}
 *
 *  />
 *
 *  This handles toggling display vs form, depending on what state the form store is in.
 *  It also handles on submit, putting a curtain over the form so that users can't double-click
 *  the submit button. We also use this to show modals by not passing a displayComponent in
 */

const isApplicableToCurrentState = (formState, formItemIdentifier) =>
  !!(
    formState.isVisible && formState.formItemIdentifier === formItemIdentifier
  );
const FormStateHelperTreatment = ({
  formState,
  formComponent,
  displayComponent,
  loadingTreatment,
  isFormModal,
  formItemIdentifier,
  ...props
}) => {
  const loadingComponent = loadingTreatment || (
    <LoadingCurtain isFullscreen={isFormModal} />
  );
  return (
    <PositionRelative
      data-test={formatDataTestId('relativePositionWrapper')}
      {...props}
    >
      {isApplicableToCurrentState(formState, formItemIdentifier) &&
        !!formState.errors &&
        formState.errors.length > 0 && (
          <Toast>An error happened with your request. Please try again.</Toast>
        )}
      {isApplicableToCurrentState(formState, formItemIdentifier) &&
        !!formState.isSubmitting &&
        loadingComponent}
      {isApplicableToCurrentState(formState, formItemIdentifier) &&
      formComponent
        ? formComponent
        : null}
      {!isApplicableToCurrentState(formState, formItemIdentifier) &&
      displayComponent
        ? displayComponent
        : null}
    </PositionRelative>
  );
};

FormStateHelperTreatment.propTypes = {
  displayComponent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  formComponent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  formItemIdentifier: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formState: FormStateHelperShape.isRequired,
  isFormModal: PropTypes.bool,
  loadingTreatment: ComponentShape,
};

FormStateHelperTreatment.defaultProps = {
  formItemIdentifier: null,
  displayComponent: null,
  formComponent: null,
  isFormModal: false,
  loadingTreatment: null,
};

export default FormStateHelperTreatment;
