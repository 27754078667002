import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useApi,
  Well,
  Grid,
  GridColumn,
  Card,
  CardSection,
  CardHeader,
  SmallHeader,
  Form,
  FormGroup,
  Gutter,
  Radio,
  Flashy,
  Layout,
  LayoutItem,
  Toggle,
  Suspense,
  Button,
  FormLabel,
  CondensedLarge,
  CondensedMedium,
  IconButton,
  Banner,
  ToastUtil,
} from '@axiom/ui';
import { CandidateSchema } from '@axiom/validation';
import { CandidatesConst } from '@axiom/const';

import { PreferencesNavigation } from '../PreferencesNavigation/PreferencesNavigation';
import { WorkFeedAlerts } from '../WorkFeedAlerts/WorkFeedAlerts';
import { CandidateLegacyApi } from '../../api/candidate-legacy-api';
import { CandidateApi } from '../../api/candidate-api';
import { UserApi } from '../../api/user-api';
import { CandidateUtil } from '../../utils/candidate-util';
import { EnvUtil } from '../../utils/env-util';
import { isEnvoyUser, isLPUser } from '../../utils/roles-util';

import { PreferencesTwoFactorPromptModal } from './PreferencesTwoFactorPromptModal';
import { PreferencesTwoFactorDeleteModal } from './PreferencesTwoFactorDeleteModal';

const { WorkingEmploymentStatuses } = CandidatesConst;

const CommunicationPreferencesSchema = CandidateSchema.pick({
  preferredContactMethod: true,
});

const CommunicationPreferencesAlertsSchema = CandidateSchema.pick({
  isOptInWorkFeedEmail: true,
});

export const PreferencesCommunication = () => {
  const [{ data: user }] = useApi(UserApi.readUser());
  const { candidateId: urlCandidateId } = useParams();

  const candidateId = CandidateUtil.reconcileUserCandidateId(
    user,
    urlCandidateId
  );

  const [{ data: candidate }, { data: candidateMfa }] = useApi(
    CandidateLegacyApi.readCandidate(candidateId),
    CandidateApi.readCandidateMfaPhoneNumber(candidateId)
  );

  const [showTwoFactorPromptModal, setShowTwoFactorPromptModal] =
    useState(false);
  const [showTwoFactorDeleteModal, setShowTwoFactorDeleteModal] =
    useState(false);
  const {
    employmentStatus,
    homePhone,
    isAccessibleToWorkFeed,
    isOptInWorkFeedEmail,
    mobilePhone,
    personalEmail,
    preferredContactMethod,
    workEmail,
  } = candidate;
  const showEditInMyApps = WorkingEmploymentStatuses.includes(employmentStatus);
  const talentPartnerUser = CandidateUtil.getTalentPartnerUser(candidate);
  const updatePhoneSubject = 'Please update my contact information';
  const updatePhoneBody = `Dear ${talentPartnerUser.firstName},%0D%0A%0D%0APlease update my contact information in Greenhouse to the following:`;

  const initiate2faRestrictionToast = (isDelete = false) => {
    ToastUtil.add({
      name: '2FA_RESTRICTION_TOAST',
      type: 'error',
      children: (
        <CondensedLarge>
          Only talent {isDelete ? 'and admin' : ''} can{' '}
          {isDelete ? 'delete' : 'edit'} 2FA phone numbers
        </CondensedLarge>
      ),
    });
  };

  return (
    <>
      <Grid name="COMMUNICATION_PREFERENCES">
        <GridColumn largeScreenWidth={4} smallScreenWidth={12}>
          <PreferencesNavigation />
          <Gutter only="smallScreen" bottom="16px" />
        </GridColumn>
        <GridColumn largeScreenWidth={8} smallScreenWidth={12}>
          <Layout direction="vertical" verticalGutter="16px">
            <Card>
              <CardHeader>
                <SmallHeader>Communications</SmallHeader>
              </CardHeader>
              <CardSection divider>
                <Form
                  name="COMMS-FORM"
                  schema={CommunicationPreferencesSchema}
                  onSubmit={formData => {
                    return CandidateLegacyApi.updateCandidate(
                      candidateId,
                      CandidateSchema.partial().parse(formData)
                    ).then(() => {
                      return CandidateLegacyApi.refreshCandidate(candidateId);
                    });
                  }}
                  initialValues={{
                    preferredContactMethod,
                  }}
                  submitOnChange
                >
                  {() => (
                    <FormGroup
                      name="preferredContactMethod"
                      label="Preferred Email"
                      description="Where would you like us to send notifications about new opportunities or things that need your attention?"
                    >
                      <Layout direction="vertical" verticalGutter="8px">
                        {!!workEmail && (
                          <Radio
                            name="preferredContactMethod"
                            option="Axiom"
                            displayValue={workEmail}
                          />
                        )}
                        {!!personalEmail && (
                          <Radio
                            name="preferredContactMethod"
                            option="Personal"
                            displayValue={personalEmail}
                          />
                        )}
                        {!!workEmail && !!personalEmail && (
                          <Radio
                            name="preferredContactMethod"
                            option="Both"
                            displayValue="Both"
                          />
                        )}
                        {!workEmail && !personalEmail && (
                          <Flashy
                            name="NO_EMAIL_WARNING"
                            bold
                            color="textAlert"
                          >
                            Please reach out to your Axiom contact to set your
                            email.
                          </Flashy>
                        )}
                      </Layout>
                    </FormGroup>
                  )}
                </Form>
              </CardSection>
              <CardSection divider>
                <FormLabel>Phone Number</FormLabel>
                <Gutter bottom="8px" />
                <CondensedLarge name="mobilePhone">
                  Mobile: {mobilePhone ?? '--'}
                </CondensedLarge>
                <Gutter bottom="8px" />
                <CondensedLarge name="homePhone">
                  Telephone: {homePhone ?? '--'}
                </CondensedLarge>
              </CardSection>
              <CardSection divider>
                {showEditInMyApps ? (
                  <>
                    <CondensedLarge>
                      Update your contact information in Workday
                    </CondensedLarge>
                    <Gutter bottom="8px" />
                    <Button
                      name="MYAPPS_BUTTON"
                      pattern="secondary"
                      variation="outline"
                      toTab={EnvUtil.myAppsUrl}
                    >
                      Launch My Apps
                    </Button>
                  </>
                ) : (
                  <CondensedLarge>
                    To update your contact information, reach out to your{` `}
                    <a
                      data-test="REACHOUT_LINK"
                      href={`mailto:${talentPartnerUser.email}?subject=${updatePhoneSubject}&body=${updatePhoneBody}`}
                    >
                      talent partner.
                    </a>
                  </CondensedLarge>
                )}
              </CardSection>
            </Card>

            <Card>
              <CardHeader>
                <SmallHeader>Security</SmallHeader>
              </CardHeader>
              <CardSection>
                {!isLPUser(user) && (
                  <>
                    <Banner name="2FA_RESTRICTION_BANNER">
                      <Flashy bold>
                        Only talent can edit or delete 2FA phone numbers.
                      </Flashy>{' '}
                      If talent has been locked out of their account, please ask
                      an admin to remove the phone number on their behalf.
                    </Banner>
                    <Gutter bottom="8px" />
                  </>
                )}
                <FormLabel>Two-factor authentication</FormLabel>
                <Gutter bottom="8px" />
                <CondensedMedium>
                  Axiom will use this number to send security codes and other
                  Axiom communications via SMS.
                </CondensedMedium>
                <Gutter bottom="8px" />
                {!!candidateMfa.countryCode && !!candidateMfa.phoneNumber && (
                  <Well name="2FA_WELL">
                    <Layout position="space-between">
                      <LayoutItem name="2FA_WELL_PHONE_DISPLAY">{`+${candidateMfa.countryCode} ${candidateMfa.phoneNumber}`}</LayoutItem>
                      <LayoutItem>
                        <IconButton
                          name="2FA_EDIT_BUTTON"
                          icon="pencil"
                          variation="minimal"
                          onClick={() => {
                            if (isLPUser(user)) {
                              setShowTwoFactorPromptModal(true);
                            } else {
                              initiate2faRestrictionToast();
                            }
                          }}
                        />

                        <IconButton
                          name="2FA_DELETE_BUTTON"
                          icon="trash"
                          variation="minimal"
                          onClick={() => {
                            if (isEnvoyUser(user)) {
                              initiate2faRestrictionToast(true);
                            } else {
                              setShowTwoFactorDeleteModal(true);
                            }
                          }}
                        />
                      </LayoutItem>
                    </Layout>
                  </Well>
                )}
                {!candidateMfa.countryCode &&
                  !candidateMfa.phoneNumber &&
                  isLPUser(user) && (
                    <Button
                      name="SETUP_2FA_BUTTON"
                      onClick={() => setShowTwoFactorPromptModal(true)}
                    >
                      Set Up
                    </Button>
                  )}
              </CardSection>
            </Card>
            {!!isAccessibleToWorkFeed && (
              <Card name="ALERTS_PREFERENCES_CARD">
                <CardHeader>
                  <SmallHeader>Alerts</SmallHeader>
                </CardHeader>
                <CardSection divider>
                  <Form
                    name="ALERTS-FORM"
                    schema={CommunicationPreferencesAlertsSchema}
                    onSubmit={formData => {
                      return CandidateLegacyApi.updateCandidate(
                        candidateId,
                        CandidateSchema.partial().parse(formData)
                      ).then(() => {
                        return CandidateLegacyApi.refreshCandidate(candidateId);
                      });
                    }}
                    initialValues={{
                      isOptInWorkFeedEmail: !!isOptInWorkFeedEmail,
                    }}
                    submitOnChange
                  >
                    {() => (
                      <Toggle
                        name="isOptInWorkFeedEmail"
                        label="Alert Subscriptions"
                        description="I'd like to receive opportunity alerts via email."
                      />
                    )}
                  </Form>
                </CardSection>
                <CardSection>
                  <Suspense>
                    <WorkFeedAlerts candidateId={candidateId} />
                  </Suspense>
                </CardSection>
              </Card>
            )}
          </Layout>
        </GridColumn>
      </Grid>
      {!!showTwoFactorPromptModal && (
        <PreferencesTwoFactorPromptModal
          candidateMfa={candidateMfa}
          user={user}
          candidate={candidate}
          onClose={() => setShowTwoFactorPromptModal(false)}
        />
      )}
      {!!showTwoFactorDeleteModal && (
        <PreferencesTwoFactorDeleteModal
          onDelete={async () => {
            await CandidateApi.deleteCandidateMfaPhoneNumber(candidate.id);
            await CandidateApi.refreshCandidateMfaPhoneNumber(candidate.id);
            setShowTwoFactorDeleteModal(false);
          }}
          onClose={() => setShowTwoFactorDeleteModal(false)}
        />
      )}
    </>
  );
};
