import React from 'react';
import {
  Brief,
  CondensedHeader,
  CondensedLarge,
  Flashy,
  Gutter,
} from '@axiom/ui';

export const ExperienceEditDescriptionGuidelines = () => {
  return (
    <div data-test="EXPERIENCE_EDIT_DESCRIPTION_GUIDELINES">
      <Gutter bottom="16px">
        <CondensedHeader>How to write a great description</CondensedHeader>
      </Gutter>
      <Brief briefMaxChildren={2} pattern="secondary">
        <CondensedLarge>
          <Flashy bold>For experiences within last 10 years:</Flashy>
          <ul>
            <li>
              If the experience was longer than 9 months, write at least 3
              bullet points and 300 characters.
            </li>
            <li>
              If the experience was shorter than 9 months, write at least 1
              bullet point and 100 characters.
            </li>
          </ul>
        </CondensedLarge>
        <CondensedLarge>
          <Flashy bold>For experiences older than 10 years:</Flashy>
          <ul>
            <li>Write at least 1 bullet point and 100 characters.</li>
          </ul>
        </CondensedLarge>
        <Gutter bottom="16px">
          <CondensedLarge>
            <Flashy bold>
              Experiences that are not relevant to getting Axiom Engagements do
              not need to be included.
            </Flashy>
          </CondensedLarge>
        </Gutter>
        <Gutter bottom="16px">
          <CondensedLarge>
            <Flashy bold>
              Bullet points should be comprehensive and concise, describing the
              type of work you did.
            </Flashy>{' '}
            For example, specifically mention the types of contracts you were
            involved in reviewing, drafting, negotiating, or working on. Be sure
            to highlight significant projects you successfully completed.
          </CondensedLarge>
        </Gutter>
        <Gutter bottom="16px">
          <CondensedLarge>
            <Flashy bold>
              Prioritize precision over vagueness when crafting descriptions.
            </Flashy>{' '}
            Ensure that the language used is both specific and accurate. Avoid
            descriptions like "Vendor/Tech agreements" or "L&E support."
          </CondensedLarge>
        </Gutter>
        <Gutter bottom="16px">
          <CondensedLarge>
            <Flashy bold>Maintain a consistent and uniform appearance</Flashy>{' '}
            in terms of headings, spacing, and bullet points throughout.
          </CondensedLarge>
        </Gutter>
        <Gutter bottom="16px">
          <CondensedLarge>
            <Flashy bold>
              Select powerful, vivid, and highly descriptive verbs
            </Flashy>{' '}
            that effectively convey the core responsibilities and workstreams of
            the engagement.
          </CondensedLarge>
        </Gutter>
        <Gutter bottom="16px">
          <CondensedLarge>
            <Flashy bold>
              Ensure that the tense used for all past work experiences is
              consistent and uniform across the entire bio.
            </Flashy>{' '}
            Avoid having one former engagement as "Drafting contracts" whereas
            another engagement reads "Assisted on procurement contracts."
          </CondensedLarge>
        </Gutter>
      </Brief>
    </div>
  );
};
