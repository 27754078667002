import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import moment from 'moment/moment';
import {
  Candidate,
  CandidateProfileIssues,
  Experience,
  ExperienceEditSchema,
  Taxonomy,
} from '@axiom/validation';
import {
  Button,
  Checkbox,
  DateInput,
  DateUtil,
  Dropdown,
  FluidButtonLayout,
  Form,
  FormGroup,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  Input,
  Location,
  TaxonomyUtil,
} from '@axiom/ui';
import { SchemaLocation, SchemaMonthYear } from '@axiom/types';
import { CandidateProfileIssuesUtils } from '@axiom/utils';
import { ProfileCompletionConst } from '@axiom/const';

import {
  CompanyTypeahead,
  getLabel,
} from '../CompanyTypeahead/CompanyTypeahead';
import { ExperienceApi } from '../../api/experience-api';
import { CandidateExperienceApi } from '../../api/candidate-experience-api';
import { CandidateProfileIssuesApi } from '../../api/candidate-profile-issues-api';

import {
  ExperienceEditSectionKeys,
  ExperienceEditUtil,
} from './experience-edit-util';
import { ExperienceEditContext } from './ExperienceEditContext';
import { ExperienceEditIssue } from './ExperienceEditIssue';
import { ExperienceEditSection } from './ExperienceEditSection';

const { COMPANY_DETAILS, WORK_DETAILS } = ExperienceEditSectionKeys;

export const CompanyDetailsNonAxiomSchema = ExperienceEditSchema.pick({
  client: true,
  clientDomain: true,
  locationName: true,
  startDate: true,
  endDate: true,
  industryValue: true,
  externalOpportunityName: true,
});

const formSchema = CompanyDetailsNonAxiomSchema.extend({
  location: SchemaLocation,
  currentlyWorking: z.boolean().nullish(),
  startDate: SchemaMonthYear,
  endDate: SchemaMonthYear.nullish(),
  startMonthYearGroup: z.string().optional(),
  company: z.string(),
});

export type ExperienceEditFormCompanyDetailsNonAxiomProps = {
  candidateId: Candidate['id'];
  experience: Experience;
  issues: CandidateProfileIssues['issues'];
  taxonomy: Taxonomy;
};

export const ExperienceEditCompanyDetailsNonAxiom = ({
  candidateId,
  experience,
  issues,
  taxonomy,
}: ExperienceEditFormCompanyDetailsNonAxiomProps) => {
  const navigate = useNavigate();
  const {
    dirtyFormState,
    setDirtyFormState,
    accordionState,
    setAccordionState,
  } = useContext(ExperienceEditContext);
  const industryOptions = TaxonomyUtil.getIndustryOptions(taxonomy.industries);

  return (
    <Form
      name="EXPERIENCE_EDIT_FORM_COMPANY_DETAILS_NON_AXIOM"
      schema={formSchema}
      initialValues={{
        candidateId: experience.candidateId ?? candidateId,
        currentlyWorking: !experience.endDate,
        location:
          experience.locationAddressComponents ||
          experience.locationName ||
          experience.locationPlaceId
            ? {
                locationAddressComponents: experience.locationAddressComponents,
                locationName: experience.locationName,
                locationPlaceId: experience.locationPlaceId,
              }
            : null,
        company: getLabel(experience.client, experience.clientDomain),
        client: experience.client,
        clientDomain: experience.clientDomain,
        externalOpportunityName: experience.externalOpportunityName,
        startDate: experience.startDate,
        endDate: experience.endDate,
        industryValue: experience.industryValue,
      }}
      onSubmit={async (formData, actions) => {
        const startDate = formData.startDate ?? experience.startDate;
        const endDate = formData.endDate ?? experience.endDate;

        if (DateUtil.isDateAfterDate(startDate)) {
          actions.setFieldError(
            'startMonthYearGroup',
            'Start date must be in the past'
          );
          return;
        }

        if (DateUtil.isDateAfterDate(startDate, moment(endDate).toDate())) {
          actions.setFieldError(
            'startMonthYearGroup',
            'This must be earlier than or equal to end date'
          );
          return;
        }

        const submitData = {
          candidateId,
          ...formData,
          // handles edge case when user changes clientDomain without modification to client
          // ex. Axiom as a new option without domain, then later changed to Axiom from the clearbit response
          ...(formData.clientDomain !== undefined && !formData.client
            ? { client: experience.client }
            : {}),
          ...formData.location,
        };

        if (experience.id) {
          // update experience
          await ExperienceApi.updateExperience(
            experience.id,
            ExperienceEditSchema.partial().parse(submitData)
          );
        } else {
          // create experience
          const response = await ExperienceApi.createExperience(
            candidateId,
            ExperienceEditSchema.partial().parse(submitData)
          );
          if (response.data.id) {
            navigate(`../${response.data.id}`);
          }
        }
        await Promise.all([
          CandidateExperienceApi.refreshExperiences(candidateId),
          CandidateProfileIssuesApi.refreshCandidateProfileIssues(candidateId),
        ]);
        setAccordionState({
          ...ExperienceEditUtil.setAllToFalse(accordionState),
          [WORK_DETAILS]: true,
        });
      }}
    >
      {({ dirty, fireSubmit, setValues, values }) => {
        // https://stackoverflow.com/questions/61031464/setstate-called-in-render-prop-is-causing-a-react-warning
        setTimeout(() => {
          if (dirtyFormState[COMPANY_DETAILS] !== dirty) {
            setDirtyFormState((prevState: Record<string, boolean>) => ({
              ...prevState,
              [COMPANY_DETAILS]: dirty,
            }));
          }
        }, 0);

        return (
          <ExperienceEditSection
            expandable
            expanded={accordionState[COMPANY_DETAILS]}
            isComplete={ExperienceEditUtil.isDataValid(
              CompanyDetailsNonAxiomSchema,
              experience
            )}
            title="Company Details"
            name={COMPANY_DETAILS}
          >
            <div data-test="EXPERIENCE_EDIT_FORM_COMPANY_DETAILS_NON_AXIOM_BODY">
              {CandidateProfileIssuesUtils.hasExperienceWithMissingIndustryIssue(
                issues,
                experience.id
              ) && (
                <Gutter bottom="16px">
                  <ExperienceEditIssue
                    title={
                      ProfileCompletionConst
                        .ProfileCompletionExperienceIndustryBanner.missingTitle
                    }
                    description={
                      ProfileCompletionConst
                        .ProfileCompletionExperienceIndustryBanner
                        .missingContent
                    }
                    estimatedTime="1 min"
                    name="ADD_INDUSTRY_HINT_EDIT"
                  />
                </Gutter>
              )}
              <CompanyTypeahead
                name="company"
                label="Company Name"
                initialValue={{
                  name: values.client,
                  domain: values.clientDomain,
                }}
                onChange={payload => {
                  setValues({
                    ...values,
                    client: payload.name,
                    clientDomain: payload.domain,
                  });
                }}
              />
              <Gutter bottom="16px" />
              <Input name="externalOpportunityName" label="Title" />
              <Gutter bottom="16px" />
              <Location
                name="location"
                label="Location"
                placeholder={experience.locationName}
              />
              <Gutter bottom="16px" />
              <Grid>
                <GridColumn
                  largeScreenWidth={6}
                  tabletWidth={12}
                  mobileWidth={12}
                >
                  <Grid columns={2}>
                    <GridRow>
                      <GridColumn width={12}>
                        <DateInput name="startDate" label="Start Date" />
                      </GridColumn>
                      <GridColumn width={12}>
                        {/* for displaying custom error */}
                        <FormGroup name="startMonthYearGroup" />
                      </GridColumn>
                    </GridRow>
                  </Grid>
                </GridColumn>
                <GridColumn
                  largeScreenWidth={6}
                  tabletWidth={12}
                  mobileWidth={12}
                >
                  <Grid columns={2}>
                    <GridColumn width={12}>
                      <DateInput
                        name="endDate"
                        label="End Date"
                        disabled={values.currentlyWorking}
                      />
                    </GridColumn>
                    <GridColumn width={12}>
                      <Gutter bottom="16px" />
                      <Checkbox
                        name="currentlyWorking"
                        displayValue="I currently work here"
                        onChange={v => {
                          if (v) {
                            setValues({ ...values, endDate: null });
                          } else {
                            setValues({
                              ...values,
                              endDate: DateUtil.formatAsDate(moment().date(1)),
                            });
                          }
                        }}
                      />
                    </GridColumn>
                  </Grid>
                </GridColumn>
              </Grid>
              <Gutter bottom="16px" />
              <Dropdown
                displayKey="label"
                name="industryValue"
                options={industryOptions}
                valueKey="value"
                label="Industry"
              />
              <Gutter bottom="16px" />
              <FluidButtonLayout>
                <Button
                  pattern="secondary"
                  onClick={fireSubmit}
                  name="EXPERIENCE_EDIT_FORM_COMPANY_DETAILS_NON_AXIOM_SAVE"
                >
                  Save
                </Button>
              </FluidButtonLayout>
            </div>
          </ExperienceEditSection>
        );
      }}
    </Form>
  );
};
