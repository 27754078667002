import React from 'react';
import { string, oneOf } from 'prop-types';
import { DataTest } from '@axiom/ui';

import { NumberUtil } from '../../utils/number-util';

const CharacterCounter = ({ text, location, className }) => (
  <DataTest
    value="characters_left"
    style={{
      textAlign: location,
    }}
    className={className}
  >
    {NumberUtil.withCommas(1000 - text.length)} characters left
  </DataTest>
);

CharacterCounter.defaultProps = {
  location: 'left',
  className: null,
  text: '',
};

CharacterCounter.propTypes = {
  className: string,
  location: oneOf(['left', 'right', 'center']),
  text: string,
};

export default CharacterCounter;
