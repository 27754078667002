import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { node } from 'prop-types';

import { DigitalSubmissionStore } from '../stores/digital-submission-store';
import StoreStateTreatment from '../components/StoreStateTreatment/StoreStateTreatment';
import LoadingTreatment from '../components/LoadingTreatment/LoadingTreatment';
import { LoadingCurtain } from '../components/LoadingCurtain/LoadingCurtain';
import { PreloadedFeatureFlagsStore } from '../stores/preloaded-feature-flags-store';

const DigitalSubmissionDataProviderComponent = ({
  digitalSubmissionState,
  children,
}) => {
  const { submissionId, candidateId } = useParams();

  useEffect(() => {
    DigitalSubmissionStore.load(submissionId, candidateId);
    PreloadedFeatureFlagsStore.bootstrap({});
  }, [submissionId]);

  return (
    <StoreStateTreatment
      initialLoader={<LoadingTreatment />}
      refetchLoader={<LoadingCurtain isFullscreen />}
      storeState={digitalSubmissionState}
      renderLoadedView={() => children}
    />
  );
};

DigitalSubmissionDataProviderComponent.propTypes = {
  children: node,
  digitalSubmissionState: DigitalSubmissionStore.getStateShape().isRequired,
};

DigitalSubmissionDataProviderComponent.defaultProps = {
  children: null,
};

export const DigitalSubmissionDataProvider = connect(state => ({
  digitalSubmissionState: DigitalSubmissionStore.select(state),
}))(DigitalSubmissionDataProviderComponent);
