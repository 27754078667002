import React from 'react';
import {
  Accordions,
  Button,
  Form,
  FormLabel,
  FormGroup,
  Gutter,
  Input,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Radio,
  TaxonomyUtil,
} from '@axiom/ui';
import {
  Candidate,
  CandidateAlertWorkFeed,
  CandidateAlertWorkFeedSchema,
  Taxonomy,
} from '@axiom/validation';
import { CandidateOppFeedAlertsConst } from '@axiom/const';

import { CandidateAlertsWorkFeedApi } from '../../api/candidate-alerts-work-feed-api';
import { WorkFeedFilterAccordion } from '../WorkFeedFilterAccordion/WorkFeedFilterAccordion';
import { usePracticeAreaOptions } from '../../hooks/usePracticeAreaOptions';
import { WorkFeedFilterUtil } from '../../utils/work-feed-filter-util';
import { Option, FilterPill } from '../../types/work-feed-types';

import { WorkFeedAlertsPills } from './WorkFeedAlertsPills';

const { Frequency } = CandidateOppFeedAlertsConst;

const {
  countryOptions,
  engagementLengthOptions,
  legalRoleOptions,
  weeklyCommitmentOptions,
  worksiteOptions,
} = WorkFeedFilterUtil;

export const WorkFeedAlertsModal = ({
  candidateId,
  onClose,
  workFeedAlert,
  taxonomy,
}: {
  candidateId: Candidate['id'];
  onClose: () => void;
  workFeedAlert: CandidateAlertWorkFeed;
  taxonomy: Taxonomy;
}) => {
  const practiceAreaOptions = usePracticeAreaOptions();
  const industryOptions = TaxonomyUtil.getFilteredIndustryOptions(
    taxonomy.industries
  );

  const removeFilter = (
    name: string,
    value: string,
    values: CandidateAlertWorkFeed
  ) => {
    const filter = [...values[name as keyof typeof values]];
    const index = filter.indexOf(value);
    filter.splice(index, 1);
    return filter;
  };

  const isOptionsExpanded = (
    isExpanded: boolean,
    id: string,
    options: Option[],
    maxChildrenShown: number
  ) => {
    if (isExpanded) {
      return isExpanded;
    }
    const index = options.findIndex(option => option.value === id);
    return index >= maxChildrenShown;
  };

  return (
    <Form
      schema={CandidateAlertWorkFeedSchema}
      initialValues={workFeedAlert}
      onSubmit={async changedData => {
        const body = {
          ...changedData,
        };
        await CandidateAlertsWorkFeedApi.updateCandidateAlert(
          candidateId,
          workFeedAlert.id,
          body
        );
        await CandidateAlertsWorkFeedApi.refreshCandidateAlerts(candidateId);
        onClose();
      }}
    >
      {({ fireSubmit, setValues, values }) => {
        return (
          <Modal name="WORK_FEED_ALERTS_MODAL">
            <ModalHeader name="WORK_FEED_ALERTS_MODAL_HEADER" onClose={onClose}>
              Edit Alert
            </ModalHeader>
            <ModalSection>
              <Input name="alertName" label="Alert name" />
              <Gutter bottom="24px" />
              <FormGroup label="Email Frequency">
                <Radio
                  name="frequency"
                  option={Frequency.Instant}
                  displayValue="Instant"
                />
                <Gutter bottom="8px" />
                <Radio
                  name="frequency"
                  option={Frequency.Daily}
                  displayValue="Daily"
                />
                <Gutter bottom="8px" />
                <Radio
                  name="frequency"
                  option={Frequency.Weekly}
                  displayValue="Weekly"
                />
              </FormGroup>
              <Gutter bottom="24px" />
              <FormLabel>Email alert preferences</FormLabel>
              <Gutter bottom="8px" />
              <WorkFeedAlertsPills
                workFeedAlert={values}
                onClear={(pill: FilterPill) => {
                  const { name, value } = pill;
                  const filter = removeFilter(name, value, values);
                  setValues({
                    ...values,
                    [name]: filter,
                  });
                }}
                onClearAll={() =>
                  setValues({
                    ...values,
                    practiceAreas: [],
                    industries: [],
                    weeklyCommitments: [],
                    worksites: [],
                    engagementLengths: [],
                    countries: [],
                    legalRoles: [],
                  })
                }
              />
              <Accordions name="WORK_FEED_ALERTS_ACCORDIONS">
                <WorkFeedFilterAccordion
                  count={values.practiceAreas?.length ?? 0}
                  expandAccordion={!!workFeedAlert.practiceAreas?.length}
                  expandOptions={workFeedAlert.practiceAreas?.reduce(
                    (isExpanded, id) =>
                      isOptionsExpanded(isExpanded, id, practiceAreaOptions, 6),
                    false
                  )}
                  maxChildrenShown={6}
                  name="practiceAreas"
                  options={practiceAreaOptions}
                  title="Practice Area"
                />
                <WorkFeedFilterAccordion
                  count={values.industries?.length ?? 0}
                  expandAccordion={!!workFeedAlert.industries?.length}
                  expandOptions={workFeedAlert.industries?.reduce(
                    (isExpanded, id) =>
                      isOptionsExpanded(isExpanded, id, industryOptions, 6),
                    false
                  )}
                  maxChildrenShown={6}
                  name="industries"
                  options={industryOptions}
                  title="Industry"
                />
                <WorkFeedFilterAccordion
                  count={values.weeklyCommitments?.length ?? 0}
                  expandAccordion={!!workFeedAlert.weeklyCommitments?.length}
                  name="weeklyCommitments"
                  options={weeklyCommitmentOptions}
                  title="Weekly Commitment"
                />
                <WorkFeedFilterAccordion
                  count={values.countries?.length ?? 0}
                  expandAccordion={!!workFeedAlert.countries?.length}
                  name="countries"
                  options={countryOptions}
                  title="Country"
                />
                <WorkFeedFilterAccordion
                  count={values.worksites?.length ?? 0}
                  expandAccordion={!!workFeedAlert.worksites?.length}
                  name="worksites"
                  options={worksiteOptions}
                  title="Worksite"
                />
                <WorkFeedFilterAccordion
                  count={values.engagementLengths?.length ?? 0}
                  expandAccordion={!!workFeedAlert.engagementLengths?.length}
                  name="engagementLengths"
                  options={engagementLengthOptions}
                  title="Engagement Length"
                />
                <WorkFeedFilterAccordion
                  count={values.legalRoles?.length ?? 0}
                  expandAccordion={!!workFeedAlert.legalRoles?.length}
                  name="legalRoles"
                  options={legalRoleOptions}
                  title="Legal Role"
                />
              </Accordions>
            </ModalSection>
            <ModalFooter>
              <Button variation="outline" name="CANCEL" onClick={onClose}>
                Cancel
              </Button>
              <Button name="SUBMIT" onClick={fireSubmit}>
                Save
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    </Form>
  );
};
