import React from 'react';
import { Flashy, Icon, Layout, LayoutItem, Paragraph } from '@axiom/ui';
import { Link } from 'react-router-dom';

import { ActionNeededItem } from '../../utils/profile-completion-util';

export const ProfileCompletionChecklistItem = ({
  actionItem,
}: {
  actionItem: ActionNeededItem;
}) => {
  return (
    <LayoutItem name="PROFILE_COMPLETION_CHECKLIST_ITEM">
      <Layout
        direction="horizontal"
        horizontalGutter="8px"
        name={`pendo-${actionItem.name}`}
      >
        <Flashy color="iconAlert">
          <Icon name="alert-filled" />
        </Flashy>
        <Paragraph>
          <Flashy color="textDefault">
            <Paragraph as="span" name="PREPEND_TEXT">
              {actionItem.prependText}
            </Paragraph>{' '}
            {!!actionItem.linkText && !!actionItem.linkTo && (
              <Link to={actionItem.linkTo} data-test="LINK_TEXT">
                {actionItem.linkText}
              </Link>
            )}
          </Flashy>
        </Paragraph>
      </Layout>
    </LayoutItem>
  );
};
