import React from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  Grid,
  GridRow,
  GridColumn,
  Form,
  Textarea,
  FluidButtonLayout,
  Button,
  Gutter,
  Modal,
  ModalSection,
  ModalHeader,
  ModalFooter,
  Banner,
  CondensedSmall,
  Flashy,
  Layout,
  LayoutItem,
  Paragraph,
  SectionHeader,
} from '@axiom/ui';
import {
  Candidate,
  CandidateProfileIssue,
  CandidateSummarySchema,
} from '@axiom/validation';
import { z } from 'zod';
import { ProfileCompletionConst } from '@axiom/const';
import { CandidateProfileIssuesUtils } from '@axiom/utils';

import { CandidateLegacyApi } from '../../api/candidate-legacy-api';
import { CandidateProfileIssuesApi } from '../../api/candidate-profile-issues-api';

import { ProfileSummaryGuidance } from './ProfileSummaryGuidance';

export const ProfileSummaryEdit = ({
  candidate,
  issues,
  onClose,
}: {
  candidate: Candidate;
  issues: CandidateProfileIssue[];
  onClose: () => void;
}) => {
  const candidateSummaryLengthEmpty =
    CandidateProfileIssuesUtils.hasSummaryOfAnyLengthIssues(issues);
  const candidateSummaryLengthTooShort =
    CandidateProfileIssuesUtils.hasSummaryOfSufficientLengthIssues(issues);

  return (
    <Form
      name="PROFILE_SUMMARY_EDIT_FORM"
      schema={CandidateSummarySchema}
      initialValues={{
        candidateSummary: candidate.candidateSummary,
      }}
      onSubmit={async (formData: z.infer<typeof CandidateSummarySchema>) => {
        // If no changes, just close form
        if (isEmpty(formData)) {
          return onClose();
        }

        await CandidateLegacyApi.updateCandidate(candidate.id, formData);
        await CandidateLegacyApi.refreshCandidate(candidate.id);
        await CandidateProfileIssuesApi.refreshCandidateProfileIssues(
          candidate.id
        );

        return onClose();
      }}
    >
      {({ fireSubmit }) => {
        return (
          <Modal size="large">
            <ModalHeader
              name="PROFILE_SUMMARY_EDIT_HEADER"
              onClose={() => onClose()}
            >
              Edit summary
            </ModalHeader>
            <ModalSection>
              <Grid>
                <GridRow>
                  <GridColumn smallScreenWidth={12} largeScreenWidth={5}>
                    <Layout verticalGutter="16px" wrap>
                      {!!(
                        candidateSummaryLengthEmpty ||
                        candidateSummaryLengthTooShort
                      ) && (
                        <LayoutItem fluid>
                          <Banner
                            name="PROFILE_SUMMARY_EDIT_BANNER"
                            type="info"
                            impact="high"
                          >
                            <Layout
                              horizontalGutter="8px"
                              position="middle"
                              wrap
                            >
                              <LayoutItem>
                                <SectionHeader name="PROFILE_SUMMARY_EDIT_BANNER_TITLE">
                                  {!!candidateSummaryLengthEmpty &&
                                    ProfileCompletionConst
                                      .ProfileCompletionSummaryBanner
                                      .emptyTitle}
                                  {!!candidateSummaryLengthTooShort &&
                                    ProfileCompletionConst
                                      .ProfileCompletionSummaryBanner
                                      .tooShortTitle}
                                </SectionHeader>
                              </LayoutItem>
                              <LayoutItem>
                                <CondensedSmall>
                                  <Flashy color="textSubtle">|</Flashy>
                                </CondensedSmall>
                              </LayoutItem>
                              <LayoutItem>
                                <CondensedSmall>
                                  <Flashy color="textSubtle">10 mins</Flashy>
                                </CondensedSmall>
                              </LayoutItem>
                            </Layout>
                            <Gutter bottom="8px" />
                            <Paragraph name="PROFILE_SUMMARY_EDIT_BANNER_CONTENT">
                              {!!candidateSummaryLengthEmpty &&
                                ProfileCompletionConst
                                  .ProfileCompletionSummaryBanner.emptyContent}
                              {!!candidateSummaryLengthTooShort &&
                                ProfileCompletionConst
                                  .ProfileCompletionSummaryBanner
                                  .tooShortContent}
                            </Paragraph>
                          </Banner>
                        </LayoutItem>
                      )}
                      <LayoutItem fluid>
                        <ProfileSummaryGuidance />
                      </LayoutItem>
                    </Layout>
                    <Gutter bottom="16px" only="smallScreen" />
                  </GridColumn>
                  <GridColumn smallScreenWidth={12} largeScreenWidth={7}>
                    <Textarea
                      name="candidateSummary"
                      label="Profile Summary"
                      enableSuggestionsFor="portal-ProfileSummaryEdit-candidateSummary"
                    />
                  </GridColumn>
                </GridRow>
              </Grid>
            </ModalSection>
            <ModalFooter name="PROFILE_SUMMARY_EDIT_FORM_CONTROLS">
              <Gutter bottom="16px" />
              <FluidButtonLayout>
                <Button
                  variation="outline"
                  name="PROFILE_SUMMARY_EDIT_CANCEL"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button name="PROFILE_SUMMARY_EDIT_SAVE" onClick={fireSubmit}>
                  Save
                </Button>
              </FluidButtonLayout>
            </ModalFooter>
          </Modal>
        );
      }}
    </Form>
  );
};
