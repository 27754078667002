import React, { useState, useRef, useEffect } from 'react';
import { number, func, bool } from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  CondensedLarge,
  ContextMenu,
  ContextMenuDivider,
  ContextMenuHeader,
  Gutter,
  IconButton,
  Layout,
  LayoutItem,
  SectionHeader,
  Visible,
} from '@axiom/ui';

import CollectionStateHelperTreatment from '../CollectionStateHelperTreatment/CollectionStateHelperTreatment';
import { NotificationsStore } from '../../stores/protected/notifications-store';
import { CurrentUserStore } from '../../stores/protected/current-user-store';
import { AppStore } from '../../stores/app-store';
import { useOutsideClick } from '../../hooks/useOutsideClick';

import {
  NotificationsIconWrapper,
  NotificationsIconBadge,
} from './NotificationsStyles';
import NotificationItem from './NotificationsItem';

const NotificationsComponent = ({
  notificationState,
  notifications,
  unreadCount,
  user,
  fetchNotifications,
  markAllNotificationsRead,
  isImpersonating,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const notificationsRef = useRef();

  useOutsideClick(notificationsRef, () => setIsOpen(false));

  useEffect(() => {
    let fetchInterval;

    if (!isImpersonating) {
      fetchNotifications(user.id);
      fetchInterval = setInterval(() => {
        fetchNotifications(user.id);
      }, 60000); // 60 Seconds
    }

    return () => {
      if (fetchInterval) clearInterval(fetchInterval);
    };
  }, []);

  const handleMarkAllClick = () => {
    markAllNotificationsRead(notifications, user.id);
  };

  if (isImpersonating) {
    return null;
  }

  return (
    <ContextMenu
      direction="left"
      fullscreenOn="smallScreen"
      anchor={
        <Gutter right="16px">
          <NotificationsIconWrapper>
            <Visible only="smallScreen">
              <Button
                name="PENDO-NOTIFICATIONS-SMALL-SCREEN"
                icon="bell"
                variation="minimal"
                pattern="secondary"
                onClick={() => setIsOpen(!isOpen)}
              >
                Notifications
              </Button>
            </Visible>
            <Visible only="largeScreen">
              <IconButton
                name="PENDO-NOTIFICATIONS-LARGE-SCREEN"
                icon="bell"
                variation="minimal"
                pattern="secondary"
                onClick={() => setIsOpen(!isOpen)}
              >
                Notifications
              </IconButton>
            </Visible>
            {unreadCount > 0 && <NotificationsIconBadge />}
          </NotificationsIconWrapper>
        </Gutter>
      }
    >
      <ContextMenuHeader>
        <Gutter horizontal="4px" top="8px">
          <Layout position="middle" horizontalGutter="8px">
            <LayoutItem fluid>
              <SectionHeader>Notifications</SectionHeader>
            </LayoutItem>
            {notifications.length > 0 && (
              <Button
                onClick={handleMarkAllClick}
                variation="minimal"
                pattern="secondary"
                name="notificationsMarkAllAsReadButton"
              >
                Read All
              </Button>
            )}
            <Visible only="smallScreen">
              <IconButton
                icon="x-mark"
                pattern="secondary"
                variation="minimal"
              />
            </Visible>
          </Layout>
        </Gutter>
      </ContextMenuHeader>
      <ContextMenuDivider />
      <CollectionStateHelperTreatment
        collectionState={notificationState}
        showRefetchLoader={false}
      >
        {notifications.length === 0 ? (
          <Gutter horizontal="16px" bottom="24px">
            <Layout position="center">
              <CondensedLarge>No Notifications</CondensedLarge>
            </Layout>
          </Gutter>
        ) : (
          notifications.map(item => (
            <NotificationItem notification={item} key={item.id} />
          ))
        )}
      </CollectionStateHelperTreatment>
      <ContextMenuDivider />
    </ContextMenu>
  );
};

NotificationsComponent.defaultProps = {
  unreadCount: 0,
};

NotificationsComponent.propTypes = {
  fetchNotifications: func.isRequired,
  isImpersonating: bool.isRequired,
  markAllNotificationsRead: func.isRequired,
  notifications: NotificationsStore.getDataShape().isRequired,
  notificationState: NotificationsStore.getStateShape().isRequired,
  unreadCount: number,
  user: CurrentUserStore.getStateShape().isRequired,
};

export const Notifications = connect(
  state => ({
    user: CurrentUserStore.selectUser(state),
    notificationState: NotificationsStore.selectNotificationsState(state),
    notifications: NotificationsStore.selectNotifications(state),
    unreadCount: NotificationsStore.selectUnreadCount(state),
    isImpersonating: !!AppStore.selectImpersonatingCandidateId(state),
  }),
  {
    fetchNotifications: NotificationsStore.actionFetchNotifications,
    markAllNotificationsRead: NotificationsStore.actionMarkAllNotificationsRead,
  }
)(NotificationsComponent);
