import { has } from 'lodash';
import { arrayOf, shape } from 'prop-types';

import CollectionStateHelper from '../../lib/collection-state-helper';
import SubmitStateHelper from '../../lib/submit-state-helper';
import GenericStateHelper from '../../lib/generic-state-helper';
import {
  getNotifications,
  putAllNotificationsRead,
  putNotificationRead,
} from '../../api/notifications-api';
import { ObjectUtil } from '../../utils/object-util';
import { NotificationModelShape } from '../../models/notification-model';

export const NOTIFICATIONS_NAMESPACE = 'NOTIFICATIONS';

const stateNotifications = new CollectionStateHelper(
  NOTIFICATIONS_NAMESPACE,
  'FETCH'
);
const stateMarkNotificationRead = new SubmitStateHelper(
  NOTIFICATIONS_NAMESPACE,
  'SUBMIT_MARK_SINGLE'
);
const stateMarkAllNotificationsRead = new SubmitStateHelper(
  NOTIFICATIONS_NAMESPACE,
  'SUBMIT_MARK_ALL'
);

const changeNotificationReadStatus = new GenericStateHelper(
  NOTIFICATIONS_NAMESPACE,
  'CHANGE_NOTIFICATION_READ'
);
const changeAllNotificationReadStatuses = new GenericStateHelper(
  NOTIFICATIONS_NAMESPACE,
  'CHANGE_ALL_NOTIFICATION_READ'
);

export const NotificationsStore = {
  getReducers() {
    return {
      ...stateNotifications.generateReducers(),
      ...stateMarkNotificationRead.generateReducers(),
      ...stateMarkAllNotificationsRead.generateReducers(),
      [changeNotificationReadStatus.getActionName()]: (
        state,
        { notificationId, isRead }
      ) => {
        const newState = ObjectUtil.clone(state);
        newState.data.forEach(item => {
          if (item.id === notificationId) {
            item.isRead = isRead;
          }
        });
        return newState;
      },
      [changeAllNotificationReadStatuses.getActionName()]: (
        state,
        notificationReadStates
      ) => {
        const newState = ObjectUtil.clone(state);
        newState.data.forEach(item => {
          if (has(notificationReadStates, item.id)) {
            item.isRead = notificationReadStates[item.id];
          }
        });
        return newState;
      },
    };
  },
  getStateShape() {
    return shape({
      ...stateNotifications.getStructure(this.getDataShape()),
      submits: shape({
        ...stateMarkNotificationRead.getStructure(),
        ...stateMarkAllNotificationsRead.getStructure(),
      }),
    });
  },
  getDataShape() {
    return arrayOf(NotificationModelShape);
  },
  actionFetchNotifications(userId) {
    return dispatch => {
      dispatch(
        stateNotifications.fetchCollection(
          getNotifications(userId).catch(() => {
            // If error or 404 happens, just set state to success
            dispatch(stateNotifications.setCollection([]));
          })
        )
      );
    };
  },

  actionMarkNotificationRead(notificationId) {
    return dispatch => {
      dispatch(
        changeNotificationReadStatus.actionSet({
          notificationId,
          isRead: true,
        })
      );

      dispatch(
        stateMarkNotificationRead.submitData(
          putNotificationRead(notificationId).catch(() => {
            dispatch(
              changeNotificationReadStatus.actionSet({
                notificationId,
                isRead: true,
              })
            );
          })
        )
      );
    };
  },

  actionMarkAllNotificationsRead(notifications, userId) {
    return dispatch => {
      /**
       * Capture init old_states
       */
      const originalReadState = {};
      const changedReadStates = {};
      (notifications || [])
        .filter(item => !item.isRead)

        .forEach(item => {
          originalReadState[item.id] = item.isRead;
          changedReadStates[item.id] = true;
        });

      dispatch(changeAllNotificationReadStatuses.actionSet(changedReadStates));

      /**
       * Submit call to mark all
       */
      dispatch(
        stateMarkAllNotificationsRead.submitData(
          putAllNotificationsRead(userId).catch(() => {
            /**
             * If Error change notifications back to original state
             */
            dispatch(
              changeAllNotificationReadStatuses.actionSet(originalReadState)
            );
          })
        )
      );
    };
  },

  selectNotificationsState(state) {
    return stateNotifications.select(state);
  },

  selectNotifications(state) {
    return stateNotifications.selectData(state);
  },

  selectUnreadCount(state) {
    return stateNotifications.selectData(state).filter(item => !item.isRead)
      .length;
  },
};
