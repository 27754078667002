import React from 'react';

import { ProfileView } from '../components/ProfileView/ProfileView';
import { TalentDataProvider } from '../providers/TalentDataProvider';
import { PageLayout } from '../layouts/PageLayout/PageLayout';

export const TalentProfilePage = () => (
  <TalentDataProvider>
    <PageLayout showTalentLinks>
      <ProfileView />
    </PageLayout>
  </TalentDataProvider>
);
