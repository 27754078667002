import ApiHelper from '../lib/api-helper';

import { CANDIDATE_ACTIVITIES_API_NAME } from './constants';

const api = new ApiHelper(CANDIDATE_ACTIVITIES_API_NAME);

export const getProfileImageUrl = candidateID =>
  `/api/candidates/${candidateID}/profileImage`;

export const putProfileImage = async (candidateID, profileImgUri) => {
  const response = await api.PUTIMAGE(
    `/candidates/${candidateID}/profileImage`,
    'document',
    profileImgUri
  );
  return { data: response.data };
};

export const deleteProfileImage = async candidateID => {
  const { data } = await api.DELETE(`/candidates/${candidateID}/profileImage`);
  return { data };
};
