import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Accordions, Form, useApi, TaxonomyUtil } from '@axiom/ui';
import {
  Candidate,
  CandidateWorkFeedFormFilter,
  CandidateWorkFeedFormFilterSchema,
} from '@axiom/validation';

import { WorkFeedFilterUtil } from '../../utils/work-feed-filter-util';
import { WorkFeedFilterAccordion } from '../WorkFeedFilterAccordion/WorkFeedFilterAccordion';
import { Option } from '../../types/work-feed-types';
import { usePracticeAreaOptions } from '../../hooks/usePracticeAreaOptions';
import { PracticeAreaApi } from '../../api/practice-areas-api';
import { TaxonomyApi } from '../../api/taxonomy-api';

const {
  countryOptions,
  engagementLengthOptions,
  legalRoleOptions,
  opportunityStageOptions,
  weeklyCommitmentOptions,
  worksiteOptions,
} = WorkFeedFilterUtil;

export const WorkFeedFiltersForm = ({
  candidate,
  filters,
  minimal,
}: {
  candidate: Candidate;
  filters: CandidateWorkFeedFormFilter;
  minimal?: boolean;
}) => {
  const [, setSearchParams] = useSearchParams();
  const [{ data: practiceAreas }, { data: taxonomy }] = useApi(
    PracticeAreaApi.readPracticeAreas(),
    TaxonomyApi.readTaxonomy()
  );
  const practiceAreaOptions = usePracticeAreaOptions();
  const industryOptions = TaxonomyUtil.getFilteredIndustryOptions(
    taxonomy.industries
  );

  const isOptionsExpanded = (
    isExpanded: boolean,
    id: string,
    options: Option[],
    maxChildrenShown: number
  ) => {
    if (isExpanded) {
      return isExpanded;
    }
    const index = options.findIndex(option => option.value === id);
    return index >= maxChildrenShown;
  };

  return (
    <Form
      name="opportunity_feed_filters_form_treatment"
      schema={CandidateWorkFeedFormFilterSchema}
      initialValues={filters}
      submitOnChange
      onSubmit={changedData => {
        return Promise.resolve().then(() => {
          WorkFeedFilterUtil.applyFilters(
            setSearchParams,
            candidate,
            practiceAreas,
            {
              ...filters,
              ...changedData,
            }
          );
        });
      }}
    >
      {({ values }) => (
        <Accordions name="WORK_FEED_FILTERS_ACCORDIONS" minimal={minimal}>
          <WorkFeedFilterAccordion
            count={values.opportunityPhases?.length ?? 0}
            expandAccordion={!!filters.opportunityPhases?.length}
            minimal={minimal}
            name="opportunityPhases"
            options={opportunityStageOptions}
            title="Opportunity Stage"
          />
          <WorkFeedFilterAccordion
            count={values.practiceAreaId?.length ?? 0}
            expandAccordion={!!filters.practiceAreaId?.length}
            expandOptions={filters.practiceAreaId?.reduce(
              (isExpanded, id) =>
                isOptionsExpanded(isExpanded, id, practiceAreaOptions, 6),
              false
            )}
            maxChildrenShown={6}
            minimal={minimal}
            name="practiceAreaId"
            options={practiceAreaOptions}
            title="Practice Area"
          />
          <WorkFeedFilterAccordion
            count={values.industries?.length ?? 0}
            expandAccordion={!!filters.industries?.length}
            expandOptions={filters.industries?.reduce(
              (isExpanded, id) =>
                isOptionsExpanded(isExpanded, id, industryOptions, 6),
              false
            )}
            maxChildrenShown={6}
            minimal={minimal}
            name="industries"
            options={industryOptions}
            title="Industry"
          />
          <WorkFeedFilterAccordion
            count={values.timeCommitment?.length ?? 0}
            expandAccordion={!!filters.timeCommitment?.length}
            minimal={minimal}
            name="timeCommitment"
            options={weeklyCommitmentOptions}
            title="Weekly Commitment"
          />
          <WorkFeedFilterAccordion
            count={values.countries?.length ?? 0}
            expandAccordion={!!filters.countries?.length}
            minimal={minimal}
            name="countries"
            options={countryOptions}
            title="Country"
          />
          <WorkFeedFilterAccordion
            count={values.worksites?.length ?? 0}
            expandAccordion={!!filters.worksites?.length}
            minimal={minimal}
            name="worksites"
            options={worksiteOptions}
            title="Worksite"
          />
          <WorkFeedFilterAccordion
            count={values.engagementLength?.length ?? 0}
            expandAccordion={!!filters.engagementLength?.length}
            minimal={minimal}
            name="engagementLength"
            options={engagementLengthOptions}
            title="Engagement Length"
          />
          <WorkFeedFilterAccordion
            count={values.talentTypes?.length ?? 0}
            expandAccordion={!!filters.talentTypes?.length}
            minimal={minimal}
            name="talentTypes"
            options={legalRoleOptions}
            title="Legal Role"
          />
        </Accordions>
      )}
    </Form>
  );
};
