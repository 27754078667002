import { shape, string, bool } from 'prop-types';

import RecordStateHelper from '../../lib/record-state-helper';
import { getBannerMessage } from '../../api/banner-messages-api';

export const BANNER_MESSAGES_NAMESPACE = 'BANNER_MESSAGES';
const stateBannerMessages = new RecordStateHelper(
  BANNER_MESSAGES_NAMESPACE,
  'FETCH'
);
export const BannerMessagesStore = {
  getReducers() {
    return {
      ...stateBannerMessages.generateReducers(),
    };
  },

  getStateShape() {
    return shape({
      ...stateBannerMessages.getStructure(this.getDataShape()),
    });
  },

  getDataShape() {
    return shape({
      appName: string,
      createdAt: string,
      isActive: bool,
      text: string,
      type: string,
      updatedAt: string,
    });
  },

  actionSetBannerMessages(bannerMessages) {
    return stateBannerMessages.setRecord(bannerMessages);
  },
  actionFetchBannerMessages() {
    return stateBannerMessages.fetchRecord(getBannerMessage());
  },
  selectBannerMessages(state) {
    return stateBannerMessages.selectData(state);
  },
};
