import {
  string,
  number,
  array,
  arrayOf,
  bool,
  object,
  oneOfType,
  shape,
} from 'prop-types';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';

import { ObjectUtil } from '../utils/object-util';
import { StateComponentUtil } from '../utils/state-component-util';

import { BadRequestError } from './errors';

export const GenericStoreStructure = {
  isLoading: bool,
  hasLoaded: bool,
  errors: arrayOf(
    shape({
      statusCode: number,
      errors: arrayOf(oneOfType([string, object])),
    })
  ),
  data: oneOfType([array, object]),
};

const mergeData = (state, data) => {
  if (!state.data || !data) {
    return data;
  }

  if (Array.isArray(state.data)) {
    /**
     * We can't merge arrays because it'll spread dup objects if
     * you refetch and setData on a collection
     */
    return [...data];
  }
  return {
    ...state.data,
    ...data,
  };
};
const parseErrors = errors =>
  errors.map(e =>
    e instanceof BadRequestError && isFunction(e.getStoreErrors)
      ? e.getStoreErrors()
      : e
  );

export default class GenericStore {
  initializeNamespace({ namespace }) {
    this.namespace = namespace.toUpperCase();
  }

  initializeDispatch(dispatch) {
    this.dispatch = dispatch;
  }

  dispatchAction(action) {
    this.dispatch(action);
  }

  getInitialState() {
    return {
      isLoading: false,
      hasLoaded: false,
      data: undefined,
      errors: [],
    };
  }

  getDataShape() {
    return oneOfType([array, object]);
  }

  getStateShape() {
    return shape(this.getStateStructure());
  }

  getStateStructure() {
    return {
      ...GenericStoreStructure,
      data: this.getDataShape(),
    };
  }

  getReducers() {
    return {
      [this.getCallLoadingActionName()]: state => ({
        ...state,
        isLoading: true,
        errors: [],
      }),
      [this.getSetDataActionName()]: (state, payload) => ({
        ...state,
        isLoading: false,
        hasLoaded: true,
        errors: [],
        data: mergeData(state, payload),
      }),
      [this.getFinishedWatchingActionName()]: state => ({
        ...state,
        isLoading: false,
        hasLoaded: true,
        errors: [],
      }),
      [this.getCallErrorActionName()]: (state, errors) => ({
        ...state,
        errors: parseErrors(errors),
      }),
      [this.getResetActionName()]: state => ({
        ...state,
        ...this.getInitialState(),
      }),
    };
  }

  /**
   * this.setFromStateData((data) => data)
   */
  setDataFromStateData(onGetStateData) {
    this.dispatch((dispatch, getState) => {
      const { data } = get(getState(), this.namespace, {});
      dispatch({
        type: this.getSetDataActionName(),
        payload: onGetStateData(data),
      });
    });
  }

  /**
   *
   * @param promiseOrData
   * @param dispatch - Only if you need to deal with SSR. Otherwise dispatch is baked into the UI version of redux
   * @returns {Promise<unknown>|Promise<void>}
   */
  setState(promiseOrData, dispatch = this.dispatch) {
    if (promiseOrData instanceof Promise) {
      dispatch({
        type: this.getCallLoadingActionName(),
      });

      return promiseOrData
        .then(data => {
          dispatch({
            type: this.getSetDataActionName(),
            payload: data,
          });

          return data;
        })
        .catch(e => {
          this.setErrorState(e);

          /**
           * We want to throw the e so that any thens attached to the promise don't fire
           */
          throw e;
        });
    }

    return Promise.resolve().then(() => {
      /**
       * If the data has multiple nested object pointers, its easier to break them
       * at once than rely on them not being mutated in the components
       */
      promiseOrData = ObjectUtil.clone(promiseOrData);

      dispatch({
        type: this.getSetDataActionName(),
        payload: promiseOrData,
      });

      return promiseOrData;
    });
  }

  clearState(nullOrPromise) {
    if (nullOrPromise instanceof Promise) {
      this.dispatch({
        type: this.getCallLoadingActionName(),
      });

      return nullOrPromise
        .then(() => {
          this.dispatch({
            type: this.getResetActionName(),
          });
        })
        .catch(e => {
          this.setErrorState(e);

          /**
           * We want to throw the e so that any thens attached to the promise don't fire
           */
          throw e;
        });
    }

    return Promise.resolve().then(() => {
      this.dispatch({
        type: this.getResetActionName(),
      });

      return nullOrPromise;
    });
  }

  watchState(promise) {
    this.dispatch({
      type: this.getCallLoadingActionName(),
    });

    return promise
      .then(() => {
        this.dispatch({
          type: this.getFinishedWatchingActionName(),
        });
      })
      .catch(e => {
        this.setErrorState(e);

        /**
         * We want to throw the e so that any thens attached to the promise don't fire
         */
        throw e;
      });
  }

  setErrorState(e) {
    this.dispatch({
      type: this.getCallErrorActionName(),
      errors: Array.isArray(e) ? [...e] : [e],
    });
  }

  select(state) {
    return get(state, this.namespace, {});
  }

  /**
   * If we override this for preloaded stores, we still want to be able to access
   * root state
   */
  selectRoot(state) {
    return get(state, this.namespace, {});
  }

  isRenderingLoadedView(state) {
    return StateComponentUtil.shouldShowComponent([state]);
  }

  getSetDataActionName() {
    return `${this.namespace}/SET`;
  }

  getCallLoadingActionName() {
    return `${this.namespace}/LOADING_CALL`;
  }

  getCallErrorActionName() {
    return `${this.namespace}/ERROR_CALL`;
  }

  getFinishedWatchingActionName() {
    return `${this.namespace}/FINISH_LOAD_CALL`;
  }

  getResetActionName() {
    return `${this.namespace}/RESET_CALL`;
  }
}
