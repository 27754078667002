import { any, arrayOf, number, oneOf, shape, string } from 'prop-types';

import {
  TALENT_FIELD_ACTIVITY_LOG,
  TALENT_FIELD_ADDRESS_1,
  TALENT_FIELD_ADDRESS_2,
  TALENT_FIELD_ADDRESS_CITY,
  TALENT_FIELD_ADDRESS_COUNTRY,
  TALENT_FIELD_ADDRESS_COUNTRY_CODE,
  TALENT_FIELD_ADDRESS_DISPLAY_NAME,
  TALENT_FIELD_ADDRESS_STATE,
  TALENT_FIELD_ADDRESS_ZIP,
  TALENT_FIELD_ALPHABETICALLY_SMALLEST_ENGAGEMENT_ACCOUNT_NAME,
  TALENT_FIELD_ALPHABETICALLY_SMALLEST_ENGAGEMENT_END_DATE,
  TALENT_FIELD_AVAILABILITY,
  TALENT_FIELD_AVAILABILITY_NOTES,
  TALENT_FIELD_AXIOM_EMPLOYEE_TYPE,
  TALENT_FIELD_BARRED_LOCATIONS,
  TALENT_FIELD_BULLHORN_ID,
  TALENT_FIELD_CANDIDACY_ENGAGEMENTS,
  TALENT_FIELD_CANDIDACY_LIST,
  TALENT_FIELD_COLLABORATORS,
  TALENT_FIELD_COLLABORATOR_ID,
  TALENT_FIELD_COMPENSATION,
  TALENT_FIELD_CREATED_AT,
  TALENT_FIELD_DAYS_SINCE_STATUS_CHANGE,
  TALENT_FIELD_DESIRED_COMMUTE,
  TALENT_FIELD_DESIRED_ENGAGEMENT_SPECIFIC_COMPENSATION,
  TALENT_FIELD_EMPLOYEE_TYPE,
  TALENT_FIELD_EXPERIENCE,
  TALENT_FIELD_FIRST_NAME,
  TALENT_FIELD_FURTHEST_CANDIDACY_STATUS,
  TALENT_FIELD_FURTHEST_CANDIDACY_STATUS_UPDATED_AT,
  TALENT_FIELD_PRACTICE_START_YEAR,
  TALENT_FIELD_HOME_OFFICE,
  TALENT_FIELD_HOME_PHONE,
  TALENT_FIELD_HOURLY_COMPENSATION,
  TALENT_FIELD_ID,
  TALENT_FIELD_LANGUAGE_CEFR,
  TALENT_FIELD_LANGUAGES,
  TALENT_FIELD_LAST_NAME,
  TALENT_FIELD_LAST_UPDATED_BY,
  TALENT_FIELD_LATEST_ENGAGEMENT_ACCOUNT_NAME,
  TALENT_FIELD_LATEST_ENGAGEMENT_END_DATE,
  TALENT_FIELD_LAW_SCHOOL,
  TALENT_FIELD_MATTER_ID,
  TALENT_FIELD_MIDDLE_NAME,
  TALENT_FIELD_MOBILE_PHONE,
  TALENT_FIELD_NOTICE_PERIOD,
  TALENT_FIELD_OCCUPATION_TYPE,
  TALENT_FIELD_OWNER_FULL_NAME,
  TALENT_FIELD_OWNER_LAST_NAME,
  TALENT_FIELD_OWNER_USER_ID,
  TALENT_FIELD_PERSONAL_EMAIL,
  TALENT_FIELD_PROFILE_STATUS,
  TALENT_FIELD_PROFILE_STATUS_UPDATED_AT,
  TALENT_FIELD_SOONEST_ENGAGEMENT_ACCOUNT_NAME,
  TALENT_FIELD_SOONEST_ENGAGEMENT_END_DATE,
  TALENT_FIELD_STATE_EXAM_SCORE,
  TALENT_FIELD_SKILLS,
  TALENT_FIELD_UPDATED_AT,
  TALENT_FIELD_WEEKLY_AVAILABILITY,
  TALENT_FIELD_WORK_EMAIL,
  TALENT_FIELD_YEARS_OF_EXPERIENCE,
} from '../constants/talent-fields';

export const ACTIVE = 'Active';
export const ALUM = 'Alum';
export const ALUM_BEACH = 'Alum Beach';
export const BEACH = 'Beach';
export const IN_DILIGENCE = 'In Diligence';
export const ORBIT = 'Orbit';
export const RESERVED = 'Reserved';
export const WAITLIST = 'Waitlist';
export const WARM_ORBIT = 'Warm Orbit';

const yesOrNo = oneOf(['Yes', 'No']);

const talentModel = {
  [TALENT_FIELD_ACTIVITY_LOG]: arrayOf(any),
  [TALENT_FIELD_ADDRESS_1]: string,
  [TALENT_FIELD_ADDRESS_2]: string,
  [TALENT_FIELD_ADDRESS_CITY]: string,
  [TALENT_FIELD_ADDRESS_COUNTRY_CODE]: string,
  [TALENT_FIELD_ADDRESS_COUNTRY]: string,
  [TALENT_FIELD_ADDRESS_DISPLAY_NAME]: string,
  [TALENT_FIELD_ADDRESS_STATE]: string,
  [TALENT_FIELD_ADDRESS_ZIP]: string,
  [TALENT_FIELD_ALPHABETICALLY_SMALLEST_ENGAGEMENT_ACCOUNT_NAME]: string,
  [TALENT_FIELD_ALPHABETICALLY_SMALLEST_ENGAGEMENT_END_DATE]: string,
  [TALENT_FIELD_AVAILABILITY_NOTES]: string,
  [TALENT_FIELD_AVAILABILITY]: string,
  [TALENT_FIELD_AXIOM_EMPLOYEE_TYPE]: string,
  [TALENT_FIELD_BARRED_LOCATIONS]: arrayOf(shape({})),
  [TALENT_FIELD_BULLHORN_ID]: string,
  [TALENT_FIELD_CANDIDACY_ENGAGEMENTS]: arrayOf(any),
  [TALENT_FIELD_CANDIDACY_LIST]: arrayOf(any),
  [TALENT_FIELD_COLLABORATOR_ID]: arrayOf(any),
  [TALENT_FIELD_COLLABORATORS]: arrayOf(any),
  [TALENT_FIELD_COMPENSATION]: string,
  [TALENT_FIELD_CREATED_AT]: string,
  [TALENT_FIELD_DAYS_SINCE_STATUS_CHANGE]: number,
  [TALENT_FIELD_DESIRED_COMMUTE]: yesOrNo, // bool,
  [TALENT_FIELD_DESIRED_ENGAGEMENT_SPECIFIC_COMPENSATION]: yesOrNo, // bool,
  [TALENT_FIELD_EMPLOYEE_TYPE]: string,
  [TALENT_FIELD_EXPERIENCE]: arrayOf(any),
  [TALENT_FIELD_FIRST_NAME]: string,
  [TALENT_FIELD_FURTHEST_CANDIDACY_STATUS_UPDATED_AT]: string,
  [TALENT_FIELD_FURTHEST_CANDIDACY_STATUS]: string,
  [TALENT_FIELD_PRACTICE_START_YEAR]: number,
  [TALENT_FIELD_HOME_OFFICE]: shape({}),
  [TALENT_FIELD_HOME_PHONE]: string,
  [TALENT_FIELD_HOURLY_COMPENSATION]: string,
  [TALENT_FIELD_ID]: string,
  [TALENT_FIELD_LANGUAGE_CEFR]: arrayOf(any),
  [TALENT_FIELD_LANGUAGES]: arrayOf(any),
  [TALENT_FIELD_LAST_NAME]: string,
  [TALENT_FIELD_LAST_UPDATED_BY]: string,
  [TALENT_FIELD_LATEST_ENGAGEMENT_ACCOUNT_NAME]: string,
  [TALENT_FIELD_LATEST_ENGAGEMENT_END_DATE]: string,
  [TALENT_FIELD_LAW_SCHOOL]: string,
  [TALENT_FIELD_MATTER_ID]: string,
  [TALENT_FIELD_MIDDLE_NAME]: string,
  [TALENT_FIELD_MOBILE_PHONE]: string,
  [TALENT_FIELD_NOTICE_PERIOD]: string,
  [TALENT_FIELD_OCCUPATION_TYPE]: string,
  [TALENT_FIELD_OWNER_FULL_NAME]: string,
  [TALENT_FIELD_OWNER_LAST_NAME]: string,
  [TALENT_FIELD_OWNER_USER_ID]: string,
  [TALENT_FIELD_PERSONAL_EMAIL]: string,
  practiceAreaId: string,
  practiceArea: shape({
    bullhornId: string,
    id: string,
    name: string,
  }),
  [TALENT_FIELD_PROFILE_STATUS_UPDATED_AT]: string,
  [TALENT_FIELD_PROFILE_STATUS]: string,
  [TALENT_FIELD_SOONEST_ENGAGEMENT_ACCOUNT_NAME]: string,
  [TALENT_FIELD_SOONEST_ENGAGEMENT_END_DATE]: string,
  [TALENT_FIELD_STATE_EXAM_SCORE]: string,
  [TALENT_FIELD_SKILLS]: arrayOf(any),
  [TALENT_FIELD_UPDATED_AT]: string,
  [TALENT_FIELD_WEEKLY_AVAILABILITY]: number,
  [TALENT_FIELD_WORK_EMAIL]: string,
  [TALENT_FIELD_YEARS_OF_EXPERIENCE]: number,
};

export const talentShape = shape(talentModel);
export const talentModelProperties = Object.keys(talentModel);

export default talentModel;
