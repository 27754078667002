/* eslint-disable react/destructuring-assignment */

import React from 'react';
import { string, node } from 'prop-types';
import { ChevronIcon } from '@axiom/ui';

import { ComponentShape } from '../../utils/prop-type-util';
import { formatDataTestId } from '../../utils/data-test-util';

import {
  QuickLinksCaret,
  QuickLinksUlWrapper,
  QuickLinksWrapper,
} from './QuickLinksStyles';

/**
 * To link to a section, you must add <section name="sectionName" />
 */

const INITIAL_STATE = {
  isFloating: false,
};

export default class QuickLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
    this.quickLinks = React.createRef();
  }

  componentDidMount() {
    this.lastSidePanel = window.document.querySelector(
      this.props.placeUnderParentCssSelector || `#quick-links-fixture`
    );

    if (!this.lastSidePanel) return;

    window.addEventListener('scroll', () => {
      // using pageYOffset over scrollY for IE11 compatibility
      const top = window.pageYOffset;
      const qlbottom =
        this.quickLinks.current.offsetTop +
        this.quickLinks.current.offsetHeight;
      const spbottom =
        this.lastSidePanel.offsetTop + this.lastSidePanel.offsetHeight;
      const bottom = Math.max(qlbottom, spbottom);

      if (
        bottom >= top ||
        top <= Number.parseInt(this.props.fixedTopAmount, 10)
      ) {
        if (this.state.isFloating !== false) {
          this.setState({ isFloating: false });
        }
      } else if (this.state.isFloating !== true) {
        this.setState({ isFloating: true });
      }
    });
  }

  componentWillUnmount() {
    window.addEventListener('scroll', () => {});
  }

  scrollToTop = event => {
    // eslint-disable-next-line no-unused-expressions
    event && event.preventDefault();

    window.document
      .querySelector('body')
      .scrollIntoView({ behavior: 'smooth' });
  };

  render() {
    const { isFloating } = this.state;

    return (
      <div id="quick-links-fixture">
        <QuickLinksWrapper
          minWidth={this.props.minWidth}
          maxWidth={
            this.props.maxWidth ||
            (this.lastSidePanel && `${this.lastSidePanel.offsetWidth}px`)
          }
          isFloating={isFloating}
          fixedTopAmount={this.props.fixedTopAmount}
          fixedLeftAmount={this.props.fixedLeftAmount}
          floatingTopAmount={this.props.floatingTopAmount}
          floatingHeight={this.props.floatingHeight}
          data-test={formatDataTestId('PENDO_QUICK_LINKS_WRAPPER')}
        >
          <QuickLinksUlWrapper className="links" ref={this.quickLinks}>
            <li>{this.props.headerText}</li>
            {this.props.renderListItems}
          </QuickLinksUlWrapper>
          <QuickLinksCaret
            isActive={isFloating}
            data-test={formatDataTestId('PENDO_QUICK_LINKS_BACK_TO_TOP')}
          >
            <a
              href="#"
              className="caret"
              aria-label="Scroll to top"
              onClick={event => this.scrollToTop(event)}
            >
              <ChevronIcon direction="up" height={19} width={19} />
            </a>
          </QuickLinksCaret>
        </QuickLinksWrapper>
      </div>
    );
  }
}

QuickLinks.propTypes = {
  fixedLeftAmount: string,
  fixedTopAmount: string.isRequired,
  floatingHeight: string,
  floatingTopAmount: string.isRequired,
  headerText: node.isRequired,
  maxWidth: string,
  minWidth: string,
  placeUnderParentCssSelector: string,
  renderListItems: ComponentShape.isRequired,
};

QuickLinks.defaultProps = {
  placeUnderParentCssSelector: undefined,
  fixedLeftAmount: 'inherit',
  minWidth: undefined,
  maxWidth: undefined,
  floatingHeight: 'inherit',
};
