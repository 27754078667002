import React from 'react';

import { TalentDataProvider } from '../providers/TalentDataProvider';
import { PageLayout } from '../layouts/PageLayout/PageLayout';
import { PreferencesProfileVisibility } from '../components/Preferences/PreferencesProfileVisibility';

export const ProfileVisibilityPreferencesPage = () => (
  <TalentDataProvider>
    <PageLayout showTalentLinks name="PROFILE-VISIBILITY-PREFERENCES-PAGE">
      <PreferencesProfileVisibility />
    </PageLayout>
  </TalentDataProvider>
);
