import React from 'react';

import { TalentDataProvider } from '../providers/TalentDataProvider';
import { PageLayout } from '../layouts/PageLayout/PageLayout';
import { PreferencesCommunication } from '../components/Preferences/PreferencesCommunication';

export const CommunicationsPreferencesPage = () => (
  <TalentDataProvider>
    <PageLayout showTalentLinks name="COMMUNICATIONS-PREFERENCES-PAGE">
      <PreferencesCommunication />
    </PageLayout>
  </TalentDataProvider>
);
