import GenericStore from '../classes/generic-store';
import { getBarredLocations } from '../api/barred-locations-api';

class PreloadedBarredLocationsStoreClass extends GenericStore {
  load() {
    return this.setState(getBarredLocations());
  }

  selectData(state) {
    return this.select(state)?.data || [];
  }
}

export const PreloadedBarredLocationsStore =
  new PreloadedBarredLocationsStoreClass();
