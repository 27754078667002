import GenericStateHelper from '../lib/generic-state-helper';
import { getBannerMessage } from '../api/banner-messages-api';
import { getBarredLocations } from '../api/barred-locations-api';
import { getLoggedUser } from '../api/user-api';
import { getSkills } from '../api/tags-api';

import { BannerMessagesStore } from './protected/banner-messages-store';
import { BarredLocationsStore } from './protected/barred-locations-store';
import { CurrentUserStore } from './protected/current-user-store';
import { CurrentCandidateStore } from './protected/current-candidate-store';
import { SkillsStore } from './protected/skills-store';
import { CandidateExperiencesStore } from './protected/candidate-experiences-store';
import { CandidateLanguagesStore } from './protected/candidate-languages-store';
import { CandidateOpportunitiesStore } from './protected/candidate-opportunities-store';
import { PreloadedPracticeAreasStore } from './preloaded-practice-areas-store';
import { PreloadedBarredLocationsStore } from './preloaded-barred-locations-store';

export const APP_NAMESPACE = 'APP';

const stateAppError = new GenericStateHelper(APP_NAMESPACE, 'APP_ERROR');
const stateAppErrorDialog = new GenericStateHelper(
  APP_NAMESPACE,
  'APP_ERROR_DIALOG'
);
const stateAppConfirmDialog = new GenericStateHelper(
  APP_NAMESPACE,
  'APP_CONFIRM_DIALOG'
);
const stateImpersonate = new GenericStateHelper(APP_NAMESPACE, 'IMPERSONATE');

const ERROR_DIALOG_NAME = 'errorDialog';
const CONFIRM_DIALOG_NAME = 'confirmDialog';
const APP_ERROR = 'appException';
const IMPERSONATE = 'impersonate';

export const AppStore = {
  getReducers() {
    return {
      [stateAppErrorDialog.getActionName()]: (state, errorDialogObj) => ({
        ...state,
        [ERROR_DIALOG_NAME]: {
          ...errorDialogObj,
        },
      }),
      [stateAppConfirmDialog.getActionName()]: (state, confirmDialogObj) => ({
        ...state,
        [CONFIRM_DIALOG_NAME]: {
          ...confirmDialogObj,
        },
      }),
      [stateAppError.getActionName()]: (state, error) => ({
        ...state,
        [APP_ERROR]: {
          ...error,
        },
      }),
      [stateImpersonate.getActionName()]: (state, impersonateObj) => ({
        ...state,
        [IMPERSONATE]: impersonateObj,
      }),
    };
  },
  initTalentAppState: impersonateCandidateId => dispatch =>
    Promise.all([
      getBannerMessage(),
      getBarredLocations(),
      getLoggedUser(),
      getSkills(),
    ]).then(([bannerMessages, barredLocations, user, skills]) => {
      dispatch(BannerMessagesStore.actionSetBannerMessages(bannerMessages));
      dispatch(BarredLocationsStore.actionSetBarredLocations(barredLocations));
      dispatch(CurrentUserStore.actionSetUser(user));
      dispatch(SkillsStore.actionSetSkills(skills));

      const candidateId = impersonateCandidateId || user.candidateId;

      if (candidateId) {
        if (
          impersonateCandidateId &&
          impersonateCandidateId !== user.candidateId
        ) {
          dispatch(AppStore.actionImpersonateCandidate(impersonateCandidateId));
        }
        dispatch(
          CurrentCandidateStore.actionFetchCandidate(candidateId, user, true)
        );
        dispatch(CandidateExperiencesStore.actionFetchExperiences(candidateId));
        dispatch(CandidateLanguagesStore.actionFetchLanguages(candidateId));
        CandidateOpportunitiesStore.load(candidateId);
        PreloadedPracticeAreasStore.load();
        PreloadedBarredLocationsStore.load();
      } else {
        dispatch(AppStore.actionShowStoreError('Profile not found'));
      }
    }),

  actionShowStoreError(errorMessage) {
    return stateAppError.actionSet({ errorMessage });
  },
  actionShowErrorDialog(errorTitle, errorDescription) {
    return stateAppErrorDialog.actionSet({ errorTitle, errorDescription });
  },

  actionShowConfirmDialog({
    bodyText,
    headerText,
    onConfirmClick = () => {},
    onCancelClick = () => {},
    showCancelButton = false,
    cancelButtonText = 'Cancel',
    confirmButtonText = 'Confirm',
  } = {}) {
    return stateAppConfirmDialog.actionSet({
      bodyText,
      headerText,
      onConfirmClick,
      onCancelClick,
      showCancelButton,
      cancelButtonText,
      confirmButtonText,
    });
  },
  actionImpersonateCandidate(candidateId) {
    return stateImpersonate.actionSet({ candidateId });
  },

  actionClearErrorDialog() {
    return stateAppErrorDialog.actionSet({});
  },
  actionClearConfirmDialog() {
    return stateAppConfirmDialog.actionSet({});
  },

  selectAppError(state) {
    return stateAppError.select(state)[APP_ERROR];
  },
  selectErrorDialog(state) {
    return stateAppErrorDialog.select(state)[ERROR_DIALOG_NAME];
  },
  selectConfirmDialog(state) {
    return stateAppConfirmDialog.select(state)[CONFIRM_DIALOG_NAME];
  },
  selectImpersonatingCandidateId(state) {
    return stateImpersonate.select(state)[IMPERSONATE]
      ? stateImpersonate.select(state)[IMPERSONATE].candidateId
      : null;
  },
};
