import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import {
  Button,
  DateUtil,
  Gutter,
  Layout,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
  SmallHeader,
} from '@axiom/ui';
import { Experience } from '@axiom/validation';

import { ExperienceEditContext } from './ExperienceEditContext';

export type ExperienceEditHeaderProps = {
  experience?: Experience;
};

export const ExperienceEditHeader = ({
  experience,
}: ExperienceEditHeaderProps) => {
  const navigate = useNavigate();
  const { dirtyFormState } = useContext(ExperienceEditContext);
  const [showModal, setShowModal] = useState(false);
  const onProfileNav = () => {
    navigate('../../profile');
  };

  return (
    <div data-test="EXPERIENCE_EDIT_HEADER">
      <Button
        name="EXPERIENCE_EDIT_HEADER_BACK_TO_PROFILE"
        pattern="secondary"
        variation="minimal"
        icon="arrow-left"
        iconPosition="left"
        onClick={event => {
          if (Object.values(dirtyFormState).includes(true)) {
            event.preventDefault();
            setShowModal(true);
          } else {
            onProfileNav();
          }
        }}
      >
        Back to profile
      </Button>
      <Gutter bottom="24px" />
      <Layout
        stackableOn="mobile"
        position="space-between"
        verticalGutter="24px"
      >
        <SmallHeader name="EXPERIENCE_EDIT_HEADER_TITLE">
          {isEmpty(experience) ? (
            <span data-test="PREPEND">Add an experience</span>
          ) : (
            [
              <span data-test="PREPEND" key="PREPEND">
                Edit your experience{' '}
              </span>,
              experience.client && (
                <span data-test="CLIENT" key="CLIENT">
                  at {experience.client}{' '}
                </span>
              ),
              experience.startDate && (
                <span data-test="DATE_RANGE" key="DATE_RANGE">
                  {DateUtil.displayDateRange(
                    experience.startDate,
                    experience.endDate
                  )}
                </span>
              ),
            ].filter(Boolean)
          )}
        </SmallHeader>
      </Layout>
      {!!showModal && (
        <Modal name="EXPERIENCE_EDIT_HEADER_UNSAVED_CHANGES_MODAL">
          <ModalHeader
            name="EXPERIENCE_EDIT_HEADER_UNSAVED_CHANGES_MODAL_HEADER"
            onClose={() => setShowModal(false)}
          >
            You have unsaved changes
          </ModalHeader>
          <ModalSection>
            <Paragraph>
              If you go back to profile now, you will lose the changes you’ve
              made on this page.
            </Paragraph>
          </ModalSection>
          <ModalFooter>
            <Button
              name="EXPERIENCE_EDIT_HEADER_CONFIRM_BACK_TO_PROFILE"
              variation="outline"
              onClick={() => onProfileNav()}
            >
              Back to profile
            </Button>
            <Button
              name="EXPERIENCE_CONTINUE_EDITING"
              onClick={() => setShowModal(false)}
            >
              Continue editing
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};
