import React from 'react';
import { Card, CardSection } from '@axiom/ui';
import { Candidate, CandidateWorkFeedItem } from '@axiom/validation';

import { WorkFeedViewsType } from '../../utils/WorkFeedUtil';

import { WorkFeedCardHeader } from './WorkFeedCardHeader';
import { WorkFeedCardBadges } from './WorkFeedCardBadges';
import { WorkFeedCardMessages } from './WorkFeedCardMessages';
import { WorkFeedCardDataPoints } from './WorkFeedCardDataPoints';
import { WorkFeedCardJobDescription } from './WorkFeedCardJobDescription';
import { WorkFeedCardFooter } from './WorkFeedCardFooter';

export const WorkFeedCard = ({
  candidate,
  workFeedItem,
  view,
  index,
}: {
  candidate: Candidate;
  workFeedItem: CandidateWorkFeedItem;
  view: WorkFeedViewsType;
  index: number;
}) => {
  const oppFeedCardDataTestLabel =
    index > 0 ? 'OPPORTUNITY_FEED_CARD' : 'OPPORTUNITY_FEED_FIRST_CARD';

  return (
    <div className="gtm-aft-oppfeed-card-view" data-test="WORK_FEED_CARD">
      <Card name={oppFeedCardDataTestLabel}>
        <WorkFeedCardHeader workFeedItem={workFeedItem} view={view} />
        <CardSection divider>
          <WorkFeedCardBadges workFeedItem={workFeedItem} />
          <WorkFeedCardMessages
            workFeedItem={workFeedItem}
            candidate={candidate}
          />
          <WorkFeedCardDataPoints workFeedItem={workFeedItem} index={index} />
          <WorkFeedCardJobDescription workFeedItem={workFeedItem} view={view} />
        </CardSection>
        <WorkFeedCardFooter
          workFeedItem={workFeedItem}
          view={view}
          candidate={candidate}
        />
      </Card>
    </div>
  );
};
