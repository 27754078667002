import { Api } from '@axiom/ui';
import { AreaOfStudy } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class AreasOfStudyApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiPortalUrl,
      cookieDomain: EnvUtil.cookieDomain,
    });
  }

  readAreasOfStudy() {
    return super.read<{ data: AreaOfStudy[] }>({
      endpoint: `/areasOfStudy`,
      method: 'GET',
    });
  }
}

export const AreasOfStudyApi = new AreasOfStudyApiClass();
