import ApiHelper from '../lib/api-helper';

import { CANDIDATE_LANGUAGES_API_NAME } from './constants';

const api = new ApiHelper(CANDIDATE_LANGUAGES_API_NAME);

export const getLanguagesByCandidateId = async candidateId => {
  const { data } = await api.GET(`/candidates/${candidateId}/languages`);
  return data;
};
