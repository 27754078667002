import React, { useState } from 'react';
import { Candidate, Certification } from '@axiom/validation';
import {
  IconButton,
  Layout,
  LayoutItem,
  Paragraph,
  ParagraphHeader,
} from '@axiom/ui';

import { AccomplishmentsDeleteModal } from './AccomplishmentsDeleteModal';

export const AccomplishmentsItemDisplay = ({
  candidateId,
  certification,
  onOpenForm,
}: {
  candidateId: Candidate['id'];
  certification: Certification;
  onOpenForm: () => void;
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  return (
    <div data-test="ACCOMPLISHMENTS_ITEM_DISPLAY">
      <Layout position="middle">
        <LayoutItem fluid>
          <ParagraphHeader name="AWARD">{certification.award}</ParagraphHeader>
        </LayoutItem>
        <LayoutItem>
          <Layout horizontalGutter="8px" position="middle">
            <IconButton
              icon="trash"
              pattern="secondary"
              variation="minimal"
              name="DELETE_ACCOMPLISHMENT_BUTTON"
              onClick={() => setIsDeleteModalOpen(true)}
            />
            <IconButton
              icon="pencil"
              pattern="secondary"
              variation="minimal"
              name="EDIT_ACCOMPLISHMENT_BUTTON"
              onClick={onOpenForm}
            />
          </Layout>
        </LayoutItem>
      </Layout>
      <Paragraph name="INSTITUTION_YEAR">
        {certification.institution}, {certification.year}
      </Paragraph>
      {!!isDeleteModalOpen && (
        <AccomplishmentsDeleteModal
          candidateId={candidateId}
          certificationId={certification.id}
          onClose={() => setIsDeleteModalOpen(false)}
        />
      )}
    </div>
  );
};
