import React from 'react';
import { shape } from 'prop-types';
import { CandidateOpportunitiesConst } from '@axiom/const';
import {
  Gutter,
  Button,
  Banner,
  Layout,
  LayoutItem,
  CompanyLogo,
  SmallHeader,
  CondensedLarge,
  Grid,
  GridRow,
  GridColumn,
  CondensedHeader,
  Brief,
  FluidButtonLayout,
} from '@axiom/ui';

import RichTextEditor from '../RichTextEditor/RichTextEditor';
import { DateUtil } from '../../utils/date-util';
import { CandidateOpportunitiesStore } from '../../stores/protected/candidate-opportunities-store';
import { PreloadedPracticeAreasStore } from '../../stores/preloaded-practice-areas-store';
import { LocationUtil } from '../../utils/location-util';
import { EnvUtil } from '../../utils/env-util';

import { CandidateStatusMessageWrapper } from './SubmissionsHistoryItemStyles';

export const SubmissionsHistoryItem = ({ opportunity, practiceAreas }) => {
  return (
    <>
      {!!(
        CandidateOpportunitiesConst.CandidateStatuses.Cooled ===
          opportunity.candidateStatus || opportunity.candidateStatusMessage
      ) && (
        <Gutter bottom="32px">
          <Banner>
            <CandidateStatusMessageWrapper>
              {opportunity.candidateStatusMessage ||
                'Thanks for your interest in this opportunity. Unfortunately it is no longer available.'}
            </CandidateStatusMessageWrapper>
            {!opportunity.candidateStatusMessageDismissedAt &&
              opportunity.candidateStatus ===
                CandidateOpportunitiesConst.CandidateStatuses.Cooled && (
                <Gutter top="24px">
                  <FluidButtonLayout>
                    <Button
                      onClick={() => {
                        CandidateOpportunitiesStore.patchHistorySubmissionItem(
                          opportunity.candidateId,
                          opportunity.id
                        );
                      }}
                      pattern="secondary"
                      name="CONFIRM_COOLED_OPP_BUTTON"
                    >
                      Got it
                    </Button>
                  </FluidButtonLayout>
                </Gutter>
              )}
          </Banner>
        </Gutter>
      )}
      <Layout position="middle" horizontalGutter="16px">
        <LayoutItem>
          <CompanyLogo
            src={`${EnvUtil.clearbitLogoUrl}/${opportunity.account?.website}`}
            alt={opportunity.account?.calculatedName}
          />
        </LayoutItem>
        <LayoutItem fluid>
          <SmallHeader>{opportunity.jobName}</SmallHeader>
          <Gutter bottom="8px" />
          <CondensedLarge>{opportunity.accountName}</CondensedLarge>
        </LayoutItem>
      </Layout>
      <Gutter bottom="24px" />
      <Grid columns="equal">
        <GridRow gutterBottom="8px">
          <GridColumn largeScreenWidth="3" smallScreenWidth="12">
            <CondensedHeader>Last Updated</CondensedHeader>
          </GridColumn>
          <GridColumn largeScreenWidth="3" smallScreenWidth="12">
            <CondensedLarge>
              {DateUtil.displayShortDate(opportunity.candidateStatusUpdatedAt)}
            </CondensedLarge>
            <Gutter bottom="16px" only="smallScreen" />
          </GridColumn>
          <GridColumn largeScreenWidth="2" smallScreenWidth="12">
            <CondensedHeader>Practice Area</CondensedHeader>
          </GridColumn>
          <GridColumn largeScreenWidth="4" smallScreenWidth="12">
            <CondensedLarge>
              {practiceAreas?.raw[opportunity.practiceAreaId]?.name || '--'}
            </CondensedLarge>
            <Gutter bottom="8px" only="smallScreen" />
          </GridColumn>
        </GridRow>

        <GridRow gutterBottom="8px">
          <GridColumn largeScreenWidth="3" smallScreenWidth="12">
            <CondensedHeader>Engagement Length</CondensedHeader>
          </GridColumn>
          <GridColumn largeScreenWidth="3" smallScreenWidth="12">
            <CondensedLarge>
              {!!opportunity.estimatedEngagementTotalMonths &&
                `~${opportunity.estimatedEngagementTotalMonths} months`}
            </CondensedLarge>
            <Gutter bottom="16px" only="smallScreen" />
          </GridColumn>
          <GridColumn largeScreenWidth="2" smallScreenWidth="12">
            <CondensedHeader>Location</CondensedHeader>
          </GridColumn>
          <GridColumn largeScreenWidth="4" smallScreenWidth="12">
            <CondensedLarge>
              {LocationUtil.displayFormattedJobLocation(
                opportunity,
                opportunity?.position
              )}
            </CondensedLarge>
            <Gutter bottom="8px" only="smallScreen" />
          </GridColumn>
        </GridRow>

        <GridRow>
          <GridColumn largeScreenWidth="3" smallScreenWidth="12">
            <CondensedHeader>Weekly Commitment</CondensedHeader>
          </GridColumn>
          <GridColumn largeScreenWidth="3" smallScreenWidth="12">
            <CondensedLarge>
              {opportunity.weeklyTimeCommitment || '--'}
            </CondensedLarge>
            <Gutter bottom="16px" only="smallScreen" />
          </GridColumn>
          <GridColumn largeScreenWidth="2" smallScreenWidth="12">
            <CondensedHeader>Role</CondensedHeader>
          </GridColumn>
          <GridColumn largeScreenWidth="4" smallScreenWidth="12">
            <CondensedLarge>{opportunity.role}</CondensedLarge>
          </GridColumn>
        </GridRow>
      </Grid>

      <Gutter bottom="24px" />

      <CondensedHeader>Work Description</CondensedHeader>
      <Gutter bottom="8px" />
      <Brief briefMaxHeight="200px" pattern="secondary">
        <CondensedLarge>
          <RichTextEditor
            readOnly
            initialValue={opportunity.description || '--'}
          />
        </CondensedLarge>
      </Brief>
    </>
  );
};

SubmissionsHistoryItem.defaultProps = {
  practiceAreas: {
    raw: {},
  },
};

SubmissionsHistoryItem.propTypes = {
  opportunity: shape({}).isRequired,
  practiceAreas: PreloadedPracticeAreasStore.getDataShape(),
};
