import React, { useMemo, useState } from 'react';
import { z } from 'zod';
import { useParams } from 'react-router-dom';
import {
  Badge,
  Banner,
  Button,
  Gutter,
  Modal,
  ModalHeader,
  ModalSection,
  ModalFooter,
  Paragraph,
  Flashy,
  Checkbox,
  Textarea,
  Form,
  useApi,
  ApiError,
  FormGroup,
  Layout,
  Radio,
  LayoutItem,
  CondensedLarge,
} from '@axiom/ui';
import {
  Candidate,
  CandidateWorkFeedItem,
  ExpressInterestSchema,
} from '@axiom/validation';

import { CandidateOpportunitiesApi } from '../../api/candidate-opportunities-api';
import { WorkFeedUtil, WorkFeedViewsType } from '../../utils/WorkFeedUtil';
import { WorkFeedApi } from '../../api/candidate-workfeed-api';
import { CandidateOpportunitiesLegacyApi } from '../../api/candidate-opportunities-legacy-api';

import { WarningsList } from './WorkFeedDirectExpressionModalStyles';

export const WorkFeedDirectExpressionModal = ({
  onClose,
  candidate,
  workFeedItem,
  view,
}: {
  onClose: () => void;
  candidate: Candidate;
  workFeedItem: CandidateWorkFeedItem;
  view: WorkFeedViewsType;
}) => {
  const params = useParams();
  const [{ interestedCount }, { data: candidateOpportunities }] = useApi(
    WorkFeedApi.readWorkFeedInterestedCount(candidate.id),
    CandidateOpportunitiesLegacyApi.readOpportunitiesByCandidateId(candidate.id)
  );

  const qualificationWarnings = useMemo(
    () =>
      WorkFeedUtil.getQualificationWarnings(
        candidate,
        workFeedItem,
        candidateOpportunities
      ),
    [candidate, workFeedItem]
  );

  const [
    showOpenToLowerEscCompensationPercentage,
    setShowOpenToLowerEscCompensationPercentage,
  ] = useState(false);

  return (
    <Form
      schema={ExpressInterestSchema}
      initialValues={{
        opportunityId: workFeedItem.opportunity.id,
        roleFitDescription: candidate.candidateSummary || '',
      }}
      onSubmit={async (formData: z.infer<typeof ExpressInterestSchema>) => {
        try {
          await CandidateOpportunitiesApi.writeWorkFeedInterested(
            candidate.id,
            {
              ...formData,
              roleFitDescription:
                formData.roleFitDescription ?? candidate.candidateSummary,
              opportunityId: workFeedItem.opportunity.id,
            }
          );
          await WorkFeedApi.refreshAllOfWorkFeed(
            candidate.id,
            WorkFeedUtil.isSinglePageView(view)
              ? workFeedItem.opportunity.id
              : null,
            true
          );
          WorkFeedUtil.addInterestedToasts(
            interestedCount,
            `${params.pageNumber ? '../../../' : '../'}interested`
          );
          return onClose();
        } catch (e) {
          if (e instanceof ApiError && e.getStatusCode() === 424) {
            return WorkFeedUtil.addErrorToast(candidate.ownerUser?.email);
          }
          throw e;
        }
      }}
    >
      {({ fireSubmit }) => {
        return (
          <Modal name="DIRECT_EXPRESSION_OPPORTUNITY">
            <ModalHeader
              name="DIRECT_EXPRESSION_OPPORTUNITY_MODAL_HEADER"
              onClose={onClose}
            >
              Submit Profile to Client
            </ModalHeader>
            <ModalSection>
              <div
                data-test="PENDO_ANALYTICS"
                data-pendo-analytics-id="expressed_interest"
                data-pendo-analytics-opportunity-id={
                  workFeedItem.opportunity.id
                }
                data-pendo-analytics-qualification-warnings={qualificationWarnings.join(
                  ', '
                )}
                data-pendo-analytics-phase={workFeedItem.phase}
              >
                <Gutter bottom="24px">
                  <Badge background="sky">Submit to Client</Badge>
                </Gutter>

                <Gutter bottom="24px">
                  <Paragraph>
                    <Flashy bold>
                      This immediately shares your profile with Axiom's client.
                    </Flashy>{' '}
                    Please make sure it is up to date. We will reach out if the
                    client decides to interview you.
                  </Paragraph>
                </Gutter>

                {qualificationWarnings.length > 0 && (
                  <Gutter bottom="24px" name="QUALIFICATION_WARNINGS">
                    <Banner type="error">
                      <Paragraph>
                        <strong>Before confirming,</strong> check that your
                        qualifications align with these opportunity details, or
                        tell us why we should still consider you.
                      </Paragraph>
                      <WarningsList>
                        {qualificationWarnings.map(warning => (
                          <li key={warning}>{warning}</li>
                        ))}
                      </WarningsList>
                    </Banner>
                  </Gutter>
                )}

                <Gutter bottom="24px">
                  <LayoutItem bottomGutter="8px">
                    <CondensedLarge>
                      Include a cover letter for the client to review with your
                      profile. Briefly describe why you are interested in this
                      work and what makes you uniquely qualified for the role.{' '}
                      <Flashy color="textAlert">*</Flashy>
                    </CondensedLarge>
                  </LayoutItem>
                  <Textarea
                    name="roleFitDescription"
                    enableSuggestionsFor="portal-WorkFeedDirectExpressionModal-roleFitDescription"
                  />
                </Gutter>
                <Gutter bottom="24px">
                  <Checkbox
                    name="hasNoConflicts"
                    displayValue="To my knowledge this opportunity does not present any actual or potential conflicts of interest that would prevent me from acting for the client on this matter."
                  />
                </Gutter>
                <Gutter bottom="24px">
                  <Checkbox
                    name="openToLowerEsc"
                    onChange={() =>
                      setShowOpenToLowerEscCompensationPercentage(
                        !showOpenToLowerEscCompensationPercentage
                      )
                    }
                    displayValue="I would be willing to discuss accepting moderately lower compensation for this specific opportunity if the client's budget is restrictive."
                  />
                </Gutter>
                {!!showOpenToLowerEscCompensationPercentage && (
                  <Banner>
                    <LayoutItem bottomGutter="8px">
                      <CondensedLarge>
                        How much of a reduction in compensation would you be
                        willing to accept for this opportunity? If Axiom moves
                        forward with your candidacy, we will call you to
                        finalize your rate. <Flashy color="textAlert">*</Flashy>
                      </CondensedLarge>
                    </LayoutItem>
                    <FormGroup name="openToLowerEscCompensationPercentage">
                      <Layout direction="vertical" verticalGutter="8px">
                        {[5, 10, 15, 20, 25].map(item => (
                          <Radio
                            key={item}
                            name="openToLowerEscCompensationPercentage"
                            option={item}
                            displayValue={`Up to ${item}%`}
                          />
                        ))}
                      </Layout>
                    </FormGroup>
                  </Banner>
                )}
              </div>
            </ModalSection>
            <ModalFooter>
              <Button variation="outline" name="CANCEL" onClick={onClose}>
                Cancel
              </Button>

              <Button name="SUBMIT" onClick={fireSubmit}>
                Submit to Client
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    </Form>
  );
};
