import React from 'react';
import {
  CondensedSmall,
  Flashy,
  Gutter,
  Layout,
  LayoutItem,
  Paragraph,
  SectionHeader,
  Well,
} from '@axiom/ui';

export interface ExperienceEditIssueProps {
  title: string;
  description: string;
  estimatedTime: string;
  name?: string;
}

export const ExperienceEditIssue = ({
  description,
  estimatedTime,
  name = null,
  title,
}: ExperienceEditIssueProps) => {
  return (
    <Well name={name ?? 'WELL_PROMPT'} background="blue">
      <Layout horizontalGutter="8px" position="middle" wrap>
        <LayoutItem>
          <SectionHeader name="TITLE">{title}</SectionHeader>
        </LayoutItem>
        <LayoutItem>
          <CondensedSmall>
            <Flashy color="textReversed">|</Flashy>
          </CondensedSmall>
        </LayoutItem>
        <LayoutItem>
          <CondensedSmall>
            <Flashy color="textReversed" name="ESTIMATED_TIME">
              {estimatedTime}
            </Flashy>
          </CondensedSmall>
        </LayoutItem>
      </Layout>
      <Gutter bottom="8px" />
      <Paragraph name="DESCRIPTION">{description}</Paragraph>
    </Well>
  );
};
