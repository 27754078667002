import { Api } from '@axiom/ui';
import { Candidate, Experience, ExperienceEdit } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class ExperienceApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiPortalUrl,
    });
  }

  createExperience(candidateId: Candidate['id'], experience: ExperienceEdit) {
    return super.write<{ data: Experience }>({
      endpoint: `/candidates/${candidateId}/experiences`,
      body: experience,
      method: 'POST',
    });
  }

  updateExperience(id: Experience['id'], experience: ExperienceEdit) {
    return super.write<{ data: Experience }>({
      endpoint: `/experiences/${id}`,
      body: experience,
      method: 'PATCH',
    });
  }

  deleteExperience(id: Experience['id']) {
    return super.write<{ data: Experience }>({
      endpoint: `/experiences/${id}`,
      method: 'DELETE',
    });
  }
}

export const ExperienceApi = new ExperienceApiClass();
