import React from 'react';
import { CandidateWorkFeedItem } from '@axiom/validation';
import {
  backgroundColorNames,
  Badge,
  Gutter,
  Layout,
  BadgeBackgroundType,
  CondensedMedium,
} from '@axiom/ui';
import { WorkFeedConst } from '@axiom/const';

import { WorkFeedUtil } from '../../utils/WorkFeedUtil';
import { DateUtil } from '../../utils/date-util';

const { Phases } = WorkFeedConst;

const badgeColorMap: Record<string, BadgeBackgroundType> = {
  [Phases.New]: backgroundColorNames.orange,
  [Phases.Submitted]: backgroundColorNames.lightOrange,
  [Phases.Interviewing]: backgroundColorNames.lightOrange,
  [Phases.Closed]: backgroundColorNames.gray,
};

export const WorkFeedCardBadges = ({
  workFeedItem,
}: {
  workFeedItem: CandidateWorkFeedItem;
}) => {
  const displayBadges =
    !workFeedItem.rejectionLossCode &&
    !WorkFeedUtil.isUnavailableToCandidate(workFeedItem) &&
    !workFeedItem.opportunity.isExcludedFromFeed;
  const displayAcceptingSinceEOI =
    workFeedItem.opportunity.madeAvailableToFeedNotificationAt;
  return displayAcceptingSinceEOI || displayBadges ? (
    <Gutter bottom="16px">
      <Layout
        name="WORK_FEED_CARD_BADGES"
        horizontalGutter="8px"
        verticalGutter="8px"
        wrap
      >
        {!!displayBadges && (
          <Badge
            name="OPPORTUNITY_STAGE"
            background={badgeColorMap[workFeedItem.phase]}
          >
            {WorkFeedUtil.formatPhaseForDisplay(workFeedItem.phase)}
          </Badge>
        )}

        {!!displayBadges && !!workFeedItem.opportunity.directEligible && (
          <Badge
            name="OPPORTUNITY_DIRECT_ELIGIBLE"
            background={backgroundColorNames.sky}
          >
            Submit to Client
          </Badge>
        )}
        {!!displayAcceptingSinceEOI && (
          <CondensedMedium
            as="span"
            data-test="OPPORTUNITY_TIME_SINCE_ACCEPTING_INTEREST"
          >
            Accepting interest as of{' '}
            {DateUtil.displayRelativeTime(
              workFeedItem.opportunity.madeAvailableToFeedNotificationAt
            )}
          </CondensedMedium>
        )}
      </Layout>
    </Gutter>
  ) : null;
};
