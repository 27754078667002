import React from 'react';
import {
  CondensedLarge,
  Gutter,
  AccordionsItem,
  AccordionsItemHeader,
  AccordionsItemSection,
  Accordions,
  CondensedHeader,
} from '@axiom/ui';

export const ProfileSummaryGuidance = () => {
  return (
    <Accordions>
      <AccordionsItem name="PENDO_PROFILE_SUMMARY_EDIT_ACCORDION_WRITE">
        <AccordionsItemHeader>
          <CondensedHeader>How to write a great summary</CondensedHeader>
        </AccordionsItemHeader>
        <AccordionsItemSection>
          <Gutter top="24px" bottom="16px">
            <CondensedLarge>
              A compelling profile summary makes a first impression that will
              grab the attention of prospective clients. This summary appears
              directly below your profile photo in the bio that Axiom shares
              with clients, and on other client and public-facing sites that
              feature Axiom talent (if you're opted in).
            </CondensedLarge>
          </Gutter>
          <CondensedHeader>Guidelines:</CondensedHeader>
          <Gutter bottom="16px" />
          <ul>
            <li>
              Specifics on prior, substantive experience and firm and company
              employers are ideal.
              <Gutter bottom="16px" />
            </li>
            <li>
              We recommend avoiding non-substantive descriptions involving work
              style, overall aptitude, or professionalism. Our clients will
              safely assume those attributes across the Axiom talent population.
              The less jargon, the better.
              <Gutter bottom="16px" />
            </li>
            <li>
              3-4 sentences in length, with a minimum of 200 and maximum of 500
              characters.
            </li>
          </ul>
          <CondensedHeader>A few things NOT to include:</CondensedHeader>
          <Gutter bottom="16px" />
          <ul>
            <li>
              Bullets, dashes or similar formatting.
              <Gutter bottom="16px" />
            </li>
            <li>
              First-person voice.
              <Gutter bottom="16px" />
            </li>
            <li>
              Any confidential or personally identifiable information.
              <Gutter bottom="16px" />
            </li>
            <li>
              Prior Axiom client names. References to industry sector of prior
              Axiom clients and company type are fine (global software company,
              big four accounting firm, large financial institution).
              <Gutter bottom="16px" />
            </li>
            <li>Subjective qualifiers like great, excellent, deep, etc.</li>
          </ul>
        </AccordionsItemSection>
      </AccordionsItem>
      <AccordionsItem name="PENDO_PROFILE_SUMMARY_EDIT_ACCORDION_EXAMPLES">
        <AccordionsItemHeader>
          <CondensedHeader>Great summary examples</CondensedHeader>
        </AccordionsItemHeader>
        <AccordionsItemSection>
          <Gutter top="24px" bottom="16px">
            <CondensedHeader>
              Here are some examples you can use for inspiration or as a
              starting point.
            </CondensedHeader>
          </Gutter>
          <ul>
            <li>
              Former AGC and Assistant Secretary of a Fortune 500 commercial
              bank. Experience in corporate governance, commercial,
              transactional, securities, and litigation matters. Eight years of
              law firm experience advising public and private companies in
              mergers and acquisitions, equity investments, restructurings, and
              SEC compliance.
              <Gutter bottom="16px" />
            </li>
            <li>
              In-house alum of F250 software company. Experienced in licensing,
              product counseling, marketing agreements, and general commercial
              work. Five years of experience with an Amlaw100 firm advising
              public and private hardware and software companies.
              <Gutter bottom="16px" />
            </li>
            <li>
              Experienced privacy counselor. Supported multiple global companies
              with GDPR and CCPA compliance, from program design to execution.
              CIPP certified. Recent experience advising retailers, e-commerce
              companies, and other large organizations who collect heavy volumes
              of personally identifiable information.
              <Gutter bottom="16px" />
            </li>
            <li>
              Life sciences attorney with law firm and F500 in-house experience
              with pharmaceutical and medical device companies. Experience
              supporting promotional review, clinical trial work, sales support,
              and commercial transactions. Undergraduate degree in biological
              sciences.
            </li>
          </ul>
        </AccordionsItemSection>
      </AccordionsItem>
    </Accordions>
  );
};
