import React from 'react';
import orderBy from 'lodash/orderBy';
import { useApi } from '@axiom/ui';
import { Candidate } from '@axiom/validation';

import { TaxonomyApi } from '../../api/taxonomy-api';
import { CandidateExperienceApi } from '../../api/candidate-experience-api';
import { CandidateProfileIssuesApi } from '../../api/candidate-profile-issues-api';

import { ExperiencesCard } from './ExperiencesCard';

export const Experiences = ({
  candidateId,
}: {
  candidateId: Candidate['id'];
}) => {
  const [{ data: taxonomy }, { data: experiences }, { data: profileIssues }] =
    useApi(
      TaxonomyApi.readTaxonomy(),
      CandidateExperienceApi.readExperiences(candidateId),
      CandidateProfileIssuesApi.readCandidateProfileIssues(candidateId)
    );

  const orderedExperiences = orderBy(
    experiences,
    ['endDate', 'startDate', 'isAxiom'],
    ['desc', 'desc', 'desc']
  );

  return (
    <ExperiencesCard
      experiences={orderedExperiences}
      taxonomy={taxonomy}
      candidateId={candidateId}
      issues={profileIssues.issues}
    />
  );
};
