import { shape } from 'prop-types';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';

import { getSubmission } from '../api/submissions-api';
import GenericStore from '../classes/generic-store';

class DigitalSubmissionStoreClass extends GenericStore {
  load(submissionId, candidateId = null) {
    return this.setState(
      getSubmission(submissionId).then((data = {}) => {
        const candidateOpportunities = (
          candidateId
            ? (data?.candidateOpportunities ?? []).filter(
                ({ candidate }) => candidate.id === candidateId
              )
            : sortBy(data?.candidateOpportunities || [], [
                'submissionRank',
                'candidate.calculatedDisplayName',
              ])
        ).map(candidateOpportunity => {
          return {
            ...candidateOpportunity,
            candidate: {
              ...candidateOpportunity.candidate,
              certifications: orderBy(
                candidateOpportunity.candidate.certifications,
                'year',
                'desc'
              ),
              experiences: candidateOpportunity.candidate.experiences,
              languages: orderBy(
                candidateOpportunity.candidate.languages,
                'name',
                'asc'
              ),
            },
          };
        });

        return {
          ...data,
          candidateOpportunities,
          isTalentSubmission: !!candidateId,
        };
      })
    );
  }

  getDataShape() {
    return shape({});
  }
}

export const DigitalSubmissionStore = new DigitalSubmissionStoreClass();
