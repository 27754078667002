import { shape } from 'prop-types';
import { CandidateOpportunitiesConst } from '@axiom/const';

import FormStateHelper from '../../lib/form-state-helper';
import { updateOpportunityByCandidate } from '../../api/candidate-opportunities-legacy-api';
import { AppStore } from '../app-store';

import { CandidateOpportunitiesStore } from './candidate-opportunities-store';

export const FORM_NEW_OPPORTUNITY_UNSURE_NAMESPACE =
  'FORM_NEW_OPPORTUNITY_UNSURE';

const formNewOppUnsureState = new FormStateHelper(
  FORM_NEW_OPPORTUNITY_UNSURE_NAMESPACE,
  'MAIN'
);

export const FormNewOpportunityUnsureStore = {
  getReducers() {
    return {
      ...formNewOppUnsureState.generateReducers(),
    };
  },
  getStateShape() {
    return shape({
      ...formNewOppUnsureState.getStructure(this.getDataShape()),
    });
  },
  getDataShape() {
    return shape({});
  },
  actionShowForm(opportunity) {
    const oppForm = {
      opportunityId: opportunity.id,
    };
    return formNewOppUnsureState.showForm(oppForm, opportunity.id);
  },
  actionHideForm() {
    return formNewOppUnsureState.hideForm();
  },
  actionSubmitForm(candidateId, oppUnsureFormData) {
    oppUnsureFormData.candidateStatus =
      CandidateOpportunitiesConst.CandidateStatuses.WarmedUnsure;
    return dispatch =>
      dispatch(
        formNewOppUnsureState.submitForm(
          updateOpportunityByCandidate(
            candidateId,
            oppUnsureFormData.opportunityId,
            oppUnsureFormData
          )
            .then(() => {
              CandidateOpportunitiesStore.load(candidateId);
            })
            .catch(() => {
              dispatch(
                AppStore.actionShowErrorDialog(
                  'Submit Error',
                  'There was an error submitting your data. Please try again later.'
                )
              );
            })
        )
      );
  },

  selectFormState(state) {
    return formNewOppUnsureState.select(state);
  },
  selectFormData(state) {
    return formNewOppUnsureState.selectData(state);
  },
};
