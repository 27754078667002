import { shape, string, arrayOf } from 'prop-types';

import CollectionStateHelper from '../../lib/collection-state-helper';
import { getBarredLocations } from '../../api/barred-locations-api';

export const BARRED_LOCATION_NAMESPACE = 'BARRED_LOCATIONS';
const stateBarredLocations = new CollectionStateHelper(
  BARRED_LOCATION_NAMESPACE,
  'FETCH'
);
export const BarredLocationsStore = {
  getReducers() {
    return {
      ...stateBarredLocations.generateReducers(),
    };
  },
  getStateShape() {
    return shape({
      ...stateBarredLocations.getStructure(this.getDataShape()),
    });
  },
  getDataShape() {
    return arrayOf(
      shape({
        createdAt: string,
        id: string,
        name: string,
        updatedAt: string,
      })
    );
  },
  actionSetBarredLocations(barredLocations) {
    return stateBarredLocations.setCollection(barredLocations);
  },
  actionFetchBarredLocations() {
    return stateBarredLocations.fetchCollection(getBarredLocations());
  },
  selectBarredLocations(state) {
    return stateBarredLocations.selectData(state);
  },
};
