import React from 'react';
import { bool } from 'prop-types';

import { CollectionStateHelperShape } from '../../lib/collection-state-helper';
import { LoadingCurtain } from '../LoadingCurtain/LoadingCurtain';
import { OldStateComponentUtil } from '../../utils/old-state-component-util';
import LoadingGradient from '../LoadingGradient/LoadingGradient';
import { ComponentShape } from '../../utils/prop-type-util';
import { ErrorBlurb } from '../ErrorBlurb/ErrorBlurb';

/**
 * Use this for handling when the store data is ready for use.
 *
 * Assuming you connect selectors look like this:
 * export default connect(state => ({
    donutsState: DonutsStore.selectDonutsState(state),
    donuts: DonutsStore.selectDonutsData(state)
  }))(ListOfDonutsPage);

 *
 * <CollectionStateHelperTreatment collectionState={donutsState}>
 *    {donuts.map(donut => <div>{donut.name}</div>)}
 *  </CollectionStateHelperTreatment>
 *
 *  This will handle showing the loader while the data is fetched, then load the
 *  child component
 */
const CollectionStateHelperTreatment = ({
  collectionState,
  children,
  initialLoader,
  refetchLoader,
  showInitialLoader,
  showRefetchLoader,
  errorComponent,
}) => {
  /**
   * Need this console log, else it'll get swallowed.
   */
  if (collectionState.error) {
    // eslint-disable-next-line no-console
    console.error(collectionState.error);
  }

  return (
    <>
      {OldStateComponentUtil.shouldShowError(collectionState) && errorComponent}
      {OldStateComponentUtil.shouldShowInitialLoader(collectionState) &&
        !!showInitialLoader &&
        initialLoader}
      {OldStateComponentUtil.shouldShowBlockingLoader(collectionState) &&
        !!showRefetchLoader &&
        refetchLoader}
      {OldStateComponentUtil.shouldShowComponent(collectionState) && children}
    </>
  );
};

CollectionStateHelperTreatment.propTypes = {
  children: ComponentShape,
  collectionState: CollectionStateHelperShape,
  errorComponent: ComponentShape,
  initialLoader: ComponentShape,
  refetchLoader: ComponentShape,
  showInitialLoader: bool,
  showRefetchLoader: bool,
};

CollectionStateHelperTreatment.defaultProps = {
  collectionState: {},
  children: null,
  initialLoader: <LoadingGradient lines={3} />,
  refetchLoader: <LoadingCurtain />,
  errorComponent: <ErrorBlurb />,
  showInitialLoader: true,
  showRefetchLoader: true,
};

export default CollectionStateHelperTreatment;
