import { ZodSchema } from 'zod';

export const ExperienceEditSectionKeys = {
  COMPANY_DETAILS: 'COMPANY_DETAILS',
  WORK_DETAILS: 'WORK_DETAILS',
  SKILLS: 'SKILLS',
} as const;

export const ExperienceEditUtil = {
  setAllToFalse: (state: Record<string, boolean>) => {
    return Object.keys(state).reduce(
      (acc: Record<string, boolean>, key: string) => {
        acc[key] = false;
        return acc;
      },
      {}
    );
  },
  isDataValid: (schema: ZodSchema, data: Record<string, unknown>) => {
    return schema.safeParse(data).success;
  },
};
