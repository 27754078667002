import React, { useState } from 'react';
import {
  Button,
  CondensedLarge,
  Flashy,
  Gutter,
  Layout,
  LayoutItem,
  useApi,
  Well,
} from '@axiom/ui';
import { Candidate } from '@axiom/validation';

import { CandidateAlertsWorkFeedApi } from '../../api/candidate-alerts-work-feed-api';
import { TaxonomyApi } from '../../api/taxonomy-api';

import { WorkFeedAlertsModal } from './WorkFeedAlertsModal';
import { WorkFeedAlertsPills } from './WorkFeedAlertsPills';

export const WorkFeedAlerts = ({
  candidateId,
}: {
  candidateId: Candidate['id'];
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [{ data: workFeedAlerts }, { data: taxonomy }] = useApi(
    CandidateAlertsWorkFeedApi.readCandidateAlerts(candidateId),
    TaxonomyApi.readTaxonomy()
  );

  // should only happen in the case of BSEs
  if (workFeedAlerts.length === 0) {
    return (
      <div data-test="WORK_FEED_ALERTS_EMPTY">
        <CondensedLarge>
          You currently do not have any Work Feed Alerts
        </CondensedLarge>
      </div>
    );
  }

  // expect only 1 alert to be returned
  const workFeedAlert = workFeedAlerts[0];

  return (
    <div data-test="WORK_FEED_ALERTS">
      <Layout position="center middle">
        <LayoutItem fluid rightGutter="16px">
          <Well name="ALERT_NAME">{workFeedAlert.alertName}</Well>
        </LayoutItem>
        <LayoutItem>
          <Button
            name="OPEN_ALERTS_MODAL"
            pattern="secondary"
            variation="outline"
            onClick={() => setIsModalOpen(true)}
          >
            Edit
          </Button>
        </LayoutItem>
      </Layout>
      <Gutter bottom="8px" />
      <WorkFeedAlertsPills workFeedAlert={workFeedAlert} />
      <CondensedLarge>
        <Flashy bold>Email Frequency:</Flashy>{' '}
        <span data-test="EMAIL_FREQUENCY">{workFeedAlert.frequency}</span>
      </CondensedLarge>
      {!!isModalOpen && (
        <WorkFeedAlertsModal
          candidateId={candidateId}
          onClose={() => setIsModalOpen(false)}
          workFeedAlert={workFeedAlert}
          taxonomy={taxonomy}
        />
      )}
    </div>
  );
};
