import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, GridColumn, Gutter, useApi } from '@axiom/ui';

import { UserApi } from '../../api/user-api';
import { TaxonomyApi } from '../../api/taxonomy-api';
import { CandidateUtil } from '../../utils/candidate-util';
import { CandidateProfileIssuesApi } from '../../api/candidate-profile-issues-api';
import { CandidateExperienceApi } from '../../api/candidate-experience-api';

import {
  ExperienceEditSectionKeys,
  ExperienceEditUtil,
} from './experience-edit-util';
import { ExperienceEditContext } from './ExperienceEditContext';
import { ExperienceEditHeader } from './ExperienceEditHeader';
import { ExperienceEditTips } from './ExperienceEditTips';
import {
  ExperienceEditCompanyDetailsAxiom,
  CompanyDetailsAxiomSchema,
} from './ExperienceEditCompanyDetailsAxiom';
import {
  ExperienceEditCompanyDetailsNonAxiom,
  CompanyDetailsNonAxiomSchema,
} from './ExperienceEditCompanyDetailsNonAxiom';
import {
  ExperienceEditFormWorkDetails,
  WorkDetailsFormSchema,
} from './ExperienceEditFormWorkDetails';
import {
  ExperienceEditFormSkills,
  SkillsFormSchema,
} from './ExperienceEditFormSkills';

const { COMPANY_DETAILS, WORK_DETAILS, SKILLS } = ExperienceEditSectionKeys;

export const ExperienceEdit = () => {
  const { experienceId, candidateId: urlCandidateId } = useParams();
  const [{ data: user }, { data: taxonomy }] = useApi(
    UserApi.readUser(),
    TaxonomyApi.readTaxonomy()
  );
  const candidateId = CandidateUtil.reconcileUserCandidateId(
    user,
    urlCandidateId
  );
  const [{ data: profileIssues }, { data: experiences } = { data: [] }] =
    useApi(
      CandidateProfileIssuesApi.readCandidateProfileIssues(candidateId),
      experienceId && CandidateExperienceApi.readExperiences(candidateId)
    );
  const experience = experiences.find(exp => exp.id === experienceId) ?? {};

  const companyDetailsComplete = ExperienceEditUtil.isDataValid(
    experience.isAxiom
      ? CompanyDetailsAxiomSchema
      : CompanyDetailsNonAxiomSchema,
    experience
  );
  const workDetailsComplete = ExperienceEditUtil.isDataValid(
    WorkDetailsFormSchema,
    experience
  );
  const skillsComplete = ExperienceEditUtil.isDataValid(
    SkillsFormSchema,
    experience
  );

  const [accordionState, setAccordionState] = useState({
    [COMPANY_DETAILS]: !companyDetailsComplete,
    [WORK_DETAILS]: companyDetailsComplete && !workDetailsComplete,
    [SKILLS]: companyDetailsComplete && workDetailsComplete && !skillsComplete,
  });

  const [dirtyFormState, setDirtyFormState] = useState({
    [COMPANY_DETAILS]: false,
    [WORK_DETAILS]: false,
    [SKILLS]: false,
  });

  const providerValue = useMemo(
    () => ({
      accordionState,
      setAccordionState,
      dirtyFormState,
      setDirtyFormState,
    }),
    [accordionState, dirtyFormState]
  );

  return (
    <div data-test="EXPERIENCE_EDIT">
      <ExperienceEditContext.Provider value={providerValue}>
        <ExperienceEditHeader experience={experience} />
        <Gutter bottom="24px" />
        <Grid>
          <GridColumn smallScreenWidth={12} largeScreenWidth={8}>
            {experience.isAxiom ? (
              <ExperienceEditCompanyDetailsAxiom
                candidateId={candidateId}
                experience={experience}
                issues={profileIssues.issues}
                taxonomy={taxonomy}
              />
            ) : (
              <ExperienceEditCompanyDetailsNonAxiom
                candidateId={candidateId}
                experience={experience}
                issues={profileIssues.issues}
                taxonomy={taxonomy}
              />
            )}
            <Gutter bottom="16px" />
            <ExperienceEditFormWorkDetails
              candidateId={candidateId}
              expandable={companyDetailsComplete}
              experience={experience}
            />
            <Gutter bottom="16px" />
            <ExperienceEditFormSkills
              candidateId={candidateId}
              expandable={!!companyDetailsComplete && workDetailsComplete}
              experience={experience}
              taxonomy={taxonomy}
            />
            <Gutter bottom="24px" />
          </GridColumn>
          <GridColumn smallScreenWidth={12} largeScreenWidth={4}>
            <ExperienceEditTips experience={experience} />
          </GridColumn>
        </Grid>
      </ExperienceEditContext.Provider>
    </div>
  );
};
