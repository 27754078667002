import ApiHelper from '../lib/api-helper';

import { BANNER_MESSAGE_API_NAME } from './constants';

const api = new ApiHelper(BANNER_MESSAGE_API_NAME);

export const getBannerMessage = async () => {
  const { data } = await api.GET(`/bannerMessages/portal`);
  return data;
};
