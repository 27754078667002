import React from 'react';
import { FormText as RSFormText } from 'reactstrap';
import { bool, number, node, oneOfType, string } from 'prop-types';
import size from 'lodash/size';
import styled from 'styled-components';

import { formatDataTestId } from '../../utils/data-test-util';

export const FlexLeftWrapper = styled.div`
  padding-top: 10px;
  flex: initial;
  display: inline-flex;
  flex-direction: column;
`;

export const FlexRightWrapper = styled.div`
  padding-top: 10px;
  flex: 1 auto;
  width: auto;
  text-align: end;
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
`;

const InputCharRemaining = ({
  showCharsRemainingLabel,
  maxLength,
  errorMessageNode,
  value,
  type,
}) => {
  const shouldShowCharsRemaining = () =>
    type !== 'number' && showCharsRemainingLabel === true && maxLength !== null;

  return (
    <FlexWrapper>
      {!!errorMessageNode && (
        <FlexLeftWrapper>{errorMessageNode}</FlexLeftWrapper>
      )}
      {shouldShowCharsRemaining() && (
        <FlexRightWrapper>
          <RSFormText
            data-test={formatDataTestId('characters_remaining_container')}
          >
            <strong>
              <span data-test={formatDataTestId('characters_remaining')}>
                {maxLength - size(value) || 0}
              </span>{' '}
              characters remaining
            </strong>
          </RSFormText>
        </FlexRightWrapper>
      )}
    </FlexWrapper>
  );
};

InputCharRemaining.defaultProps = {
  showCharsRemainingLabel: true,
  maxLength: 255,
  errorMessageNode: null,
  value: '',
  type: 'text',
};

InputCharRemaining.propTypes = {
  errorMessageNode: node,
  maxLength: number,
  showCharsRemainingLabel: bool,
  type: string,
  value: oneOfType([string, number]),
};

export default InputCharRemaining;
