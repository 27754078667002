import { createContext } from 'react';

import { ExperienceEditSectionKeys } from './experience-edit-util';

const { COMPANY_DETAILS, WORK_DETAILS, SKILLS } = ExperienceEditSectionKeys;

export type ExperienceEditContextType = {
  accordionState: {
    [COMPANY_DETAILS]: boolean;
    [WORK_DETAILS]: boolean;
    [SKILLS]: boolean;
  };
  setAccordionState: () => void;
  dirtyFormState: {
    [COMPANY_DETAILS]: boolean;
    [WORK_DETAILS]: boolean;
    [SKILLS]: boolean;
  };
  setDirtyFormState: () => void;
};

export const ExperienceEditContext = createContext(null);
