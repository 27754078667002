import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button, CardFooter, useApi } from '@axiom/ui';
import { CandidateWorkFeedItem, Candidate } from '@axiom/validation';

import { EnvUtil } from '../../utils/env-util';
import { CandidateOpportunitiesLegacyApi } from '../../api/candidate-opportunities-legacy-api';
import { WorkFeedApi } from '../../api/candidate-workfeed-api';
import { WorkFeedViewsType, WorkFeedUtil } from '../../utils/WorkFeedUtil';
import { WorkFeedConfirmInterestModal } from '../WorkFeedConfirmInterestModal/WorkFeedConfirmInterestModal';
import { WorkFeedDirectExpressionModal } from '../WorkFeedDirectExpressionModal/WorkFeedDirectExpressionModal';
import { WorkFeedRemoveSavedOpportunityModal } from '../WorkFeedRemoveSavedOpportunityModal/WorkFeedRemoveSavedOpportunityModal';
import { WorkFeedHideOpportunityModal } from '../WorkFeedHideOpportunityModal/WorkFeedHideOpportunityModal';

export type WorkFeedCardFooterProps = {
  workFeedItem: CandidateWorkFeedItem;
  view: WorkFeedViewsType;
  candidate: Candidate;
};

export const WorkFeedCardFooter = ({
  workFeedItem,
  view,
  candidate,
}: WorkFeedCardFooterProps) => {
  const [isHideModalOpen, setIsHideModalOpen] = useState(false);
  const [isExpressInterestModalOpen, setIsExpressInterestModalOpen] =
    useState(false);
  const [isRemoveOpportunityModalOpen, setIsRemoveOpportunityModalOpen] =
    useState(false);
  const params = useParams();
  const isSPV = WorkFeedUtil.isSinglePageView(view);
  const {
    isInterested,
    isSubmitted,
    isClosed,
    isSelected,
    isFulfillmentCompleted,
  } = WorkFeedUtil.getWorkFeedCandidateStatuses(workFeedItem);

  const [{ interestedCount }] = useApi(
    WorkFeedApi.readWorkFeedInterestedCount(candidate.id)
  );

  const onSaveOpportunity = async () => {
    if (workFeedItem.isSaved) {
      setIsRemoveOpportunityModalOpen(true);
    } else {
      await CandidateOpportunitiesLegacyApi.createWorkFeedSaved(candidate.id, {
        opportunityId: workFeedItem.opportunity.id,
      });
      await WorkFeedApi.refreshAllOfWorkFeed(
        candidate.id,
        WorkFeedUtil.isSinglePageView(view)
          ? workFeedItem.opportunity.id
          : null,
        true
      );
      WorkFeedUtil.addSavedToasts(
        `${params.pageNumber ? '../../../' : '../'}saved`
      );
    }
  };

  const onRemoveOpportunity = async () => {
    if (workFeedItem.candidateIsInterested || workFeedItem.isSaved) {
      if (workFeedItem.candidateIsInterested) {
        await CandidateOpportunitiesLegacyApi.deleteWorkFeedInterested(
          candidate.id,
          workFeedItem.opportunity.id
        );
      }

      if (workFeedItem.isSaved) {
        await CandidateOpportunitiesLegacyApi.deleteWorkFeedSaved(
          candidate.id,
          workFeedItem.opportunity.id
        );
      }

      await WorkFeedApi.refreshAllOfWorkFeed(
        candidate.id,
        WorkFeedUtil.isSinglePageView(view)
          ? workFeedItem.opportunity.id
          : null,
        true
      );
    }
  };

  // Default Buttons
  if (!isInterested && !isClosed && !isSubmitted && !isFulfillmentCompleted) {
    return (
      <CardFooter stackableOn="mobile">
        {WorkFeedUtil.isAllView(view) && (
          <Button
            name="PENDO_OPP_FEED_HIDE_BUTTON"
            variation="outline"
            onClick={() => setIsHideModalOpen(true)}
          >
            Hide
          </Button>
        )}

        <Button
          className={`${workFeedItem.isSaved ? 'favorited' : ''}`}
          name="HEART_BUTTON"
          variation="outline"
          onClick={onSaveOpportunity}
          icon={workFeedItem.isSaved ? 'heart-filled' : 'heart'}
        >
          {workFeedItem.isSaved ? 'Saved' : 'Save'}
        </Button>

        {interestedCount < EnvUtil.workFeedMaxInterestedLimit &&
          !workFeedItem.opportunity.isExcludedFromFeed &&
          !!(workFeedItem.position.description ?? '').trim() &&
          !!workFeedItem.opportunity.allowInterest &&
          !WorkFeedUtil.isUnavailableToCandidate(workFeedItem) && (
            <Button
              name="CTA_EXPRESS_INTEREST"
              onClick={() => setIsExpressInterestModalOpen(true)}
            >
              I'm Interested
            </Button>
          )}

        {!!isHideModalOpen && (
          <WorkFeedHideOpportunityModal
            onClose={() => setIsHideModalOpen(false)}
            candidateId={candidate.id}
            opportunityId={workFeedItem.opportunity.id}
          />
        )}

        {!!isExpressInterestModalOpen &&
          (workFeedItem.opportunity.directEligible ? (
            <WorkFeedDirectExpressionModal
              onClose={() => setIsExpressInterestModalOpen(false)}
              candidate={candidate}
              workFeedItem={workFeedItem}
              view={view}
            />
          ) : (
            <WorkFeedConfirmInterestModal
              onClose={() => setIsExpressInterestModalOpen(false)}
              candidate={candidate}
              workFeedItem={workFeedItem}
              view={view}
            />
          ))}

        {!!isRemoveOpportunityModalOpen && (
          <WorkFeedRemoveSavedOpportunityModal
            onClose={() => setIsRemoveOpportunityModalOpen(false)}
            candidateId={candidate.id}
            opportunityId={workFeedItem.opportunity.id}
            view={view}
          />
        )}
      </CardFooter>
    );
  }

  // View Submissions Button
  else if ((isSubmitted && !isClosed) || isSelected) {
    return (
      <CardFooter>
        <Link to="../../engagements">
          <Button name="CTA_VIEW_SUBMISSIONS">View Submissions</Button>
        </Link>
      </CardFooter>
    );
  }

  // Remove Interest Button
  else if (!isSPV && !isSelected && (isClosed || isFulfillmentCompleted)) {
    return (
      <CardFooter>
        <Button name="CTA_REMOVE_INTEREST" onClick={onRemoveOpportunity}>
          Remove
        </Button>
      </CardFooter>
    );
  }

  return null;
};
