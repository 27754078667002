import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Candidate } from '@axiom/validation';
import {
  Button,
  Card,
  CardHeader,
  CardSection,
  Gutter,
  Layout,
  LayoutItem,
  SectionHeader,
  SmallHeader,
} from '@axiom/ui';

import { CertificationsUtil } from '../../utils/certifications-util';

import { AccomplishmentsItemDisplay } from './AccomplishmentsItemDisplay';
import { AccomplishmentsItemEdit } from './AccomplishmentsItemEdit';
import { AccomplishmentsBannerPrompt } from './AccomplishmentsBannerPrompt';

export const Accomplishments = ({ candidate }: { candidate: Candidate }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [certificationEdit, setCertificationEdit] = useState(null);
  const groupedCerts = CertificationsUtil.groupCertifications(
    candidate.certifications
  );

  useEffect(() => {
    if (searchParams.get('modal') === 'accomplishment') {
      setCertificationEdit({});
      setSearchParams({}, { replace: true });
    }
  }, [searchParams]);

  return (
    <Card name="ACCOMPLISHMENTS">
      <CardHeader>
        <Layout position="middle" wrap>
          <LayoutItem fluid>
            <SmallHeader>Accomplishments</SmallHeader>
          </LayoutItem>
          <LayoutItem>
            <Button
              name="ACCOMPLISHMENTS_ADD_BUTTON"
              pattern="secondary"
              variation="minimal"
              icon="plus"
              onClick={() => {
                setCertificationEdit({});
              }}
            >
              Add
            </Button>
          </LayoutItem>
        </Layout>
      </CardHeader>
      {candidate.certifications.length === 0 && (
        <CardSection>
          <AccomplishmentsBannerPrompt
            onOpenEdit={() => {
              setCertificationEdit({});
            }}
          />
          <Gutter bottom="16px" />
        </CardSection>
      )}
      {groupedCerts.certifications.length > 0 && (
        <CardSection name="CERTIFICATIONS_AWARDS" divider>
          <SectionHeader>Certifications & Awards</SectionHeader>
          <Gutter bottom="16px" />
          <Layout direction="vertical" verticalGutter="16px">
            {groupedCerts.certifications.map(certification => (
              <AccomplishmentsItemDisplay
                candidateId={candidate.id}
                certification={certification}
                key={certification.id}
                onOpenForm={() => {
                  setCertificationEdit(certification);
                }}
              />
            ))}
          </Layout>
        </CardSection>
      )}
      {groupedCerts.qualifications.length > 0 && (
        <CardSection name="QUALIFICATIONS">
          <SectionHeader>Qualifications</SectionHeader>
          <Gutter bottom="16px" />
          <Layout direction="vertical" verticalGutter="16px">
            {groupedCerts.qualifications.map(qualification => (
              <AccomplishmentsItemDisplay
                candidateId={candidate.id}
                certification={qualification}
                key={qualification.id}
                onOpenForm={() => {
                  setCertificationEdit(qualification);
                }}
              />
            ))}
          </Layout>
        </CardSection>
      )}
      {!!certificationEdit && (
        <AccomplishmentsItemEdit
          candidateId={candidate.id}
          certification={certificationEdit}
          onClose={() => {
            setCertificationEdit(null);
          }}
          showBanner={candidate.certifications.length === 0}
        />
      )}
    </Card>
  );
};
