// eslint-disable-next-line max-classes-per-file
import {
  JOB_API_NAME,
  CANDIDATE_API_NAME,
  TYPEAHEAD_API_NAME,
  CANDIDATE_ACTIVITIES_API_NAME,
  CANDIDATE_EXPERIENCES_API_NAME,
  JOB_CANDIDATES_API_NAME,
  CANDIDATE_LANGUAGES_API_NAME,
  CANDIDATE_OPPORTUNITIES_API_NAME,
  USERS_API_NAME,
  CONTACTS_API_NAME,
  COMPANIES_API_NAME,
} from '../api/constants';

const userApiGroup = {
  [JOB_API_NAME]: {
    GET: 'getting opportunities',
    POST: 'updating opportunities',
    PUT: 'updating opportunities',
    PATCH: 'updating opportunities',
    DELETE: 'deleting opportunities',
  },
  [CANDIDATE_API_NAME]: {
    GET: 'getting candidates',
    POST: 'updating candidates',
    PUT: 'updating candidates',
    PATCH: 'updating candidates',
    DELETE: 'deleting candidates',
  },
  [CANDIDATE_ACTIVITIES_API_NAME]: {
    GET: 'getting candidates activities',
    POST: 'updating candidates activities',
    PUT: 'updating candidates activities',
    PATCH: 'updating candidates activities',
    DELETE: 'deleting candidates activities',
  },
  [CANDIDATE_EXPERIENCES_API_NAME]: {
    GET: 'getting candidates experience',
    POST: 'updating candidates experience',
    PUT: 'updating candidates experience',
    PATCH: 'updating candidates experience',
    DELETE: 'deleting candidates experience',
  },
  [CANDIDATE_LANGUAGES_API_NAME]: {
    GET: 'getting candidates languages',
    POST: 'updating candidates languages',
    PUT: 'updating candidates languages',
    PATCH: 'updating candidates languages',
    DELETE: 'deleting candidates languages',
  },
  [CANDIDATE_OPPORTUNITIES_API_NAME]: {
    GET: 'getting candidates opportunities',
    POST: 'updating candidates opportunities',
    PUT: 'updating candidates opportunities',
    PATCH: 'updating candidates opportunities',
    DELETE: 'deleting candidates opportunities',
  },
  [JOB_CANDIDATES_API_NAME]: {
    GET: 'getting opportunity candidates',
    POST: 'updating opportunity candidates',
    PUT: 'updating opportunity candidates',
    PATCH: 'updating opportunity candidates',
    DELETE: 'deleting opportunity candidates',
  },
  [TYPEAHEAD_API_NAME]: {
    GET: 'searching',
    POST: 'searching',
    PUT: 'searching',
    PATCH: 'searching',
    DELETE: 'searching',
  },
  [CONTACTS_API_NAME]: {
    GET: 'getting contacts',
    POST: 'updating contacts',
    PUT: 'updating contacts',
    PATCH: 'updating contacts',
    DELETE: 'deleting contacts',
  },
  [COMPANIES_API_NAME]: {
    GET: 'getting companies',
    POST: 'updating companies',
    PUT: 'updating companies',
    PATCH: 'updating companies',
    DELETE: 'deleting companies',
  },
};

const devApiGroup = {
  [USERS_API_NAME]: {
    GET: '10',
    POST: '11',
    PUT: '12',
    PATCH: '13',
    DELETE: '14',
  },
  [JOB_API_NAME]: {
    GET: '20',
    POST: '21',
    PUT: '22',
    PATCH: '23',
    DELETE: '24',
  },
  [CANDIDATE_API_NAME]: {
    GET: '30',
    POST: '31',
    PUT: '32',
    PATCH: '33',
    DELETE: '34',
  },
  [TYPEAHEAD_API_NAME]: {
    GET: '40',
    POST: '41',
    PUT: '42',
    PATCH: '43',
    DELETE: '44',
  },
  [CANDIDATE_ACTIVITIES_API_NAME]: {
    GET: '50',
    POST: '51',
    PUT: '52',
    PATCH: '53',
    DELETE: '54',
  },
  [CANDIDATE_EXPERIENCES_API_NAME]: {
    GET: '60',
    POST: '61',
    PUT: '62',
    PATCH: '63',
    DELETE: '64',
  },
  [CANDIDATE_LANGUAGES_API_NAME]: {
    GET: '70',
    POST: '71',
    PUT: '72',
    PATCH: '73',
    DELETE: '74',
  },
  [CANDIDATE_OPPORTUNITIES_API_NAME]: {
    GET: '80',
    POST: '81',
    PUT: '82',
    PATCH: '83',
    DELETE: '84',
  },
  [JOB_CANDIDATES_API_NAME]: {
    GET: '90',
    POST: '91',
    PUT: '92',
    PATCH: '93',
    DELETE: '94',
  },
  [CONTACTS_API_NAME]: {
    GET: '100',
    POST: '101',
    PUT: '102',
    PATCH: '103',
    DELETE: '104',
  },
  [COMPANIES_API_NAME]: {
    GET: '110',
    POST: '111',
    PUT: '112',
    PATCH: '113',
    DELETE: '114',
  },
};

// @Kevin TODO: Write tests for these once fully baked
export const createDeveloperErrorString = ({
  HTTPErrorCode,
  HTTPMethod,
  apiName,
  response,
  extras = {},
}) => [
  `[ ${apiName} ${HTTPMethod} ] API ${HTTPErrorCode} ENCOUNTERED. Response was ${JSON.stringify(
    response
  )}`,
  extras,
];

export const convertAPINameToUserFriendlyText = (apiName, HTTPMethod) => {
  const apiGroup = userApiGroup[apiName];
  const groupWithMethod = apiGroup ? apiGroup[HTTPMethod] : HTTPMethod;
  return groupWithMethod;
};

export const convertHTTPErrorCodeToUserErrorCode = (apiName, HTTPMethod) => {
  const apiGroup = devApiGroup[apiName];
  const groupWithMethod = apiGroup ? apiGroup[HTTPMethod] : HTTPMethod;
  return groupWithMethod;
};

export const createAPIErrorObject = ({
  HTTPErrorCode,
  HTTPMethod,
  apiName,
  response,
  extras,
  errors,
}) => ({
  id: `${Date.now()}.${apiName}.${JSON.stringify(response)}.${JSON.stringify(
    extras
  )}`,
  userErrorText: `There was a problem ${convertAPINameToUserFriendlyText(
    apiName,
    HTTPMethod
  )}. Error Code: ${convertHTTPErrorCodeToUserErrorCode(
    apiName,
    HTTPMethod
  )}${HTTPErrorCode}`,
  developerErrorText: createDeveloperErrorString({
    HTTPErrorCode,
    HTTPMethod,
    apiName,
    response,
    extras,
  }),
  apiName,
  response,
  errors,
});

export class ClientError extends Error {
  constructor(errorObject) {
    super('Client Error');
    this.applicationError = errorObject;
  }

  getReduxFormErrors = () => ({
    ...this.applicationError,
  });
}

export const HIDDEN_FIELD_ERROR_CODES = [
  'extra_field',
  'requires_at_least_one',
];

export const fieldErrorFormatters = {
  enum_mismatch: ({ expectedValues = [] }) =>
    `Must be one of (${expectedValues.join(', ')}).`,
  extra_field: ({ field }) =>
    `Field "${field}" is unknown or cannot be edited.`,
  missing_required: () => `This field is required.`,
  requires_at_least_one: () => 'At least one field must be filled in.',
  too_long: ({ maxLength }) => `Can't be longer than ${maxLength} characters.`,
  too_short: ({ minLength }) =>
    `Can't be shorter than ${minLength} characters.`,
  wrong_format: ({ expectedFormat = '' }) =>
    `Must be a${/^[aeio]/i.test(expectedFormat) ? 'n' : ''} ${expectedFormat}.`,
  wrong_type: ({ expectedType = '' }) =>
    `Must be a${/^[aeio]/i.test(expectedType) ? 'n' : ''} ${expectedType}.`,
};

export const formatFieldError = e => {
  if (typeof e === 'string') return e;
  const formatter = fieldErrorFormatters[e.code];
  if (formatter) return formatter(e);
  return e.message || JSON.stringify(e);
};

export class BadRequestError extends Error {
  constructor({ name, endpoint, method, body, headers, response }) {
    super('Bad Request Error');
    const errorObj = createAPIErrorObject({
      HTTPErrorCode: response.status,
      HTTPMethod: method,
      apiName: name,
      body,
      errors: body.errors,
      extras: { endpoint, headers, body },
      response,
    });
    this.applicationError = errorObj;
  }

  getReduxFormErrors = () => {
    const { errors } = this.applicationError;
    const formErrors = {};
    try {
      let apiErrors = 0;
      errors.forEach(e => {
        let { field } = e;
        if (!field || HIDDEN_FIELD_ERROR_CODES.includes(e.code)) {
          apiErrors += 1;
          field = `__error${apiErrors}`;
        }
        formErrors[field] = formatFieldError(e);
      });
    } catch (e) {
      console.error('Error parsing API error', e); // eslint-disable-line no-console
    }
    return formErrors;
  };
}

export class APIError extends Error {
  constructor({ name, endpoint, method, body, headers, response }) {
    super('API Error');
    const errorObj = createAPIErrorObject({
      HTTPErrorCode: response.status,
      HTTPMethod: method,
      apiName: name,
      response,
      errors: body.errors,
      body,
      extras: { endpoint, headers, body },
    });
    this.applicationError = errorObj;
  }
}

export class AuthorizationError extends Error {
  constructor({ name, endpoint, method, body, headers, response }) {
    super('Authorization Error');
    const errorObj = createAPIErrorObject({
      HTTPErrorCode: response.status,
      HTTPMethod: method,
      apiName: name,
      response,
      errors: body.errors,
      body,
      extras: { endpoint, headers, body },
    });
    this.applicationError = errorObj;
  }
}

export class AuthenticationError extends Error {
  constructor({ name, endpoint, method, body, headers, response }) {
    super('Authentication Error');
    const errorObj = createAPIErrorObject({
      HTTPErrorCode: response.status,
      HTTPMethod: method,
      apiName: name,
      response,
      errors: body.errors,
      body,
      extras: { endpoint, headers, body },
    });
    this.applicationError = errorObj;
  }
}
