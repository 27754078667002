import { useApi } from '@axiom/ui';
import { PracticeAreaConst } from '@axiom/const';

import { PracticeAreaApi } from '../api/practice-areas-api';
import { getTreeTrunk } from '../utils/dropdown-tree-util';
import { Option } from '../types/work-feed-types';

const customSort: Record<string, number> = {
  'Commercial & Contract Law': 0, // label of item : index of display order
  Finance: 1,
  'Regulatory & Compliance': 2,
  'Data Privacy & Cybersecurity': 3,
  'Intellectual Property': 4,
};

export const usePracticeAreaOptions = () => {
  const [{ data: practiceAreas }] = useApi(PracticeAreaApi.readPracticeAreas());

  const filtered = practiceAreas.filter(
    pa => pa.type && pa.name !== PracticeAreaConst.PracticeAreas.Unknown
  );
  const options = getTreeTrunk(filtered, true) as Option[];

  const { priorityOpts, restOpts } = options.reduce(
    (acc, opt) => {
      const priorityIndex = customSort[opt.label];

      if (priorityIndex === undefined) {
        acc.restOpts.push(opt);
      } else {
        acc.priorityOpts[priorityIndex] = opt;
      }

      return acc;
    },
    {
      priorityOpts: [],
      restOpts: [],
    }
  );

  return [...priorityOpts, ...restOpts];
};
