import React from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Card,
  CardHeader,
  CardSection,
  Layout,
  SmallHeader,
  useApi,
} from '@axiom/ui';

import { PreloadedPermissionsStore } from '../../stores/preloaded-permissions-store';
import { CandidateUtil } from '../../utils/candidate-util';
import { UserApi } from '../../api/user-api';
import { CandidateLegacyApi } from '../../api/candidate-legacy-api';
import { PreferencesUtil } from '../../utils/preferences-util';

import { PreferencesNavigationItem } from './PreferencesNavigationItem';

const {
  canViewWorkStylePreferences,
  canViewProfileVisibilityPreferences,
  canViewProgramsPreferences,
} = PreloadedPermissionsStore;

export const PreferencesNavigationComponent = ({
  permissions,
}: {
  permissions: {
    viewWorkStylePreferences: boolean;
    viewProfileVisibilityPreferences: boolean;
    viewProgramsPreferences: boolean;
  };
}) => {
  const [{ data: user }] = useApi(UserApi.readUser());
  const { candidateId: urlCandidateId } = useParams();
  const candidateId = CandidateUtil.reconcileUserCandidateId(
    user,
    urlCandidateId
  );
  const [{ data: candidate }] = useApi(
    CandidateLegacyApi.readCandidate(candidateId)
  );
  const {
    workStylePreferencesReviewedAt,
    profileVisibilityReviewedAt,
    programsPreferencesReviewedAt,
  } = candidate;

  return (
    <Card>
      <CardHeader>
        <SmallHeader>Preferences</SmallHeader>
      </CardHeader>
      <CardSection>
        <Layout direction="vertical" verticalGutter="16px">
          <PreferencesNavigationItem
            name="SIDE_NAV_COMMUNICATIONS"
            url="../communications"
          >
            Communications & Alerts
          </PreferencesNavigationItem>

          <PreferencesNavigationItem name="SIDE_NAV_PROFILE" url="../profile">
            Profile
          </PreferencesNavigationItem>

          {canViewWorkStylePreferences(permissions) && (
            <PreferencesNavigationItem
              name="SIDE_NAV_WORK_STYLE"
              url="../work-style"
              showBadge={PreferencesUtil.shouldPreferenceNeedReview(
                workStylePreferencesReviewedAt
              )}
            >
              Work Style
            </PreferencesNavigationItem>
          )}

          {canViewProfileVisibilityPreferences(permissions) && (
            <PreferencesNavigationItem
              name="SIDE_NAV_PROFILE_VISIBILITY"
              url="../profile-visibility"
              showBadge={PreferencesUtil.shouldPreferenceNeedReview(
                profileVisibilityReviewedAt
              )}
            >
              Profile Visibility
            </PreferencesNavigationItem>
          )}

          {canViewProgramsPreferences(permissions) && (
            <PreferencesNavigationItem
              name="SIDE_NAV_PROGRAMS"
              url="../programs"
              showBadge={PreferencesUtil.shouldPreferenceNeedReview(
                programsPreferencesReviewedAt
              )}
            >
              Programs
            </PreferencesNavigationItem>
          )}
        </Layout>
      </CardSection>
    </Card>
  );
};
export const PreferencesNavigation = connect(state => ({
  permissions: PreloadedPermissionsStore.select(state),
}))(PreferencesNavigationComponent);
