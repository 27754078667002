import React, { useMemo } from 'react';
import {
  AccordionsItem,
  AccordionsItemHeader,
  AccordionsItemSection,
  CondensedHeader,
  Gutter,
  Badge,
  FormGroup,
  Brief,
  Checkbox,
} from '@axiom/ui';

import { Option } from '../../types/work-feed-types';

export const WorkFeedFilterAccordion = ({
  count,
  expandAccordion,
  expandOptions,
  maxChildrenShown,
  minimal,
  name,
  onChange = () => {},
  options,
  title,
}: {
  count: number;
  expandAccordion: boolean;
  expandOptions?: boolean;
  maxChildrenShown?: number;
  minimal?: boolean;
  name: string;
  onChange?: (name: string, value: string[]) => void;
  options: Option[];
  title: string;
}) => {
  const initExpAcc = useMemo(() => expandAccordion, []);
  const initExpOpt = useMemo(() => expandOptions, []);

  return (
    <AccordionsItem
      expanded={initExpAcc}
      key={title}
      name={`${name}_accordion`}
    >
      <AccordionsItemHeader name={`${name}_header`}>
        <CondensedHeader>
          {title}
          {count > 0 && (
            <Gutter left="8px" as="span">
              <Badge background="light blue" name={`${name}_count`}>
                {count}
              </Badge>
            </Gutter>
          )}
        </CondensedHeader>
      </AccordionsItemHeader>
      <AccordionsItemSection>
        {!minimal && <Gutter top="24px" name="NON_MINIMAL_GUTTER" />}
        <FormGroup name={name}>
          <Brief
            briefMaxChildren={maxChildrenShown}
            name={`${name}_brief`}
            pattern="secondary"
            position="left"
            expanded={initExpOpt}
          >
            {options.map(option => (
              <Gutter bottom="8px" key={option.value}>
                <Checkbox
                  name={name}
                  key={option.value}
                  displayValue={option.label}
                  option={option.value}
                  onChange={v => {
                    onChange(name, v as string[]);
                  }}
                />
              </Gutter>
            ))}
          </Brief>
        </FormGroup>
      </AccordionsItemSection>
    </AccordionsItem>
  );
};
