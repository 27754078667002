import React from 'react';
import {
  Gutter,
  HeaderBar,
  Layout,
  LayoutBleed,
  LayoutItem,
  Sticky,
  StickyLayout,
  Suspense,
} from '@axiom/ui';
import styled from 'styled-components';

import Footer from '../../components/Footer/Footer';
import { AuthenticatedHeaderMenu } from '../AuthenticatedHeaderMenu/AuthenticatedHeaderMenu';

const PageBlock = styled.div`
  min-height: 100vh;

  & > * {
    min-height: 100vh;
  }
`;

export const PageLayout = ({
  children,
  name,
  showTalentLinks = false,
}: {
  children: React.ReactNode;
  name?: string;
  showTalentLinks?: boolean;
}) => {
  return (
    <StickyLayout>
      <PageBlock>
        <Layout direction="vertical" name={name}>
          <LayoutItem>
            <Sticky>
              <HeaderBar name="TOPNAV">
                {!!showTalentLinks && <AuthenticatedHeaderMenu />}
              </HeaderBar>
              <Gutter bottom="24px" />
            </Sticky>
          </LayoutItem>
          <LayoutItem fluid>
            <Suspense>
              <LayoutBleed>{children}</LayoutBleed>
            </Suspense>
          </LayoutItem>
          <LayoutItem>
            <Footer />
          </LayoutItem>
        </Layout>
      </PageBlock>
    </StickyLayout>
  );
};
