import _ from 'lodash';
import { arrayOf, shape } from 'prop-types';

import SubmitStateHelper from '../../lib/submit-state-helper';
import { getExperiences } from '../../api/candidate-experience-api';
import CollectionStateHelper from '../../lib/collection-state-helper';
import { ExperienceDataShape } from '../../models/experience-model';

export const CANDIDATE_EXPERIENCE_NAMESPACE = 'CANDIDATE_EXPERIENCE';

const stateGetExperiences = new CollectionStateHelper(
  CANDIDATE_EXPERIENCE_NAMESPACE,
  'FETCH'
);

const stateDeleteExperience = new SubmitStateHelper(
  CANDIDATE_EXPERIENCE_NAMESPACE,
  'DELETE_EXP'
);

export const CandidateExperiencesStore = {
  getReducers() {
    return {
      ...stateGetExperiences.generateReducers(),
      ...stateDeleteExperience.generateReducers(),
    };
  },
  getStateShape() {
    return shape({
      ...stateGetExperiences.getStructure(this.getDataShape()),
      submits: shape({
        ...stateDeleteExperience.getStructure(),
      }),
    });
  },
  getDataShape() {
    return arrayOf(ExperienceDataShape);
  },
  actionFetchExperiences(candidateId) {
    return stateGetExperiences.fetchCollection(getExperiences(candidateId));
  },
  selectExperienceState(state) {
    return stateGetExperiences.select(state);
  },
  selectExperiences(state) {
    const experiences = stateGetExperiences.selectData(state) || [];
    return _.orderBy(
      experiences,
      ['endDate', 'startDate', 'isAxiom'],
      ['desc', 'desc', 'desc']
    );
  },
};
