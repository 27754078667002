import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardFooter,
  CardSection,
  CondensedLarge,
  EmptyLayout,
  Gutter,
  Layout,
  Paragraph,
  SmallHeader,
} from '@axiom/ui';
import { Plural } from '@lingui/macro';
import {
  Candidate,
  CandidateWorkFeedItem,
  CandidateWorkFeedSearch,
} from '@axiom/validation';

import { CandidateLegacyApi } from '../../api/candidate-legacy-api';
import { WorkFeedUtil, WorkFeedViewsType } from '../../utils/WorkFeedUtil';
import { WorkFeedCard } from '../WorkFeedCard/WorkFeedCard';
import { WorkFeedEndOfFeed } from '../WorkFeedEndOfFeed/WorkFeedEndOfFeed';
import EmptyProjectsGraphic from '../../public/svg/empty-project.svg';
import TargetGraphic from '../../public/svg/target.svg';

import { WorkFeedPagination } from './WorkFeedPagination';

// The EndOfFeed card should be visible when there are no workFeedItems or
// when 1 - 9 workFeedItems or evenly divisible by 10 workFeedItems are available.
const isEndOfFeed = (
  workFeedItems: Array<CandidateWorkFeedItem>,
  meta: CandidateWorkFeedSearch['meta']
) => {
  const modulusTen = workFeedItems.length % 10;
  const { currentPage, pageCount } = meta;

  const exceededLastPage = currentPage > pageCount;

  if (
    (modulusTen === 0 && !exceededLastPage && currentPage === pageCount) ||
    pageCount === 0
  ) {
    return true;
  }
  return modulusTen !== 0;
};

export const WorkFeedCards = ({
  workFeedItems = [],
  candidate,
  meta = {},
  resultCount = 0,
  view,
}: {
  workFeedItems?: Array<CandidateWorkFeedItem>;
  candidate: Candidate;
  meta?: CandidateWorkFeedSearch['meta'];
  resultCount?: number;
  view: WorkFeedViewsType;
}) => {
  const navigate = useNavigate();
  const firstDirectEligibleIndex = workFeedItems.findIndex(
    item => item.opportunity.directEligible
  );
  return (
    <>
      <Layout position="space-between middle" direction="horizontal">
        <CondensedLarge name="TOTAL_OPPORTUNITY_COUNT">
          <Plural
            value={resultCount}
            one="# opportunity"
            other="# opportunities"
          />
        </CondensedLarge>

        {workFeedItems.length > 0 && (
          <div data-test="WORKFEED_PAGINATION_TOP">
            <WorkFeedPagination meta={meta} />
          </div>
        )}
      </Layout>

      <Gutter bottom="16px" />
      {WorkFeedUtil.isAllView(view) && !candidate.oppAlertsMessageDismissed && (
        <>
          <Card>
            <CardSection
              name="WORK_FEED_CARD_MESSAGE_NEW_INSTANT_ALERTS"
              divider
            >
              <EmptyLayout
                graphic={
                  <Gutter left="auto" right="auto" gutterType="margin">
                    <TargetGraphic width="119px" height="120px" />
                  </Gutter>
                }
              >
                <SmallHeader>New Instant Alerts</SmallHeader>
                <Gutter bottom="24px" />
                <Paragraph>
                  We’ve set up an instant Opportunity Alert for you based off of
                  your profile. Opportunity Alerts keep you from missing out on
                  opportunities that check all your boxes. Easily update it any
                  time in your Preferences under Communications & Alerts.
                </Paragraph>
              </EmptyLayout>
            </CardSection>
            <CardFooter>
              <Button
                name="WORK_FEED_CARD_NEW_INSTANT_ALERTS_VIEW_ALERTS_BUTTON"
                onClick={async () => {
                  await CandidateLegacyApi.updateCandidate(candidate.id, {
                    oppAlertsMessageDismissed: true,
                  });
                  CandidateLegacyApi.refreshCandidate(candidate.id);
                  navigate('../../preferences/communications');
                }}
              >
                View Alerts
              </Button>
            </CardFooter>
          </Card>
          <Gutter bottom="16px" />
        </>
      )}
      {workFeedItems.length === 0 && !WorkFeedUtil.isAllView(view) && (
        <Card>
          <CardSection name="WORK_FEED_CARD_MESSAGE_NO_OPPS">
            <EmptyLayout
              graphic={<EmptyProjectsGraphic width="200px" height="200px" />}
            >
              {WorkFeedUtil.isSavedView(view) && (
                <>
                  <SmallHeader>No Saved Opportunities</SmallHeader>
                  <Gutter bottom="24px" />
                  <Paragraph>
                    It looks like you haven't saved any opportunities yet. To
                    save an opportunity, click the Save icon by an opportunity
                    in the Opportunity Feed.
                  </Paragraph>
                </>
              )}
              {WorkFeedUtil.isInterestedView(view) && (
                <>
                  <SmallHeader>It's pretty quiet around here</SmallHeader>
                  <Gutter bottom="24px" />
                  <Paragraph>
                    It looks like you haven't expressed interest in any
                    opportunities yet. To express interest in an opportunity,
                    click the I'm Interested button by an opportunity in the
                    Opportunity Feed.
                  </Paragraph>
                </>
              )}
            </EmptyLayout>
          </CardSection>
        </Card>
      )}
      <Layout
        direction="vertical"
        verticalGutter="16px"
        name="PENDO_WORK_FEED_CARDS"
      >
        {workFeedItems.map((workFeedItem, index) => {
          return (
            <div
              key={workFeedItem.id}
              data-test={
                firstDirectEligibleIndex === index
                  ? 'PENDO_WORK_FEED_CARD_DIRECT_ELIGIBLE_FIRST'
                  : 'PENDO_WORK_FEED_CARD'
              }
            >
              <WorkFeedCard
                index={index}
                workFeedItem={workFeedItem}
                candidate={candidate}
                view={view}
              />
            </div>
          );
        })}{' '}
        {isEndOfFeed(workFeedItems, meta) && WorkFeedUtil.isAllView(view) && (
          <WorkFeedEndOfFeed candidate={candidate} />
        )}
      </Layout>

      {workFeedItems.length > 0 && (
        <Layout position="right">
          <Gutter top="16px" name="WORKFEED_PAGINATION_BOTTOM">
            <WorkFeedPagination meta={meta} />
          </Gutter>
        </Layout>
      )}
    </>
  );
};
