// eslint-disable-next-line project-structure/folder-structure
import { Opportunity } from '@axiom/const';

import selectifyUtil from '../utils/selectify-util';

// Note: these names are not camelcase because they are included in error messages
export const ACTIVITIES_API_NAME = 'Activities API';
export const BANNER_MESSAGE_API_NAME = 'Banner Messages API';
export const BARRED_LOCATION_API_NAME = 'Barred Locations API';
export const CANDIDATE_TAGS_API_NAME = 'Candidate Tags API';
export const COMPANIES_API_NAME = 'Companies API';
export const CONTACTS_API_NAME = 'Contacts API';
export const EXPERIENCE_API_NAME = 'Experience API';
export const EXPERIENCE_TAGS_API_NAME = 'Experience Tags API';
export const GROUPS_API_NAME = 'Groups API';
export const HOME_OFFICES_API_NAME = 'Home Offices API';
export const JOB_API_NAME = 'Jobs API';
export const JOB_CANDIDATES_API_NAME = 'Job Candidates API';
export const JOBS_ACTIVITIES_API_NAME = 'Job Activities API';
export const LANGUAGE_API_NAME = 'Languages API';
export const OPPORTUNITY_LANGUAGES_API_NAME = 'Opportunity Languages API';
export const OPPORTUNITY_TAGS_API_NAME = 'Candidate Tags API';
export const PRACTICE_AREAS_API_NAME = 'Practice Areas API';
export const SEARCHES_API_NAME = 'Saved Search API';
export const TAG_API_NAME = 'Tags API';
export const SKILL_API_NAME = 'Skills API';
export const NOTIFICATION_API_NAME = 'Notifications API';
export const CANDIDATE_ACTIVITIES_API_NAME = 'Candidate Activities API';
export const CANDIDATE_API_NAME = 'Candidate API';
export const CANDIDATE_AVAILABLE_WORK_FEED_ITEMS_API_NAME =
  'Candidate Available Work Feed Items API';
export const CANDIDATE_EXPERIENCES_API_NAME = 'Candidate Experiences API';
export const CANDIDATE_LANGUAGES_API_NAME = 'Candidate Languages API';
export const CANDIDATE_OPPORTUNITIES_API_NAME = 'Candidate Opportunities API';
export const TYPEAHEAD_API_NAME = 'Typeaheads API';
export const USERS_API_NAME = 'Users API';
export const USER_API_NAME = 'User API';
export const CONFIRMATIONS_API_NAME = 'Confirmations API';

export const TAG_TYPE = {
  TAG: 'tag',
  SKILL: 'skill',
  INDUSTRY: 'industry',
};

export const JOBS_FORM = 'jobs';
export const CREATE_CONTACT_FORM = 'createContactForm';
export const TALENT_FORM = 'candidates';
export const BENCH_FORM = 'benchTalent';

export const OWNER = 'owner';
export const COLLABORATOR = 'collaborator';
export const TEAM = 'team';

export const countries = {
  Afghanistan: 'Afghanistan',
  Albania: 'Albania',
  Algeria: 'Algeria',
  Andorra: 'Andorra',
  Angola: 'Angola',
  Anguilla: 'Anguilla',
  'Antigua & Barbuda': 'Antigua & Barbuda',
  Argentina: 'Argentina',
  Armenia: 'Armenia',
  Australia: 'Australia',
  Austria: 'Austria',
  Azerbaijan: 'Azerbaijan',
  Bahamas: 'Bahamas',
  Bahrain: 'Bahrain',
  Bangladesh: 'Bangladesh',
  Barbados: 'Barbados',
  Belarus: 'Belarus',
  Belgium: 'Belgium',
  Belize: 'Belize',
  Benin: 'Benin',
  Bermuda: 'Bermuda',
  Bhutan: 'Bhutan',
  Bolivia: 'Bolivia',
  'Bosnia & Herzegovina': 'Bosnia & Herzegovina',
  Botswana: 'Botswana',
  Brazil: 'Brazil',
  'Brunei Darussalam': 'Brunei Darussalam',
  Bulgaria: 'Bulgaria',
  'Burkina Faso': 'Burkina Faso',
  'Myanmar/Burma': 'Myanmar/Burma',
  Burundi: 'Burundi',
  Cambodia: 'Cambodia',
  Cameroon: 'Cameroon',
  'Cape Verde': 'Cape Verde',
  'Cayman Islands': 'Cayman Islands',
  'Central African Republic': 'Central African Republic',
  Chad: 'Chad',
  Chile: 'Chile',
  China: 'China',
  Colombia: 'Colombia',
  Comoros: 'Comoros',
  Congo: 'Congo',
  'Costa Rica': 'Costa Rica',
  Croatia: 'Croatia',
  Cuba: 'Cuba',
  Cyprus: 'Cyprus',
  'Czech Republic': 'Czech Republic',
  'Democratic Republic of the Congo': 'Democratic Republic of the Congo',
  Denmark: 'Denmark',
  Djibouti: 'Djibouti',
  Dominica: 'Dominica',
  'Dominican Republic': 'Dominican Republic',
  Ecuador: 'Ecuador',
  Egypt: 'Egypt',
  'El Salvador': 'El Salvador',
  'Equatorial Guinea': 'Equatorial Guinea',
  Eritrea: 'Eritrea',
  Estonia: 'Estonia',
  Ethiopia: 'Ethiopia',
  Fiji: 'Fiji',
  Finland: 'Finland',
  France: 'France',
  'French Guiana': 'French Guiana',
  Gabon: 'Gabon',
  Gambia: 'Gambia',
  Georgia: 'Georgia',
  Germany: 'Germany',
  Ghana: 'Ghana',
  'Great Britain': 'Great Britain',
  Greece: 'Greece',
  Grenada: 'Grenada',
  Guadeloupe: 'Guadeloupe',
  Guatemala: 'Guatemala',
  Guinea: 'Guinea',
  'Guinea-Bissau': 'Guinea-Bissau',
  Guyana: 'Guyana',
  Haiti: 'Haiti',
  Honduras: 'Honduras',
  Hungary: 'Hungary',
  Iceland: 'Iceland',
  India: 'India',
  Indonesia: 'Indonesia',
  Iran: 'Iran',
  Iraq: 'Iraq',
  'Israel and the Occupied Territories': 'Israel and the Occupied Territories',
  Italy: 'Italy',
  "Ivory Coast (Cote d'Ivoire)": "Ivory Coast (Cote d'Ivoire)",
  Jamaica: 'Jamaica',
  Japan: 'Japan',
  Jordan: 'Jordan',
  Kazakhstan: 'Kazakhstan',
  Kenya: 'Kenya',
  Kosovo: 'Kosovo',
  Kuwait: 'Kuwait',
  'Kyrgyz Republic (Kyrgyzstan)': 'Kyrgyz Republic (Kyrgyzstan)',
  Laos: 'Laos',
  Latvia: 'Latvia',
  Lebanon: 'Lebanon',
  Lesotho: 'Lesotho',
  Liberia: 'Liberia',
  Libya: 'Libya',
  Liechtenstein: 'Liechtenstein',
  Lithuania: 'Lithuania',
  Luxembourg: 'Luxembourg',
  'Republic of Macedonia': 'Republic of Macedonia',
  Madagascar: 'Madagascar',
  Malawi: 'Malawi',
  Malaysia: 'Malaysia',
  Maldives: 'Maldives',
  Mali: 'Mali',
  Malta: 'Malta',
  Martinique: 'Martinique',
  Mauritania: 'Mauritania',
  Mauritius: 'Mauritius',
  Mayotte: 'Mayotte',
  Mexico: 'Mexico',
  'Moldova, Republic of': 'Moldova, Republic of',
  Monaco: 'Monaco',
  Mongolia: 'Mongolia',
  Montenegro: 'Montenegro',
  Montserrat: 'Montserrat',
  Morocco: 'Morocco',
  Mozambique: 'Mozambique',
  Namibia: 'Namibia',
  Nepal: 'Nepal',
  Netherlands: 'Netherlands',
  'New Zealand': 'New Zealand',
  Nicaragua: 'Nicaragua',
  Niger: 'Niger',
  Nigeria: 'Nigeria',
  'Korea, Democratic Republic of (North Korea)':
    'Korea, Democratic Republic of (North Korea)',
  Norway: 'Norway',
  Oman: 'Oman',
  'Pacific Islands': 'Pacific Islands',
  Pakistan: 'Pakistan',
  Panama: 'Panama',
  'Papua New Guinea': 'Papua New Guinea',
  Paraguay: 'Paraguay',
  Peru: 'Peru',
  Philippines: 'Philippines',
  Poland: 'Poland',
  Portugal: 'Portugal',
  'Puerto Rico': 'Puerto Rico',
  Qatar: 'Qatar',
  Reunion: 'Reunion',
  Romania: 'Romania',
  'Russian Federation': 'Russian Federation',
  Rwanda: 'Rwanda',
  'Saint Kitts and Nevis': 'Saint Kitts and Nevis',
  'Saint Lucia': 'Saint Lucia',
  "Saint Vincent's & Grenadines": "Saint Vincent's & Grenadines",
  Samoa: 'Samoa',
  'Sao Tome and Principe': 'Sao Tome and Principe',
  'Saudi Arabia': 'Saudi Arabia',
  Senegal: 'Senegal',
  Serbia: 'Serbia',
  Seychelles: 'Seychelles',
  'Sierra Leone': 'Sierra Leone',
  Singapore: 'Singapore',
  'Slovak Republic (Slovakia)': 'Slovak Republic (Slovakia)',
  Slovenia: 'Slovenia',
  'Solomon Islands': 'Solomon Islands',
  Somalia: 'Somalia',
  'South Africa': 'South Africa',
  'Korea, Republic of (South Korea)': 'Korea, Republic of (South Korea)',
  'South Sudan': 'South Sudan',
  Spain: 'Spain',
  'Sri Lanka': 'Sri Lanka',
  Sudan: 'Sudan',
  Suriname: 'Suriname',
  Swaziland: 'Swaziland',
  Sweden: 'Sweden',
  Switzerland: 'Switzerland',
  Syria: 'Syria',
  Tajikistan: 'Tajikistan',
  Tanzania: 'Tanzania',
  Thailand: 'Thailand',
  'Timor Leste': 'Timor Leste',
  Togo: 'Togo',
  'Trinidad & Tobago': 'Trinidad & Tobago',
  Tunisia: 'Tunisia',
  Turkey: 'Turkey',
  Turkmenistan: 'Turkmenistan',
  'Turks & Caicos Islands': 'Turks & Caicos Islands',
  Uganda: 'Uganda',
  Ukraine: 'Ukraine',
  'United Arab Emirates': 'United Arab Emirates',
  'United States': 'United States',
  Uruguay: 'Uruguay',
  Uzbekistan: 'Uzbekistan',
  Venezuela: 'Venezuela',
  Vietnam: 'Vietnam',
  'Virgin Islands (UK)': 'Virgin Islands (UK)',
  'Virgin Islands (US)': 'Virgin Islands (US)',
  Yemen: 'Yemen',
  Zambia: 'Zambia',
  Zimbabwe: 'Zimbabwe',
};

export const countriesItems = selectifyUtil({ options: countries });

export const states = {
  Alabama: 'Alabama',
  Alaska: 'Alaska',
  Arizona: 'Arizona',
  Arkansas: 'Arkansas',
  California: 'California',
  Colorado: 'Colorado',
  Connecticut: 'Connecticut',
  Delaware: 'Delaware',
  Florida: 'Florida',
  Georgia: 'Georgia',
  Hawaii: 'Hawaii',
  Idaho: 'Idaho',
  Illinois: 'Illinois',
  Indiana: 'Indiana',
  Iowa: 'Iowa',
  Kansas: 'Kansas',
  Kentucky: 'Kentucky',
  Louisiana: 'Louisiana',
  Maine: 'Maine',
  Maryland: 'Maryland',
  Massachusetts: 'Massachusetts',
  Michigan: 'Michigan',
  Minnesota: 'Minnesota',
  Mississippi: 'Mississippi',
  Missouri: 'Missouri',
  Montana: 'Montana',
  Nebraska: 'Nebraska',
  Nevada: 'Nevada',
  'New Hampshire': 'New Hampshire',
  'New Jersey': 'New Jersey',
  'New Mexico': 'New Mexico',
  'New York': 'New York',
  'North Carolina': 'North Carolina',
  'North Dakota': 'North Dakota',
  Ohio: 'Ohio',
  Oklahoma: 'Oklahoma',
  Oregon: 'Oregon',
  Pennsylvania: 'Pennsylvania',
  'Rhode Island': 'Rhode Island',
  'South Carolina': 'South Carolina',
  'South Dakota': 'South Dakota',
  Tennessee: 'Tennessee',
  Texas: 'Texas',
  Utah: 'Utah',
  Vermont: 'Vermont',
  Virginia: 'Virginia',
  Washington: 'Washington',
  'Washington, DC': 'Washington, DC',
  'West Virginia': 'West Virginia',
  Wisconsin: 'Wisconsin',
  Wyoming: 'Wyoming',
};

export const statesItems = selectifyUtil({ options: states });

export const territories = {
  'Canada - Ontario': 'Canada - Ontario',
  'Canada - British Columbia': 'Canada - British Columbia',
  'Canada - Quebec': 'Canada - Quebec',
  'Canada - Nunavut': 'Canada - Nunavut',
  'Canada - Northwest Territories': 'Canada - Northwest Territories',
  'Canada - Alberta': 'Canada - Alberta',
  'Canada - Saskatchewan': 'Canada - Saskatchewan',
  'Canada - Manitoba': 'Canada - Manitoba',
  'Canada - Yukon': 'Canada - Yukon',
  'Canada - Newfoundland and Labrador': 'Canada - Newfoundland and Labrador',
  'Canada - New Brunswick': 'Canada - New Brunswick',
  'Canada - Nova Scotia': 'Canada - Nova Scotia',
  'Canada - Prince Edward Island': 'Canada - Prince Edward Island',
  'Hong Kong': 'Hong Kong',
};

export const territoriesItems = selectifyUtil({ options: territories });

export const statesAndCountryItems = [
  ...statesItems,
  ...territoriesItems,
  ...countriesItems,
];

export const DEFAULT_MAX_HOURS = {
  maxHours: '60',
};

// Fields
export const TAGS = 'tags';
export const SKILLS = 'skills';
export const LANGUAGES = 'languages';

export const DATE_FORMAT = 'MM/DD/YY';

export const {
  VALIDATE,
  DEVELOP,
  CONTRACT,
  COMPETE,
  CRAFT,
  CLOSED_WON,
  CLOSED_LOST,
} = Opportunity;

export const OPPORTUNITY_WHITELIST = [
  VALIDATE,
  DEVELOP,
  CONTRACT,
  COMPETE,
  CRAFT,
];

export const EDITABLE_OPPORTUNITY_STATUS_BLACKLIST = new Set([
  CLOSED_WON,
  CLOSED_LOST,
]);

export const FILTERS = 'filters';
export const SALES_CLOSE_DATE = `${FILTERS}.salesCloseDate`;

export const MIN_COMP_RATE = 0;
export const MAX_COMP_RATE = 500000;

// Years of experience
export const MIN_YEARS_OF_EXPERIENCE = 0;
export const MAX_YEARS_OF_EXPERIENCE = 50;
