import React, { useContext } from 'react';
import {
  Candidate,
  Experience,
  ExperienceEditSchema,
  Taxonomy,
  CandidateProfileIssues,
} from '@axiom/validation';
import {
  Button,
  CondensedLarge,
  DateUtil,
  Dropdown,
  FluidButtonLayout,
  Form,
  FormLabel,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  TaxonomyUtil,
} from '@axiom/ui';
import { CandidateProfileIssuesUtils } from '@axiom/utils';
import { ProfileCompletionConst } from '@axiom/const';

import { ExperienceApi } from '../../api/experience-api';
import { CandidateExperienceApi } from '../../api/candidate-experience-api';
import { CandidateProfileIssuesApi } from '../../api/candidate-profile-issues-api';

import {
  ExperienceEditSectionKeys,
  ExperienceEditUtil,
} from './experience-edit-util';
import { ExperienceEditContext } from './ExperienceEditContext';
import { ExperienceEditIssue } from './ExperienceEditIssue';
import { ExperienceEditSection } from './ExperienceEditSection';

const { COMPANY_DETAILS, WORK_DETAILS } = ExperienceEditSectionKeys;

export const CompanyDetailsAxiomSchema = ExperienceEditSchema.pick({
  industryValue: true,
  industryIsOverridden: true,
});

export type ExperienceEditFormCompanyDetailsAxiomProps = {
  candidateId: Candidate['id'];
  experience: Experience;
  issues: CandidateProfileIssues['issues'];
  taxonomy: Taxonomy;
};

export const ExperienceEditCompanyDetailsAxiom = ({
  candidateId,
  experience,
  issues,
  taxonomy,
}: ExperienceEditFormCompanyDetailsAxiomProps) => {
  const {
    dirtyFormState,
    setDirtyFormState,
    accordionState,
    setAccordionState,
  } = useContext(ExperienceEditContext);
  const { industries } = taxonomy;
  const industryOptions = TaxonomyUtil.getIndustryOptions(industries);

  return (
    <Form
      name="EXPERIENCE_EDIT_FORM_COMPANY_DETAILS_AXIOM"
      schema={CompanyDetailsAxiomSchema}
      initialValues={{
        industryValue: experience.industryValue,
        industryIsOverridden: experience.industryIsOverridden,
      }}
      onSubmit={async formData => {
        await ExperienceApi.updateExperience(
          experience.id,
          CompanyDetailsAxiomSchema.parse({
            ...formData,
            ...(formData.industryValue && { industryIsOverridden: true }),
          })
        );
        await Promise.all([
          CandidateExperienceApi.refreshExperiences(candidateId),
          CandidateProfileIssuesApi.refreshCandidateProfileIssues(candidateId),
        ]);
        setAccordionState({
          ...ExperienceEditUtil.setAllToFalse(accordionState),
          [WORK_DETAILS]: true,
        });
      }}
    >
      {({ dirty, fireSubmit }) => {
        // https://stackoverflow.com/questions/61031464/setstate-called-in-render-prop-is-causing-a-react-warning
        setTimeout(() => {
          if (dirtyFormState[COMPANY_DETAILS] !== dirty) {
            setDirtyFormState((prevState: Record<string, boolean>) => ({
              ...prevState,
              [COMPANY_DETAILS]: dirty,
            }));
          }
        }, 0);

        return (
          <ExperienceEditSection
            expandable
            expanded={accordionState[COMPANY_DETAILS]}
            isComplete={ExperienceEditUtil.isDataValid(
              CompanyDetailsAxiomSchema,
              experience
            )}
            title="Company Details"
            name={COMPANY_DETAILS}
          >
            <div data-test="EXPERIENCE_EDIT_FORM_COMPANY_DETAILS_AXIOM_BODY">
              {CandidateProfileIssuesUtils.hasExperienceWithMissingIndustryIssue(
                issues,
                experience.id
              ) && (
                <Gutter bottom="16px">
                  <ExperienceEditIssue
                    title={
                      ProfileCompletionConst
                        .ProfileCompletionExperienceIndustryBanner.missingTitle
                    }
                    description={
                      ProfileCompletionConst
                        .ProfileCompletionExperienceIndustryBanner
                        .missingContent
                    }
                    estimatedTime="1 min"
                    name="ADD_INDUSTRY_HINT_EDIT"
                  />
                </Gutter>
              )}
              <Grid>
                <GridRow>
                  <GridColumn largeScreenWidth={6} smallScreenWidth={12}>
                    <FormLabel>Company Name</FormLabel>
                    <CondensedLarge name="client">
                      {experience.client}
                    </CondensedLarge>
                    <Gutter bottom="8px" />
                  </GridColumn>
                  <GridColumn largeScreenWidth={6} smallScreenWidth={12}>
                    <FormLabel>Duration</FormLabel>
                    <CondensedLarge name="duration">
                      {DateUtil.displayDateRange(
                        experience.startDate,
                        experience.endDate
                      )}
                    </CondensedLarge>
                    <Gutter bottom="8px" />
                  </GridColumn>
                  <GridColumn largeScreenWidth={6} smallScreenWidth={12}>
                    <FormLabel>Location</FormLabel>
                    <CondensedLarge name="location">
                      {experience.opportunity.locationCityStateCountry}
                    </CondensedLarge>
                    <Gutter bottom="8px" />
                  </GridColumn>
                </GridRow>
              </Grid>
              <Dropdown
                displayKey="label"
                name="industryValue"
                options={industryOptions}
                valueKey="value"
                label="Industry"
              />
              <Gutter bottom="16px" />
              <FluidButtonLayout>
                <Button
                  pattern="secondary"
                  onClick={fireSubmit}
                  name="EXPERIENCE_EDIT_FORM_COMPANY_DETAILS_AXIOM_SAVE"
                >
                  Save
                </Button>
              </FluidButtonLayout>
            </div>
          </ExperienceEditSection>
        );
      }}
    </Form>
  );
};
