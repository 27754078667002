import React from 'react';
import { shape, number } from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  ContextMenu,
  ContextMenuItem,
  Flashy,
  Gutter,
  HeaderBarLink,
  HeaderBarLinkSection,
  HeaderBarUserSection,
  Icon,
  ImageCircle,
  Layout,
  Suspense,
  useApi,
  Visible,
} from '@axiom/ui';
import { PossibleImageSizes } from '@axiom/const';

import { PreloadedPermissionsStore } from '../../stores/preloaded-permissions-store';
import { CurrentCandidateStore } from '../../stores/protected/current-candidate-store';
import { CandidateOpportunitiesStore } from '../../stores/protected/candidate-opportunities-store';
import { EnvUtil } from '../../utils/env-util';
import { Notifications } from '../../components/Notifications/Notifications';
import { CandidateUtil } from '../../utils/candidate-util';
import { EngagementsActionsCounter } from '../../components/EngagementsActionsCounter/EngagementsActionsCounter';
import { ProfileIssuesCounter } from '../../components/ProfileIssuesCounter/ProfileIssuesCounter';
import { PermissionsApi } from '../../api/permissions-api';

import { AuthenticatedHeaderMenuItem } from './AuthenticatedHeaderMenuItem';

const { canViewEngagements, canViewWorkFeed } = PreloadedPermissionsStore;

const AuthenticatedHeaderMenuComponent = ({
  candidate: { record: candidate },
  totalOpportunityCount,
  permissions,
}) => {
  const navigate = useNavigate();

  const { candidateId } = useParams();

  const [{ data: apiPermissions }] = useApi(
    PermissionsApi.readPermissions(candidate.id || candidateId)
  );

  const makeAbsoluteImpersonationUrl = url =>
    `/talent/${candidateId ? `${candidateId}/` : ''}${url}`;

  return (
    <>
      <HeaderBarLinkSection>
        <AuthenticatedHeaderMenuItem
          name="NAVBARPROFILE"
          url={makeAbsoluteImpersonationUrl('profile')}
        >
          <Layout direction="horizontal" horizontalGutter="8px">
            Profile
            <Suspense fallback={null}>
              <ProfileIssuesCounter candidateId={candidateId ?? candidate.id} />
            </Suspense>
          </Layout>
        </AuthenticatedHeaderMenuItem>

        {canViewEngagements(permissions) || totalOpportunityCount > 0 ? (
          <AuthenticatedHeaderMenuItem
            name="NAVBARENGAGEMENTS"
            url={makeAbsoluteImpersonationUrl('engagements')}
          >
            <Layout direction="horizontal" horizontalGutter="8px">
              Engagements
              <Suspense fallback={null}>
                <EngagementsActionsCounter
                  candidateId={candidateId ?? candidate.id}
                />
              </Suspense>
            </Layout>
          </AuthenticatedHeaderMenuItem>
        ) : null}
        {canViewWorkFeed(permissions) ? (
          <AuthenticatedHeaderMenuItem
            name="NAVBAROPPORTUNITIES"
            url={makeAbsoluteImpersonationUrl('opportunities')}
          >
            Opportunities
          </AuthenticatedHeaderMenuItem>
        ) : null}

        {apiPermissions.workday ? (
          <AuthenticatedHeaderMenuItem
            name="NAVBARTIMESHEETS"
            url={`${EnvUtil.apiPortalUrl}/saml/workday?RelayState=timesheet`}
            target="timesheet"
          >
            Timesheets
          </AuthenticatedHeaderMenuItem>
        ) : null}
        {!!(
          apiPermissions.workday ||
          apiPermissions.seismic ||
          apiPermissions.lessonly ||
          apiPermissions.myApps
        ) && (
          <ContextMenu
            direction="left"
            anchor={
              <HeaderBarLink name="nav-resources">Resources</HeaderBarLink>
            }
          >
            {!!apiPermissions.workday && (
              <ContextMenuItem
                name="SSONAVBAREXISTSBENEFITS"
                onClick={() => {
                  window
                    .open(
                      `${EnvUtil.apiPortalUrl}/saml/workday?RelayState=resourcesHub`,
                      'resources-hub'
                    )
                    .focus();
                }}
              >
                Resources Hub
              </ContextMenuItem>
            )}
            {!!apiPermissions.seismic && (
              <ContextMenuItem
                name="SSONAVBAREXISTSSEISMIC"
                onClick={() => {
                  window
                    .open(`${EnvUtil.apiPortalUrl}/saml/seismic`, 'seismic')
                    .focus();
                }}
              >
                Lessonly
              </ContextMenuItem>
            )}
            {!!apiPermissions.lessonly && (
              <ContextMenuItem
                name="SSONAVBAREXISTSLESSONLY"
                onClick={() => {
                  window
                    .open(`${EnvUtil.apiPortalUrl}/saml/lessonly`, 'lessonly')
                    .focus();
                }}
              >
                Lessonly
              </ContextMenuItem>
            )}
            {!!apiPermissions.workday && (
              <ContextMenuItem
                name="SSONAVBAREXISTSWORKDAY"
                onClick={() => {
                  window
                    .open(`${EnvUtil.apiPortalUrl}/saml/workday`, 'workday')
                    .focus();
                }}
              >
                Workday
              </ContextMenuItem>
            )}
            {!!apiPermissions.myApps && (
              <ContextMenuItem
                name="SSONAVBAREXISTSMYAPPS"
                onClick={() => {
                  window.open(`${EnvUtil.myAppsUrl}`, '_blank').focus();
                }}
              >
                My Apps
              </ContextMenuItem>
            )}
          </ContextMenu>
        )}
      </HeaderBarLinkSection>
      <HeaderBarUserSection>
        <Notifications />
        <ContextMenu
          direction="left"
          anchor={
            <>
              <ImageCircle
                size="thumbnail"
                imageName={candidate.calculatedDisplayName}
                src={CandidateUtil.getProfileImageUri(
                  candidate,
                  PossibleImageSizes.W_160
                )}
              />
              <Visible only="smallScreen">
                <Gutter left="4px">
                  <Button pattern="secondary" variation="minimal">
                    {candidate.calculatedDisplayName}
                  </Button>
                </Gutter>
              </Visible>
              <Gutter left="8px">
                <Flashy color="controlSecondary">
                  <Layout position="middle">
                    <Icon name="arrow-down" />
                  </Layout>
                </Flashy>
              </Gutter>
            </>
          }
        >
          <ContextMenuItem
            name="NAVBARDROPDOWNNOTIFICATIONPREFERENCES"
            className="gtm-notification-preferences-modal-open"
            onClick={() =>
              navigate(makeAbsoluteImpersonationUrl('preferences'))
            }
          >
            Preferences
          </ContextMenuItem>
          <ContextMenuItem
            name="NAVBARDROPDOWNREPORTISSUE"
            onClick={() => {
              window.location.href = 'mailto:answers@axiomlaw.com';
            }}
          >
            Report An Issue
          </ContextMenuItem>
          <ContextMenuItem
            name="NAVBARDROPDOWNLOGOUT"
            onClick={() => {
              window.location.href = '/logout';
            }}
          >
            Log Out
          </ContextMenuItem>
        </ContextMenu>
      </HeaderBarUserSection>
    </>
  );
};

AuthenticatedHeaderMenuComponent.defaultProps = {
  totalOpportunityCount: 0,
};

AuthenticatedHeaderMenuComponent.propTypes = {
  candidate: shape({}).isRequired,
  permissions: PreloadedPermissionsStore.getDataShape().isRequired,
  totalOpportunityCount: number,
};

const mapStateToProps = state => ({
  candidate: CurrentCandidateStore.selectCandidate(state),
  totalOpportunityCount:
    CandidateOpportunitiesStore.selectOpportunities(state).length,
  permissions: PreloadedPermissionsStore.select(state),
});

export const AuthenticatedHeaderMenu = connect(mapStateToProps)(
  AuthenticatedHeaderMenuComponent
);
