import React from 'react';
import { Gutter, Header1, Text, Header5, Button } from '@axiom/ui';

import { APLayout, APCard } from './AvailabilityPreferencesViewStyles';

export const AvailabilityPreferencesView = () => (
  <APLayout>
    <APCard>
      <Gutter bottom="0.625rem">
        <Header1>Thank You</Header1>
      </Gutter>
      <Gutter bottom="1.875rem">
        <Header5 name="confirm">
          You have confirmed that your preferences are up to date.
        </Header5>
      </Gutter>
      <Text>
        This might be a good time to review your Axiom for Talent profile.
      </Text>
      <Gutter vertical="1rem">
        <Button onClick={() => window.location.assign('/')}>Sign In</Button>
      </Gutter>
      <Text>If you’re all done here, you can close this window.</Text>
    </APCard>
  </APLayout>
);
