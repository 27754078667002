import React, { useState } from 'react';
import { z } from 'zod';
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
  Gutter,
  Dropdown,
  Form,
  Grid,
  GridRow,
  GridColumn,
  Input,
  CondensedSmall,
} from '@axiom/ui';
import {
  CountryCodesDropdownOptions,
  CountryCodeAddressCountryValues,
  CountryCodeValues,
} from '@axiom/const';
import { Candidate, User, MfaPhoneNumber } from '@axiom/validation';

import { UsersApi } from '../../api/users-api';

import { PreferencesTwoFactorVerifyModal } from './PreferencesTwoFactorVerifyModal';

export interface TwoFactorPromptModalProps {
  onClose: () => void;
  candidate: Candidate;
  user: User;
  candidateMfa: MfaPhoneNumber;
}

export const PreferencesTwoFactorPromptModal = ({
  onClose,
  candidate,
  user,
  candidateMfa,
}: TwoFactorPromptModalProps) => {
  const defaultCountry = CountryCodesDropdownOptions.find(
    item => item.addressCountryCode === candidate.addressCountryCode
  );

  const findDefaultCountryCode = () => {
    if (candidateMfa.countryCode) {
      return CountryCodesDropdownOptions.find(
        item => item.value === candidateMfa.countryCode
      );
    }
    return defaultCountry ?? null;
  };

  const initialValues = {
    COUNTRY_CODE: findDefaultCountryCode()?.addressCountryCode ?? null,
    COUNTRY_NUMBER: findDefaultCountryCode()?.value ?? null,
    PHONE_NUMBER: candidateMfa.phoneNumber ?? null,
  };

  const TwoFactorSchema = z
    .object({
      COUNTRY_CODE: z.enum(CountryCodeAddressCountryValues),
      COUNTRY_NUMBER: z.enum(CountryCodeValues),
      PHONE_NUMBER: z
        .string()
        .regex(/^[\d-.\s()\u202A-\u202E]+$/, {
          message: 'Please add a valid phone number',
        })
        .transform(value => value.replaceAll(/\D/g, '')),
    })
    .refine(
      value => {
        const concatString = `${value.COUNTRY_NUMBER}${value.PHONE_NUMBER}`;
        return concatString.length >= 6 && concatString.length <= 14
          ? value
          : null;
      },
      {
        message: 'Please add a valid phone number',
        path: ['PHONE_NUMBER'],
      }
    );

  const [temporaryCandidateMfa, setTemporaryCandidateMfa] = useState({
    countryCode: null,
    phoneNumber: null,
  });

  return temporaryCandidateMfa.countryCode &&
    temporaryCandidateMfa.phoneNumber ? (
    <PreferencesTwoFactorVerifyModal
      candidate={candidate}
      user={user}
      candidateMfa={temporaryCandidateMfa}
      onClose={onClose}
    />
  ) : (
    <Form
      schema={TwoFactorSchema}
      initialValues={initialValues}
      onSubmit={async changedData => {
        const data = { ...initialValues, ...changedData };

        return UsersApi.updateMfaPhoneNumber(user.id, {
          phoneNumber: data.PHONE_NUMBER,
          countryCode: data.COUNTRY_NUMBER,
        }).then(() => {
          setTemporaryCandidateMfa({
            countryCode: data.COUNTRY_NUMBER,
            phoneNumber: data.PHONE_NUMBER,
          });
        });
      }}
    >
      {({ fireSubmit, values, setValues }) => {
        return (
          <Modal name="TWO_FACTOR_PROMPT_MODAL">
            <ModalHeader
              onClose={() => onClose()}
              name="TWO_FACTOR_PROMPT_MODAL_HEADER"
            >
              Two-factor authentication
            </ModalHeader>
            <ModalSection>
              <Paragraph>
                Provide a mobile phone number we can use to verify it’s you.
                Please enter this number with no dashes, spaces or parentheses.
              </Paragraph>
              <Gutter bottom="16px" />
              <Grid>
                <GridRow>
                  <GridColumn
                    mobileWidth={12}
                    tabletWidth={4}
                    largeScreenWidth={4}
                  >
                    <Dropdown
                      name="COUNTRY_CODE"
                      label="Country code"
                      options={CountryCodesDropdownOptions}
                      displayKey="display"
                      valueKey="addressCountryCode"
                      onChange={v => {
                        setValues({
                          ...values,
                          COUNTRY_NUMBER: CountryCodesDropdownOptions.find(
                            item => item.addressCountryCode === v
                          ).value,
                        });
                      }}
                    />
                  </GridColumn>
                  <GridColumn
                    mobileWidth={12}
                    tabletWidth={4}
                    largeScreenWidth={8}
                  >
                    <Input name="PHONE_NUMBER" label="Mobile phone number" />
                  </GridColumn>
                </GridRow>
              </Grid>
              <Gutter bottom="16px" />
              <CondensedSmall>
                By continuing, you authorize Axiom to send security codes and
                other Axiom communications via SMS. Carrier fees may apply. You
                can update your communication preferences at any time.
              </CondensedSmall>
            </ModalSection>
            <ModalFooter>
              <Button
                name="CANCEL_BUTTON"
                variation="outline"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
              <Button name="NEXT_BUTTON" onClick={fireSubmit}>
                Next
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    </Form>
  );
};
