import React from 'react';

import { TalentDataProvider } from '../providers/TalentDataProvider';
import { PageLayout } from '../layouts/PageLayout/PageLayout';
import { PreferencesProfile } from '../components/Preferences/PreferencesProfile';

export const ProfilePreferencesPage = () => (
  <TalentDataProvider>
    <PageLayout showTalentLinks name="PROFILE-PREFERENCES-PAGE">
      <PreferencesProfile />
    </PageLayout>
  </TalentDataProvider>
);
