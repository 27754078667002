import { Api } from '@axiom/ui';
import { Tag } from '@axiom/validation';
import { TagConst } from '@axiom/const';

import ApiHelper from '../lib/api-helper';
import { EnvUtil } from '../utils/env-util';

import { SKILL_API_NAME } from './constants';

const { TAG_TYPES } = TagConst;

const api = new ApiHelper(SKILL_API_NAME);

class TagApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  readTags() {
    return super.read<{ data: Tag[] }>({
      endpoint: '/tags',
      method: 'GET',
    });
  }
}

export const TagApi = new TagApiClass();

const callTags = async (type: Tag['type']) => {
  const { data } = await api.GET(`/tags`, { type });
  return data;
};

export const getSkills = () => {
  return callTags(TAG_TYPES.Skill);
};

export const getIndustries = () => {
  return callTags(TAG_TYPES.Industry);
};

export const addSkill = async (body: Tag) => {
  body.type = TAG_TYPES.Skill;
  const { data } = await api.POST(`/tags`, [body]);
  return { data };
};

export const updateSkill = async (tagId: Tag['id'], body: Tag) => {
  body.type = TAG_TYPES.Skill;
  const { data } = await api.PATCH(`/tags/${tagId}`, body);
  return { data };
};

export const deleteSkill = async (tagId: Tag['id']) => {
  const { data } = await api.DELETE(`/tags/${tagId}`, {
    type: TAG_TYPES.Skill,
  });
  return { data };
};

export const getOpportunitiesBySkillId = async (tagId: Tag['id']) => {
  const { data } = await api.GET(`/tags/${tagId}/opportunities`, {
    type: TAG_TYPES.Skill,
  });
  return { data };
};

export const getCandidatesBySkillId = async (tagId: Tag['id']) => {
  const { data } = await api.GET(`/tags/${tagId}/candidates`, {
    type: TAG_TYPES.Skill,
  });
  return { data };
};
