import { shape, string, bool } from 'prop-types';

import GenericStore from '../../classes/generic-store';
import { getLoggedUser } from '../../api/user-api';

import { CurrentUserStore } from './current-user-store';

class UserStoreClass extends GenericStore {
  load() {
    const dispatchCurrentUser = user => {
      /** Temp until we convert all portal stores to same structure and extend GenericStore * */
      this.dispatchAction(CurrentUserStore.actionSetUser(user));
    };
    return this.setState(
      getLoggedUser().then(user => {
        dispatchCurrentUser(user);
        return user;
      })
    );
  }

  selectData(state) {
    return this.select(state)?.data || null;
  }

  getDataShape() {
    return shape({
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      organizationId: string,
      candidateId: string,
      role: string,
      fullName: string,
      isSSO: bool,
    });
  }
}

export const UserStore = new UserStoreClass();
