import React, { useContext } from 'react';
import {
  Accordions,
  AccordionsItem,
  AccordionsItemHeader,
  AccordionsItemSection,
  Badge,
  CondensedHeader,
  Flashy,
  Gutter,
  Layout,
  LayoutItem,
  Icon,
  ToastUtil,
} from '@axiom/ui';

import { ExperienceEditContext } from './ExperienceEditContext';

export type ExperienceEditSectionProps = {
  children: React.ReactNode;
  expandable?: boolean;
  expanded?: boolean;
  isComplete: boolean;
  name: string;
  title: string;
};

export const ExperienceEditSection = ({
  children,
  expandable = false,
  expanded = false,
  isComplete,
  name,
  title,
}: ExperienceEditSectionProps) => {
  const { accordionState, setAccordionState } = useContext(
    ExperienceEditContext
  );

  return (
    <Accordions name={`${name}_EXPERIENCE_EDIT_SECTION`}>
      <AccordionsItem name={`${name}_accordion`} expanded={expanded}>
        <AccordionsItemHeader
          name={`${name}_ACCORDION_HEADER`}
          disabled={!expandable}
          onToggle={value => {
            if (expandable) {
              setAccordionState({ ...accordionState, [name]: value });
            } else {
              ToastUtil.add({
                dismissible: true,
                name: 'OPEN_NOT_ALLOWED',
                type: 'error',
                children: (
                  <>
                    Please complete and save the previous section(s) before
                    moving on
                  </>
                ),
              });
            }
          }}
        >
          <Layout position="space-between middle">
            <LayoutItem>
              <CondensedHeader name="TITLE">{title}</CondensedHeader>
            </LayoutItem>
            <LayoutItem position="center">
              <Gutter right="16px">
                {isComplete ? (
                  <Flashy color="controlSuccessPrimary">
                    <Layout>
                      <Icon name="circle-check-filled" />
                    </Layout>
                  </Flashy>
                ) : (
                  <Badge background="gray" name="EXPERIENCE_EDIT_SECTION_BADGE">
                    TO DO
                  </Badge>
                )}
              </Gutter>
            </LayoutItem>
          </Layout>
        </AccordionsItemHeader>
        <AccordionsItemSection>
          <Gutter top="24px">{children}</Gutter>
        </AccordionsItemSection>
      </AccordionsItem>
    </Accordions>
  );
};
