import { CandidateWorkFeedFormFilter } from '@axiom/validation';
import { useApi, TaxonomyUtil } from '@axiom/ui';

import { WorkFeedFilterUtil } from '../../utils/work-feed-filter-util';
import { usePracticeAreaOptions } from '../../hooks/usePracticeAreaOptions';
import { FilterPill } from '../../types/work-feed-types';
import { TaxonomyApi } from '../../api/taxonomy-api';

const {
  countryOptions,
  engagementLengthOptions,
  legalRoleOptions,
  opportunityStageOptions,
  weeklyCommitmentOptions,
  worksiteOptions,
} = WorkFeedFilterUtil;
export const useWorkFeedFilterPills = (
  filters: CandidateWorkFeedFormFilter
) => {
  const pills: FilterPill[] = [];

  const [{ data: taxonomy }] = useApi(TaxonomyApi.readTaxonomy());
  const practiceAreaOptions = usePracticeAreaOptions();
  const industryOptions = TaxonomyUtil.getFilteredIndustryOptions(
    taxonomy.industries
  );

  const config = [
    { name: 'opportunityPhases', options: opportunityStageOptions },
    { name: 'practiceAreaId', options: practiceAreaOptions },
    { name: 'industries', options: industryOptions },
    { name: 'timeCommitment', options: weeklyCommitmentOptions },
    { name: 'countries', options: countryOptions },
    { name: 'worksites', options: worksiteOptions },
    { name: 'engagementLength', options: engagementLengthOptions },
    { name: 'talentTypes', options: legalRoleOptions },
  ] as const;

  config.forEach(({ name, options }) => {
    const filter = filters[name];
    if (filter) {
      filter.forEach(v =>
        pills.push({ ...options.find(opt => opt.value === v), name })
      );
    }
  });

  return pills;
};
