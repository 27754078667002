import React, { useContext } from 'react';
import { Candidate, Experience, ExperienceEditSchema } from '@axiom/validation';
import { Form, Button, Gutter, Textarea, FluidButtonLayout } from '@axiom/ui';

import { ExperienceApi } from '../../api/experience-api';
import { CandidateExperienceApi } from '../../api/candidate-experience-api';
import { CandidateProfileIssuesApi } from '../../api/candidate-profile-issues-api';

import {
  ExperienceEditSectionKeys,
  ExperienceEditUtil,
} from './experience-edit-util';
import { ExperienceEditContext } from './ExperienceEditContext';
import { ExperienceEditSection } from './ExperienceEditSection';

const { WORK_DETAILS, SKILLS } = ExperienceEditSectionKeys;

export const WorkDetailsFormSchema = ExperienceEditSchema.pick({
  description: true,
});

export type ExperienceEditFormWorkDetailsProps = {
  candidateId: Candidate['id'];
  expandable?: boolean;
  experience: Experience;
};

export const ExperienceEditFormWorkDetails = ({
  candidateId,
  expandable,
  experience,
}: ExperienceEditFormWorkDetailsProps) => {
  const {
    dirtyFormState,
    setDirtyFormState,
    accordionState,
    setAccordionState,
  } = useContext(ExperienceEditContext);

  return (
    <Form
      name="EXPERIENCE_EDIT_FORM_WORK_DETAILS"
      schema={WorkDetailsFormSchema}
      initialValues={{
        description: experience.description,
      }}
      onSubmit={async formData => {
        const submitData = {
          candidateId,
          ...formData,
        };
        await ExperienceApi.updateExperience(
          experience.id,
          ExperienceEditSchema.partial().parse(submitData)
        );
        await Promise.all([
          CandidateExperienceApi.refreshExperiences(candidateId),
          CandidateProfileIssuesApi.refreshCandidateProfileIssues(candidateId),
        ]);
        setAccordionState({
          ...ExperienceEditUtil.setAllToFalse(accordionState),
          [SKILLS]: true,
        });
      }}
    >
      {({ dirty, fireSubmit }) => {
        // https://stackoverflow.com/questions/61031464/setstate-called-in-render-prop-is-causing-a-react-warning
        setTimeout(() => {
          if (dirtyFormState[WORK_DETAILS] !== dirty) {
            setDirtyFormState((prevState: Record<string, boolean>) => ({
              ...prevState,
              [WORK_DETAILS]: dirty,
            }));
          }
        }, 0);

        return (
          <ExperienceEditSection
            expandable={expandable}
            expanded={accordionState[WORK_DETAILS]}
            isComplete={ExperienceEditUtil.isDataValid(
              WorkDetailsFormSchema,
              experience
            )}
            title="Work details"
            name={WORK_DETAILS}
          >
            <div data-test="EXPERIENCE_EDIT_FORM_WORK_DETAILS_BODY">
              <Textarea
                name="description"
                label="Description of work"
                full
                enableSuggestionsFor="portal-ExperienceItemNonAxiomEdit-description"
              />
              <Gutter bottom="16px" />
              <FluidButtonLayout>
                <Button
                  pattern="secondary"
                  onClick={fireSubmit}
                  name="EXPERIENCE_EDIT_FORM_WORK_DETAILS_SAVE"
                >
                  Save
                </Button>
              </FluidButtonLayout>
            </div>
          </ExperienceEditSection>
        );
      }}
    </Form>
  );
};
