import React from 'react';
import { func, string } from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  ModalHeader,
  ModalSection,
  ModalFooter,
} from '@axiom/ui';

import { CurrentCandidateStore } from '../../stores/protected/current-candidate-store';
import { FormNewOpportunityUnsureStore } from '../../stores/protected/form-new-opportunity-unsure-store';

export const NewOpportunityUnsureModalComponent = props => {
  const { candidateId, opportunityUnsureForm, submitForm, cancelForm } = props;
  return (
    <Modal>
      <ModalHeader onClose={cancelForm}>Unsure about Opportunity</ModalHeader>
      <ModalSection>
        <h4>Next Steps</h4>
        <p>
          Selecting Unsure means that we won’t share your profile with the
          client at this time. If you might be interested in this opportunity,
          you can cancel this action and opt in now. Or, you can return to this
          opportunity and opt in later if it’s still open. Opting in just means
          we’ll include your profile for the client’s consideration.
        </p>
      </ModalSection>
      <ModalFooter>
        <Button variation="outline" name="CANCEL" onClick={cancelForm}>
          Cancel
        </Button>

        <Button
          name="SUBMIT"
          onClick={() => submitForm(candidateId, opportunityUnsureForm)}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

NewOpportunityUnsureModalComponent.defaultProps = {};
NewOpportunityUnsureModalComponent.propTypes = {
  cancelForm: func.isRequired,
  candidateId: string.isRequired,
  opportunityUnsureForm:
    FormNewOpportunityUnsureStore.getDataShape().isRequired,
  submitForm: func.isRequired,
};
export default connect(
  state => ({
    candidateId: CurrentCandidateStore.selectCandidate(state).record.id,
    opportunityUnsureForm: FormNewOpportunityUnsureStore.selectFormData(state),
  }),
  {
    cancelForm: FormNewOpportunityUnsureStore.actionHideForm,
    submitForm: FormNewOpportunityUnsureStore.actionSubmitForm,
  }
)(NewOpportunityUnsureModalComponent);
