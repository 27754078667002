import { Api } from '@axiom/ui';
import {
  Candidate,
  CandidateOpportunity,
  CandidatesHiddenOpportunity,
  CandidateWorkFeedItem,
  ExpressInterest,
  Opportunity,
} from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class CandidateOpportunitiesApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiPortalUrl,
    });
  }

  readCandidateEngagementActionsCount(candidateId: Candidate['id']) {
    return super.read<{ data: { unactionedOpportunitiesCount: number } }>({
      endpoint: `/candidates/${candidateId}/opportunities/unactionedCount`,
      method: 'GET',
    });
  }

  refreshCandidateEngagementActionsCount(candidateId: Candidate['id']) {
    return super.invalidate(
      `/candidates/${candidateId}/opportunities/unactionedCount`
    );
  }

  readCandidateWorkFeedItem(
    candidateId: Candidate['id'],
    opportunityId: Opportunity['id']
  ) {
    return super.read<{ data: CandidateWorkFeedItem }>({
      endpoint: `/candidates/${candidateId}/opportunities/${opportunityId}`,
      method: 'GET',
    });
  }

  createWorkFeedHidden(
    candidateId: Candidate['id'],
    body: CandidatesHiddenOpportunity
  ) {
    return super.write<CandidateOpportunity>({
      endpoint: `/candidates/${candidateId}/opportunities/hidden`,
      body,
      method: 'POST',
    });
  }

  writeWorkFeedInterested(candidateId: Candidate['id'], body: ExpressInterest) {
    return super.write<CandidateOpportunity>({
      endpoint: `/candidates/${candidateId}/opportunities/interested`,
      body,
      method: 'POST',
    });
  }

  refreshCandidateWorkFeedItem(
    candidateId: Candidate['id'],
    opportunityId: Opportunity['id']
  ) {
    return super.invalidate(
      `/candidates/${candidateId}/opportunities/${opportunityId}`
    );
  }
}

export const CandidateOpportunitiesApi = new CandidateOpportunitiesApiClass();
