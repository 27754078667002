import { Api, CandidateOpportunitiesDto } from '@axiom/ui';
import {
  Candidate,
  CandidateOpportunity,
  Opportunity,
  CandidatesSavedOpportunity,
} from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';
import ApiHelper from '../lib/api-helper';

import { CANDIDATE_EXPERIENCES_API_NAME } from './constants';

const api = new ApiHelper(CANDIDATE_EXPERIENCES_API_NAME);

class CandidateOpportunitiesLegacyApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  createWorkFeedSaved(
    candidateId: Candidate['id'],
    body: CandidatesSavedOpportunity
  ) {
    return super.write<CandidateOpportunity>({
      endpoint: `/candidates/${candidateId}/opportunities/saved`,
      body,
      method: 'POST',
    });
  }

  deleteWorkFeedSaved(
    candidateId: Candidate['id'],
    opportunityId: Opportunity['id']
  ) {
    return super.write<CandidateOpportunity>({
      endpoint: `/candidates/${candidateId}/opportunities/saved/${opportunityId}`,
      method: 'DELETE',
    });
  }

  deleteWorkFeedInterested(
    candidateId: Candidate['id'],
    opportunityId: Opportunity['id']
  ) {
    return super.write<CandidateOpportunity>({
      endpoint: `/candidates/${candidateId}/opportunities/${opportunityId}/interested`,
      method: 'DELETE',
    });
  }

  readOpportunitiesByCandidateId(candidateId: Candidate['id']) {
    return super.read<{ data: Array<CandidateOpportunity> }>({
      endpoint: `/candidates/${candidateId}/opportunities`,
      method: 'GET',
    });
  }
}

export const CandidateOpportunitiesLegacyApi =
  new CandidateOpportunitiesLegacyApiClass();

export const getOpportunitiesByCandidateId = async (
  candidateID: Candidate['id']
) => {
  const { data } = await api.GET(`/candidates/${candidateID}/opportunities`);
  return data;
};

export const updateOpportunityByCandidate = async (
  candidateId: Candidate['id'],
  opportunityId: Opportunity['id'],
  opportunity: Opportunity
) => {
  const requestBody = new CandidateOpportunitiesDto(opportunity);

  const data = await api.PATCH(
    `/candidates/${candidateId}/opportunities/${opportunityId}`,
    requestBody.export()
  );
  return { data };
};
