import React from 'react';
import { arrayOf, shape } from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardHeader, CardSection, Gutter, SmallHeader } from '@axiom/ui';

import { CandidateOpportunitiesStore } from '../../stores/protected/candidate-opportunities-store';
import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';

import { NewOpportunitiesItem } from './NewOpportunitiesItem';

export const NewOpportunitiesComponent = ({
  newOpportunities,
  opportunitiesState,
}) => {
  if (newOpportunities.length === 0) {
    return null;
  }
  return (
    <Gutter bottom="24px" name="NEW-OPPORTUNITIES">
      <Card>
        <CardHeader>
          <SmallHeader>Suggested Opportunities</SmallHeader>
        </CardHeader>
        <StoreStateTreatment
          name="NEW_OPPORTUNITIES_TREATMENT"
          storeState={opportunitiesState}
          renderLoadedView={() =>
            newOpportunities.map(opportunity => (
              <CardSection divider key={opportunity.id}>
                <NewOpportunitiesItem opportunity={opportunity} />
              </CardSection>
            ))
          }
        />
      </Card>
    </Gutter>
  );
};

NewOpportunitiesComponent.propTypes = {
  newOpportunities: arrayOf(shape({})),
  opportunitiesState: CandidateOpportunitiesStore.getStateShape().isRequired,
};
NewOpportunitiesComponent.defaultProps = {
  newOpportunities: [],
};

export const NewOpportunities = connect(state => ({
  opportunitiesState: CandidateOpportunitiesStore.select(state),
  newOpportunities: CandidateOpportunitiesStore.selectNewOpportunities(state),
}))(NewOpportunitiesComponent);
