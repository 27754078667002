import React from 'react';
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
} from '@axiom/ui';
import { Candidate, CandidateLanguage } from '@axiom/validation';

import { CandidateLanguageUtil } from '../../utils/candidate-language-util';
import { CandidateLegacyApi } from '../../api/candidate-legacy-api';
import { CandidateLanguagesApi } from '../../api/candidate-languages-api';
import { CandidateProfileIssuesApi } from '../../api/candidate-profile-issues-api';

export const LanguagesItemDelete = ({
  candidateId,
  candidateLanguages,
  languagesToDelete,
  onClose,
}: {
  candidateId: Candidate['id'];
  candidateLanguages: CandidateLanguage[];
  languagesToDelete: CandidateLanguage[];
  onClose: () => void;
}) => {
  const languageToRemove =
    CandidateLanguageUtil.formatLanguagesForForm(languagesToDelete);
  const filteredLanguages = CandidateLanguageUtil.filterActionedLanguage(
    candidateLanguages,
    languageToRemove
  );
  const normalizedCandidatelanguages =
    CandidateLanguageUtil.normalizeCandidateLanguages(filteredLanguages);

  return (
    <Modal name="LANGUAGES_DELETE_MODAL">
      <ModalHeader
        name="LANGUAGES_DELETE_MODAL_HEADER"
        onClose={() => onClose()}
      >
        Delete This Language
      </ModalHeader>
      <ModalSection>
        Deleting this language removes it from your Axiom for Talent Profile and
        the bio that Axiom shares with prospective clients.
      </ModalSection>
      <ModalFooter>
        <Button variation="outline" name="CANCEL" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          name="SUBMIT"
          onClick={async () => {
            await CandidateLanguagesApi.updateLanguages(
              candidateId,
              normalizedCandidatelanguages
            );
            await CandidateLanguagesApi.refreshLanguages(candidateId);
            await CandidateLegacyApi.refreshCandidate(candidateId);
            await CandidateProfileIssuesApi.refreshCandidateProfileIssues(
              candidateId
            );
            return onClose();
          }}
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};
