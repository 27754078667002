import ApiHelper from '../lib/api-helper';

import { CONFIRMATIONS_API_NAME } from './constants';

const api = new ApiHelper(CONFIRMATIONS_API_NAME);

export const patchConfirmations = async (token, body) => {
  const { data } = await api.PATCH(`/confirmations/${token}`, body);
  return data;
};
